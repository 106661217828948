import React, { Suspense, useEffect, useState, useRef } from "react";
import { Main } from "../../styled";
import "antd/dist/antd.css"; // Import Ant Design styles
import "./DateTabs.css";
import { Tabs, DatePicker, Select, Button, Table, message } from "antd";
import moment from "moment";
import Calendars from "../../../components/calendar/Calendar";
import { useDispatch, useSelector } from "react-redux";
import { getAllroomTypeList } from "../../../redux/roomType/actionCreator";
import TabPane from "antd/lib/tabs/TabPane";

const RateManagement = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const dispatch = useDispatch();
  const [roomTypeList, setRoomTypeList] = useState([]);
  async function fetchRoomTypeList() {
    const getRoomTypeList = await dispatch(
      getAllroomTypeList(currentRegisterData?._id, "sell")
    );
    if (getRoomTypeList && !getRoomTypeList.error) {
      setRoomTypeList(getRoomTypeList.data);
    } else {
      message.error({
        content: getRoomTypeList.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  }
  useEffect(() => {
    if (currentRegisterData?._id) {
      fetchRoomTypeList();
    }
  }, [currentRegisterData?._id]);
  console.log("ashdgjagdajdgadgad", roomTypeList);
  let [activeTab, changeTab] = useState("PMS");
  return (
    <>
      <Main>
        <Calendars
          type="rate"
          roomTypeList={roomTypeList}
          currentRegisterData={currentRegisterData}
        />
      </Main>
    </>
  );
};

export default RateManagement;
