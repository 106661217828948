import React, { useState, useRef, useEffect } from "react";
import {
  Table,
  Input,
  Modal,
  Row,
  Col,
  Button,
  Form,
  DatePicker,
  Select,
  message,
} from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Popover } from "../../components/popup/popup";
import FeatherIcon from "feather-icons-react";
import { PageHeader } from "../../components/page-headers/page-headers";
import "../../container/profile/settings/setting.css";
import { UserTableStyleWrapper } from "../pages/style";
import commonFunction from "../../utility/commonFunctions";
import {
  SearchOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  FilterFilled,
  CloseOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import {
  getAllInventoryList,
  deleteInventory,
  getAllPosProductsList,
} from "../../redux/inventory/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";

import { Spin } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";
import { CardToolbox } from "../Customer/Style";
import { TableWrapper, Main } from "../styled";
import {
  getExpenseList,
  cancelExpense,
  getExpenseCategoryList,
  getVendorList,
} from "../../redux/vendor/actionCreator";
import moment from "moment";
import ReportExport from "./ReportExport";
const ExpenseBillList = () => {
  let allLocalData = getItem("setupCache");
  const [rsSymbol, setRsSymbol] = useState(
    allLocalData?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(allLocalData.shopDetails.rs_symbol)?.length > 0
        ? /\(([^)]+)\)/.exec(allLocalData.shopDetails.rs_symbol)[1]
        : allLocalData.shopDetails.rs_symbol
      : "₹"
  );
  const [PaymentTypeList, setPaymentTypeList] = useState(
    allLocalData?.customFields?.paymnetType?.length > 0
      ? allLocalData.customFields.paymnetType
      : []
  );
  const [vendorSearchForm] = Form.useForm();
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.active),
    };
  });
  const [invoiceSearchModalVisible, setInvoiceSearchModalVisible] = useState(
    false
  );
  const [expenseList, setExpenseList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [expenseSearchVisible, setExpenseSearchVisible] = useState(false);
  const [changePage, setChangePage] = useState(1);
  const [resetLoader, setResetLoader] = useState(false);
  const [DynoList, setDynoList] = useState([]);
  const [searchText, setSearch] = useState("");
  let [filterObj, setFilterObj] = useState({});
  const searchDidmount = useRef();
  const [invoiceFilter, setInvoiceFilter] = useState(false);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [modalDeleteVisible, setModelDeleteVisible] = useState({
    type: "Cancel",
    visible: false,
    id: "",
  });
  const [expenseFilter, setexpenseFilter] = useState(false);
  const [categorySearchForm] = Form.useForm();
  const [paymentSearchForm] = Form.useForm();
  const offLineMode = useSelector((state) => state.auth.offlineMode);
  const [invoiceDateForm] = Form.useForm();
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [vendorFilter, setVendorFilter] = useState(false);
  const [vendorSearchVisible, setVendorSearchVisible] = useState(false);
  const [paymnetFliter, setPaymnetFilter] = useState(false);
  const [paymentSearchVisible, setPaymnetSearchVisible] = useState(false);
  const [exportType, setExportType] = useState();
  const [modalVisible, setModelVisible] = useState(false);
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  const [vendorList, setVendorList] = useState([]);
  const getVendorListData = async () => {
    let response = await dispatch(getExpenseCategoryList());
    let response1 = await dispatch(getVendorList());
    if (response && !response.error) {
      setExpenseList(response.data);
      setVendorList(response1.data);
    } else {
      message.error(response.message);
    }
  };
  async function fetchExpanseList(currentPage, obj) {
    setLoader(true);
    const getExpenseListData = await dispatch(getExpenseList(currentPage, obj));
    if (getExpenseListData && getExpenseListData.data) {
      setLoader(false);
      setInvoiceSearchModalVisible(false);
      setExpenseSearchVisible(false);
      setResetLoader(false);
      setVendorSearchVisible(false);
      setPaymnetSearchVisible(false);
      setDynoList(getExpenseListData.data);
    }
  }

  useEffect(() => {
    if (currentRegisterData) {
      fetchExpanseList(1, filterObj);
    }
  }, [filterObj, currentRegisterData?._id]);

  const [state, setState] = useState({
    item: DynoList,
  });
  const { selectedRowKeys, item } = state;
  useEffect(() => {
    if (DynoList) {
      setState({
        item: DynoList,
        selectedRowKeys,
      });
    }
  }, [DynoList, selectedRowKeys]);

  const deleteSelectedRegister = async () => {
    setLoading(true);

    const getDeletedBingages = await dispatch(
      cancelExpense(modalDeleteVisible)
    );
    if (getDeletedBingages && !getDeletedBingages.error) {
      fetchExpanseList(1, filterObj);
      setModelDeleteVisible({ type: "Cancel", visible: false, id: "" });
      setLoading(false);
    }
  };
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);

  const handleCancel = (e) => {
    setModelDeleteVisible({ type: "Cancel", visible: false, id: "" });
  };

  const columns = [
    {
      title: "Reference #",
      dataIndex: "invoice_number",
      fixed: "left",
      className: "products_list_fix",
      render(text, record) {
        return {
          children: (
            <div
              style={
                record.isCancelled ? { textDecoration: "line-through" } : {}
              }
            >
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Date
          <FilterFilled
            style={{ color: invoiceFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setInvoiceSearchModalVisible(!invoiceSearchModalVisible);
            }}
          />
        </div>
      ),
      dataIndex: "invoice_date",
      key: "invoice_date",
      render(created_at, record) {
        return {
          children: record.isCancelled ? (
            <span style={{ textDecoration: "line-through" }}>
              {commonFunction.convertToDate(created_at, "MMM DD, Y")}
            </span>
          ) : (
            <span>{commonFunction.convertToDate(created_at, "MMM DD, Y")}</span>
          ),
        };
      },
    },

    {
      title: (
        <div className="filterContainer">
          Expense Category
          <FilterFilled
            style={{ color: expenseFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setExpenseSearchVisible(!expenseSearchVisible);
            }}
          />
        </div>
      ),
      dataIndex: "expense_category",
      render(text, record) {
        return {
          children: (
            <div
              style={
                record.isCancelled ? { textDecoration: "line-through" } : {}
              }
            >
              {text?.name}
            </div>
          ),
        };
      },
    },
    {
      title: (
        <div className="filterContainer">
          Vendor
          <FilterFilled
            style={{ color: vendorFilter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setVendorSearchVisible(!vendorSearchVisible);
            }}
          />
        </div>
      ),
      dataIndex: "vendor_id",
      key: "vendor_id",
      align: "left",
      render: (text) => <span>{text?.name}</span>,
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render(text, record) {
        return {
          children: (
            <div
              style={
                record.isCancelled ? { textDecoration: "line-through" } : {}
              }
            >
              {text}
            </div>
          ),
        };
      },
    },

    {
      title: (
        <div className="filterContainer">
          Payment type
          <FilterFilled
            style={{ color: paymnetFliter ? "#bd025d" : "#5A5F7D" }}
            onClick={() => {
              setPaymnetSearchVisible(!paymentSearchVisible);
            }}
          />
        </div>
      ),
      dataIndex: "payment_type",
      render(text, record) {
        return {
          children: (
            <div
              style={
                record.isCancelled ? { textDecoration: "line-through" } : {}
              }
            >
              {text == "cash"
                ? "Cash"
                : text == "other"
                  ? "Other"
                  : text == "card"
                    ? "Card"
                    : text}
            </div>
          ),
        };
      },
    },
    {
      title: "Amount",
      dataIndex: "total",
      render(text, record) {
        return {
          children: (
            <div
              style={
                record.isCancelled ? { textDecoration: "line-through" } : {}
              }
            >
              {rsSymbol}
              {text}
            </div>
          ),
        };
      },
    },
    {
      title: "",
      width: "8%",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "center" },
          },
          children: (
            <div>
              {record.isCancelled == false ? (
                <span>
                  <CloseOutlined
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      setModelDeleteVisible({
                        type: "Cancel",
                        id: record._id,
                        visible: true,
                      })
                    }
                  />
                </span>
              ) : (
                <div>
                  <span>
                    <DeleteOutlined
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        setModelDeleteVisible({
                          type: "Delete",
                          id: record._id,
                          visible: true,
                        })
                      }
                    />
                  </span>
                </div>
              )}
            </div>
          ),
        };
      },
    },
  ];
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  useEffect(() => {
    if (searchDidmount.current) {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const timer = setTimeout(() => {
        if (searchText == "") {
          delete filterObj.notes;
          setFilterObj({
            ...filterObj,
            notes: searchText,
          });
        } else {
          setFilterObj({
            ...filterObj,
            notes: searchText,
          });
        }
      }, 500);
      setDebounceTimer(timer);
      return () => clearTimeout(timer);
    } else {
      searchDidmount.current = true;
    }
  }, [searchText]);
  useEffect(() => {
    getVendorListData();
  }, []);
  const handleReset = async (type) => {
    setResetLoader(true);
    if (type == "invoice") {
      delete filterObj.invoice_start_date;
      delete filterObj.invoice_end_date;
      setFilterObj({ ...filterObj });
      setInvoiceFilter(false);
      setInvoiceSearchModalVisible(false);
      invoiceDateForm.resetFields();
    }

    if (type == "category") {
      delete filterObj.expense_category;
      setFilterObj({ ...filterObj });
      categorySearchForm.resetFields();
      setexpenseFilter(false);
      setExpenseSearchVisible(false);
    }
    if (type == "vendor") {
      delete filterObj.search_vendor;
      setFilterObj({ ...filterObj });
      vendorSearchForm.resetFields();
      setVendorFilter(false);
      setVendorSearchVisible(false);
    }
    if (type == "payment") {
      delete filterObj.payment_type;
      setFilterObj({ ...filterObj });
      paymentSearchForm.resetFields();
      setPaymnetFilter(false);
      setPaymnetSearchVisible(false);
    }
  };
  const handleInvoiceDateFilter = async (value) => {
    invoiceDateForm.validateFields().then(async (value) => {
      setInvoiceFilter(true);
      setFilterObj({
        ...filterObj,
        invoice_start_date: moment(value.invoice_start_date).format(
          "YYYY-MM-DD"
        ),
        invoice_end_date: moment(value.invoice_end_date).format("YYYY-MM-DD"),
      });
    });
  };
  const handleSearchFilter = async () => {
    categorySearchForm.validateFields().then(async (value) => {
      setexpenseFilter(true);
      setFilterObj({
        ...filterObj,
        expense_category: value.expense_category,
      });
    });
  };
  const handleSearchFilter1 = async () => {
    vendorSearchForm.validateFields().then(async (value) => {
      setVendorFilter(true);
      setFilterObj({
        ...filterObj,
        search_vendor: value.search_vendor,
      });
    });
  };
  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  const onPaymentFilter = () => {
    paymentSearchForm.validateFields().then(async (value) => {
      setPaymnetFilter(true);
      setFilterObj({
        ...filterObj,
        payment_type: value.payment_type,
      });
    });
  };
  return (
    <div>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Expenses</h2>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    className="set_serbt"
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by reference"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                    value={searchText}
                  />
                </div>
              </>
            }
            buttons={[
              <div className="expenseBtn">
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="click"
                >
                  <NavLink
                    to={"#"}
                    className="ant-btn  ant-btn-md"
                    style={{ marginBottom: "10px", marginRight: 8 }}
                  >
                    <FeatherIcon
                      icon="download"
                      size={14}
                      style={{ marginRight: 5 }}
                    />
                    Export
                  </NavLink>
                </Popover>
                <NavLink
                  to={offLineMode ? "#" : "/vendor/expense/category"}
                  className="ant-btn  ant-btn-md"
                  style={{ marginBottom: "10px", marginRight: 8 }}
                  onClick={() =>
                    offLineMode
                      ? setOfflineModeCheck(true)
                      : setOfflineModeCheck(false)
                  }
                >
                  Expenses Category
                </NavLink>
                <NavLink
                  to={offLineMode ? "#" : "/vendor/expense/addOne"}
                  className="ant-btn ant-btn-primary ant-btn-md"
                  style={{ color: "#FFF", marginBottom: "10px" }}
                  onClick={() =>
                    offLineMode
                      ? setOfflineModeCheck(true)
                      : setOfflineModeCheck(false)
                  }
                >
                  <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
                  Add Expenses
                </NavLink>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col span={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        dataSource={[]}
                        columns={columns}
                        size="small"
                        pagination={false}
                        style={{ marginTop: "8px" }}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        dataSource={DynoList}
                        columns={columns}
                        size="small"
                        style={{ marginTop: "8px" }}
                        scroll={{ x: 600 }}
                        pagination={false}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                  }}
                  onClick={() => {
                    setChangePage(changePage - 1);
                    fetchExpanseList(changePage - 1, filterObj);
                  }}
                  disabled={changePage == 1 ? true : false}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-left"
                    style={{ position: "relative", left: "-11px", top: "2px" }}
                  />
                </Button>
                <Button
                  disabled={DynoList.length != 10 ? true : false}
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                    marginLeft: "40px",
                  }}
                  onClick={() => {
                    setChangePage(changePage + 1);
                    fetchExpanseList(changePage + 1, filterObj);
                  }}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-right"
                    style={{ position: "relative", left: "-9px", top: "2px" }}
                  />
                </Button>
              </p>
            </Cards>
          </Col>
        </Row>
        <Modal
          title={"Confirm " + modalDeleteVisible.type}
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{
                      fontSize: 16,
                      color: "white",
                      margin: "0px 14px",
                    }}
                    spin
                  />
                }
              />
            ) : (
              modalDeleteVisible.type
            )
          }
          visible={modalDeleteVisible.visible}
          onOk={deleteSelectedRegister}
          onCancel={handleCancel}
          width={600}
        >
          <p>
            Are you sure you want to {modalDeleteVisible.type.toLowerCase()}{" "}
            this entry ?
          </p>
        </Modal>
        <Modal
          title="Vendor Filter"
          visible={vendorSearchVisible}
          onCancel={() => setVendorSearchVisible(false)}
          footer={[
            <Button onClick={() => handleReset("vendor")}>
              {resetLoader ? "Loading.." : "Reset"}
            </Button>,
            <Button onClick={() => handleSearchFilter1()} type="primary">
              {loader ? "Loading.." : "Submit"}
            </Button>,
          ]}
          width={600}
        >
          <div>
            <Form form={vendorSearchForm}>
              <Form.Item name="search_vendor">
                <Select
                  showSearch
                  mode="multiple"
                  className="custom_select4545"
                  style={{ margin: "8px 0 0" }}
                  placeholder="Select multile vendor"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {vendorList?.map((val) => {
                    return (
                      <Select.Option key={val._id} value={val._id}>
                        {val.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="You are Offline"
          visible={offLineModeCheck}
          onOk={() => setOfflineModeCheck(false)}
          onCancel={() => setOfflineModeCheck(false)}
          width={600}
        >
          <p>You are offline not add and update </p>
        </Modal>
        <Modal
          title="Invoice Date Wise Filter"
          visible={invoiceSearchModalVisible}
          onCancel={() => setInvoiceSearchModalVisible(false)}
          footer={[
            <Button onClick={() => handleReset("invoice")}>
              {resetLoader ? "Loading.." : "Reset"}
            </Button>,
            <Button onClick={() => handleInvoiceDateFilter()} type="primary">
              {loader ? "Loading.." : "Submit"}
            </Button>,
          ]}
          width={600}
        >
          <div>
            <Form form={invoiceDateForm}>
              <Form.Item
                label="Start Date"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 12px)",
                }}
                name="invoice_start_date"
              >
                <DatePicker
                  style={{ height: "35px" }}
                  placeholder="dd-mm-yyyy"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
              <Form.Item
                label="End Date"
                style={{
                  display: "inline-block",
                  width: "calc(50% - 12px)",
                }}
                name="invoice_end_date"
              >
                <DatePicker
                  style={{ height: "35px", marginBottom: 10 }}
                  placeholder="dd-mm-yyyy"
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Category Filter"
          visible={expenseSearchVisible}
          onCancel={() => setExpenseSearchVisible(false)}
          footer={[
            <Button onClick={() => handleReset("category")}>
              {resetLoader ? "Loading.." : "Reset"}
            </Button>,
            <Button onClick={() => handleSearchFilter()} type="primary">
              {loader ? "Loading.." : "Submit"}
            </Button>,
          ]}
          width={600}
        >
          <div>
            <Form form={categorySearchForm}>
              <Form.Item name="expense_category">
                <Select
                  showSearch
                  mode="multiple"
                  className="custom_select4545"
                  style={{ margin: "8px 0 0" }}
                  placeholder="Select multile category"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {expenseList?.map((val) => {
                    return (
                      <Select.Option key={val._id} value={val._id}>
                        {val.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Payment Filter"
          visible={paymentSearchVisible}
          onCancel={() => setPaymnetSearchVisible(false)}
          footer={[
            <Button onClick={() => handleReset("payment")}>
              {resetLoader ? "Loading.." : "Reset"}
            </Button>,
            <Button onClick={() => onPaymentFilter()} type="primary">
              {loader ? "Loading.." : "Submit"}
            </Button>,
          ]}
          width={600}
        >
          <div>
            <Form form={paymentSearchForm}>
              <Form.Item name="payment_type">
                <Select
                  showSearch
                  className="custom_select4545"
                  style={{ margin: "8px 0 0" }}
                  placeholder="Select payment type"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key={1} value={"cash"}>
                    Cash
                  </Select.Option>
                  <Select.Option key={1} value={"card"}>
                    Card
                  </Select.Option>
                  <Select.Option key={1} value={"other"}>
                    Other
                  </Select.Option>
                  {PaymentTypeList?.map((val) => {
                    return (
                      <Select.Option key={val.name} value={val.name}>
                        {val.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <ReportExport
          modalVisible={modalVisible}
          setModelVisible={setModelVisible}
          reportType={exportType}
        />
      </Main>
    </div>
  );
};

export default ExpenseBillList;
