import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Modal,
  Spin,
  Tabs,
  message,
  Space,
  Button,
  Input,
  Form,
  Select,
} from "antd";
import { CardToolbox } from "../Style";
import { UserTableStyleWrapper } from "../../pages/style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { SearchOutlined } from "@ant-design/icons";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import {
  getReservationList,
  getSingleReservation,
} from "../../.././redux/reservation/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import "./booking.css";
import FeatherIcon from "feather-icons-react";
import moment from "moment";
import {
  getMasterList,
  getRoomReservation,
} from "../../../redux/HotelReports/actionCreator";
import { useHistory, NavLink } from "react-router-dom";
import { Popover } from "../../../components/popup/popup";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { getItem } from "../../../utility/localStorageControl";
const { TabPane } = Tabs;
const BookingList = (props) => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
        ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const {
    tabChangeToCurrentFunction,
    currentRegisterData,
    setReservationDetails,
    setActiveReservationTab,
    setNewReservation,
  } = props;
  const csvLinkRef = useRef();
  const [selectType, setSelectType] = useState("arrival");
  const history = useHistory();
  const dispatch = useDispatch();
  const [reservationList, setReservationlist] = useState([]);
  const [arrivalList, setArrivalList] = useState([]);
  const [inhouseList, setInhouseList] = useState([]);
  const [departureList, setDepartureList] = useState([]);
  const [checkOutList, setCheckOutList] = useState([]);
  const [upcomingList, setUpcomingList] = useState([]);
  const [onHoldList, setOnholdList] = useState([]);
  const [pastArrivalList, setPastArrivalList] = useState([]);
  const [loading, setloading] = useState(true);
  const didMount = useRef(false);
  const [modalVisible, setModelVisible] = useState(false);
  const [changeTab, setChangeTab] = useState("arrival");
  const [searchText, setSearchText] = useState("");
  const [changePage, setChangePage] = useState(1);
  const [searchedColumn, setSearchedColumn] = useState("");
  let [search, setsearch] = useState("");
  const [form1] = Form.useForm();
  const searchInput = useRef(null);
  const [reportType, setReportType] = useState();
  let locale = {
    emptyText: <Spin style={{ marginTop: "20px" }} />,
  };
  const [reservationLoading, setReservationLoading] = useState({
    id: "",
    loading: false,
  });
  const [allReservationData, setAllReservationData] = useState([]);
  const { startDate, endDate } = useSelector((state) => {
    return {
      startDate: state.receipts.roomReservationStartDate,
      endDate: state.receipts.roomReservationEndDate,
    };
  });
  const fetchReservationList = async () => {
    setloading(true);
    let getReservationListData = await dispatch(
      getReservationList(currentRegisterData._id, "arrival")
    );
    if (getReservationListData && !getReservationListData.error) {
      let arraval = [];
      let inhouse = [];
      let departure = [];
      let checkOut = [];
      let upcoming = [];
      let past_arrivals = [];

      setAllReservationData(getReservationListData.data);
      getReservationListData.data.map((j) => {
        let currentDate = new Date();
        currentDate.setHours(12, 30, 0, 0);
        let extras = 0;
        let roomService = 0;
        let totalPrice = 0;
        if (j.addtionalItem?.length > 0) {
          j?.addtionalItem?.map((r) => {
            if (!r.cancel) {
              totalPrice = totalPrice + Number(r.amount.total);
              if (r.type == "posease-restaurant") {
                roomService = roomService + Number(r.amount.total);
              } else {
                extras = extras + Number(r.amount.total);
              }
            }
          });
        }
        j.calculatedPriceWithTax =
          Number(j.calculatedPriceWithTax) + totalPrice;
        j.extras = extras;
        j.roomService = roomService;

        if (
          (j.status == "assign" || j.status == "available") &&
          moment(j.reservationInDateAndTime).isSame(moment(currentDate), "day")
        ) {
          arraval.push(j);
        }

        if (
          (j.status == "checkin") &&
          moment(j.reservationOutDateAndTime).isSame(moment(currentDate), "day")
        ) {
          departure.push(j);
        } else if (
          (j.status == "checkin") &&
          (moment(currentDate).isBetween(
            j.reservationInDateAndTime,
            j.reservationOutDateAndTime
          ) ||
            moment(j.reservationInDateAndTime).isSame(moment(currentDate), "day"))
        ) {
          inhouse.push(j);
        }
        if (j.status == "checkout" || j.status == "cleaning") {
          checkOut.push(j);
        }
        if (
          j.status == "available" &&
          moment(j.reservationOutDateAndTime).isSame(moment(currentDate), "day")
        ) {
          past_arrivals.push(j);
        }
        if (
          (j.status == "available" || j.status == "assign") &&
          !moment(j.reservationInDateAndTime).isSame(
            moment(currentDate),
            "day"
          ) &&
          !moment(j.reservationOutDateAndTime).isSame(
            moment(currentDate),
            "day"
          )
        ) {
          if (moment(j.reservationInDateAndTime) < moment(currentDate)) {
            past_arrivals.push(j);
          } else {
            upcoming.push(j);
          }
        }
      });
      setArrivalList(arraval);
      setDepartureList(departure);
      setInhouseList(inhouse);
      setUpcomingList(upcoming);
      setReservationlist(arraval);
      setCheckOutList(checkOut);
      setPastArrivalList(past_arrivals);
      setloading(false);
    } else {
      message.error(getReservationListData.message);
      setloading(false);
    }
  };

  useEffect(() => {
    if (currentRegisterData && currentRegisterData._id) {
      fetchReservationList();
      didMount.current = true;
    }
  }, [currentRegisterData]);

  async function fetchRoomReservation() {
    setloading(true);
    const response = await dispatch(
      getRoomReservation(currentRegisterData?._id, changePage, {
        startDate: startDate,
        endDate: endDate,
      })
    );

    setloading(false);

    if (response && !response.error) {
      setNewReservation(false);
      response.data.map((j) => {
        let currentDate = new Date();
        currentDate.setHours(12, 30, 0, 0);
        let extras = 0;
        let roomService = 0;
        let totalPrice = 0;
        if (j.addtionalItem?.length > 0) {
          j?.addtionalItem?.map((r) => {
            if (!r.cancel) {
              totalPrice = totalPrice + Number(r.amount.total);
              if (r.type == "posease-restaurant") {
                roomService = roomService + Number(r.amount.total);
              } else {
                extras = extras + Number(r.amount.total);
              }
            }
          });
        }
        j.calculatedPriceWithTax =
          Number(j.calculatedPriceWithTax) + totalPrice;
        j.extras = extras;
        j.roomService = roomService;
      });
      setReservationlist(response.data);
    } else if (response) {
      message.error(response.message);
    }
  }

  useEffect(() => {
    if (didMount.current) {
      if (changeTab == "arrival") {
        setReservationlist(arrivalList);
      } else if (changeTab == "in_house") {
        setReservationlist(inhouseList);
      } else if (changeTab == "departure") {
        setReservationlist(departureList);
      } else if (changeTab == "check_out") {
        setReservationlist(checkOutList);
      } else if (changeTab == "upcoming") {
        setReservationlist(upcomingList);
      } else if (changeTab == "past_arrivals") {
        setReservationlist(pastArrivalList);
      } else if (changeTab == "search") {
        let searchData = allReservationData.filter(
          (val) =>
            val?.reservationStringId
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
            val?.customerInfo?.name
              ?.toLowerCase()
              ?.includes(search.toLowerCase())
        );

        setReservationlist(searchData);
      } else if (changeTab == "all") {
        fetchRoomReservation();
      }
    }
  }, [changeTab, changePage, search, startDate, endDate]);

  const gotoCurrentPage = async (data) => {
    setNewReservation(false);
    setReservationDetails(data);
    setActiveReservationTab("CURRENT");
  };

  const handleReservation = async (id, roomId, _id) => {
    if (reservationLoading.loading == false) {
      setReservationLoading({
        id: _id,
        loading: true,
      });
      let response = await dispatch(getSingleReservation(id));
      if (response && response.data) {
        if (roomId) {
          response.data["RoomId"] = roomId;
        }
        gotoCurrentPage(response.data);
        setReservationLoading({
          id: "",
          loading: false,
        });
      }
    }
  };
  const columns = [
    {
      title: "NAME",
      dataIndex: "customerInfo",
      key: "customerInfo",
      fixed: "left",
      render(text, record) {
        return {
          children: <div>{text?.name}</div>,
        };
      },
    },

    {
      title: "REFERENCE",
      dataIndex: "reservationStringId",
      key: "reservationStringId",
      align: "left",
      render: (text, record) => (
        <div>
          {reservationLoading?.id == record?._id &&
            reservationLoading.loading ? (
            "Loading..."
          ) : (
            <div style={{ color: "#008cba", display: "inline" }}>#{text}</div>
          )}
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (text, value) => (
        <p
          style={{
            backgroundColor: value.isCancelled
              ? "#ff4d4f10"
              : text == "cleaning"
                ? "#fa8b0c10"
                : text == "checkout"
                  ? "#ff4d4f10"
                  : text == "checkin"
                    ? "#20c99710"
                    : "rgb(212, 243, 255)",
            borderRadius: "15px",
            color: value.isCancelled
              ? "#ff4d4f"
              : text == "cleaning"
                ? "#fa8b0c"
                : text == "checkout"
                  ? "#ff4d4f"
                  : text == "checkin"
                    ? "#20c997"
                    : "rgb(24, 101, 132)",
            display: "inline",
            fontSize: "13px",
            fontWeight: 500,
            padding: "4.5px 11.85px",
          }}
        >
          {value.isCancelled
            ? "Cancelled"
            : text == "cleaning"
              ? "Dirty"
              : text == "checkout"
                ? "Checkout"
                : text == "checkin"
                  ? "Checkin"
                  : "Confirmed"}
        </p>
      ),
    },
    {
      title: "ROOM TYPE",
      dataIndex: "room_type",
      key: "room_type",
      render(text, record) {
        return {
          children: <div>{text?.name}</div>,
        };
      },
    },
    {
      title: "RATE PLAN",
      dataIndex: "rate_plan",
      key: "rate_plan",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text?.rate_name}</div>,
        };
      },
    },
    {
      title: "ROOM",
      dataIndex: "room_name",
      key: "room_name",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "GUESTS",
      dataIndex: "guest",
      key: "guest",
      align: "left",
      render: (text) => <span>{text?.adults}</span>,
    },

    {
      title: "SOURCE",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.source}</span>,
    },
    {
      title: "CHANNEL",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.channel}</span>,
    },
    {
      title: "CHECK-IN",
      dataIndex: "reservationInDateAndTime",
      key: "reservationInDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>{commonFunction.convertToDate(last_seen, "MMM DD, Y")}</span>
      ),
    },
    {
      title: "CHECK-OUT",
      dataIndex: "reservationOutDateAndTime",
      key: "reservationOutDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>
          {last_seen == "-"
            ? last_seen
            : commonFunction.convertToDate(last_seen, "MMM DD, Y")}
        </span>
      ),
    },
    {
      title: "NIGHTS",
      dataIndex: "totalNights",
      key: "totalNights",
      align: "left",
      render: (text, record) => (
        <span>
          {moment(record.reservationOutDateAndTime).diff(
            moment(record.reservationInDateAndTime),
            "days"
          )}{" "}
          night(s)
        </span>
      ),
    },
    {
      title: "ROOM CHARGES",
      dataIndex: "calculatedPriceWithoutTax",
      key: "calculatedPriceWithoutTax",
      align: "left",
      render: (text) => (
        <span>
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
    {
      title: "TAX",
      dataIndex: "calCulatedTaxPrice",
      key: "calCulatedTaxPrice",
      align: "left",
      render: (text) => (
        <span>
          {" "}
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
    {
      title: "EXTRAS",
      dataIndex: "extras",
      key: "extras",
      align: "left",
      render: (text) => (
        <span>
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
    {
      title: "ROOM SERVICES",
      dataIndex: "roomService",
      key: "roomService",
      align: "left",
      render: (text) => (
        <span>
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
    {
      title: "TOTAL",
      dataIndex: "calculatedPriceWithTax",
      key: "calculatedPriceWithTax",
      align: "left",
      render: (text) => (
        <span>
          {" "}
          {rsSymbol}
          {Number(text).toFixed(2)}
        </span>
      ),
    },
    {
      title: "BOOKED ON",
      dataIndex: "reservationBookTime",
      key: "reservationBookTime",
      align: "left",
      render: (text) => (
        <span>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</span>
      ),
    },
    {
      title: "BOOKED BY",
      dataIndex: "reservationCreatedStaffUserId",
      key: "reservationCreatedStaffUserId",
      align: "left",
      render: (text) => <span>{text?.username}</span>,
    },
  ];
  const deviceWidth = localStorage.getItem("windowWidth");
  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setReportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setReportType("CSV");
          setModelVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  const submitExport = async (values) => {
    let data =
      values.category == "upcoming"
        ? upcomingList
        : values.category == "checkout"
          ? checkOutList
          : values.category == "departure"
            ? departureList
            : values.category == "in-house"
              ? inhouseList
              : values.category == "arrival"
                ? arrivalList
                : pastArrivalList;

    let name =
      values.category == "upcoming"
        ? "Upcoming"
        : values.category == "checkout"
          ? "Checkout"
          : values.category == "departure"
            ? "Departure"
            : values.category == "in-house"
              ? "In-House"
              : values.category == "arrival"
                ? "Arrival"
                : "Past-Arrivals";
    if (reportType == "PDF") {
      let col = [...columns]
        .filter(
          (val) =>
            val.dataIndex != "action" &&
            val.dataIndex != "room_type" &&
            val.dataIndex != "general" &&
            val.dataIndex != "calculatedPriceWithoutTax" &&
            val.dataIndex != "calCulatedTaxPrice" &&
            val.dataIndex != "extras" &&
            val.dataIndex != "roomService" &&
            val.dataIndex != "reservationBookTime" &&
            val.dataIndex != "reservationCreatedStaffUserId"
        )
        .map((val) => ({
          ...val,
          dataKey: val.dataIndex,
          autoWidth: true,
        }));

      const doc = new jsPDF({ format: "a3" });
      doc.text(name, 10, 10);
      doc.autoTable({
        startY: 20,
        columns: col,
        body: data.map((row) => {
          return col.map((column) => {
            let title = column.title;
            let text = row[column.dataIndex];
            return title == "BOOKED BY"
              ? text.username
              : title == "BOOKED ON"
                ? commonFunction.convertToDate(text, "MMM DD, Y")
                : title == "NIGHTS"
                  ? moment(row.reservationOutDateAndTime).diff(
                    moment(row.reservationInDateAndTime),
                    "days"
                  )
                  : title == "CHECK-OUT"
                    ? commonFunction.convertToDate(text, "MMM DD, Y")
                    : title == "CHECK-IN"
                      ? commonFunction.convertToDate(text, "MMM DD, Y")
                      : title == "CHANNEL"
                        ? text.channel
                        : title == "SOURCE"
                          ? text.source
                          : title == "GUESTS"
                            ? text.adults
                            : title == "RATE PLAN"
                              ? text.rate_name
                              : title == "ROOM TYPE"
                                ? text.name
                                : title == "NAME"
                                  ? text.name
                                  : title == "STATUS"
                                    ? text == "cleaning"
                                      ? "Dirty"
                                      : text == "checkout"
                                        ? "Checkout"
                                        : text == "checkin"
                                          ? "Checkin"
                                          : "Confirmed"
                                    : text;
          });
        }),
        tableWidth: "auto",
        styles: {
          overflow: "linebreak",
          fontSize: 10,
        },
      });
      doc.save(name + ".pdf");
    } else if (reportType == "XLSX") {
      const worksheet = XLSX.utils.json_to_sheet(
        data.map((k) => ({
          NAME: k?.customerInfo?.name,
          REFERENCE: k?.reservationStringId,
          STATUS:
            k.status == "cleaning"
              ? "Dirty"
              : k.status == "checkout"
                ? "Checkout"
                : k.status == "checkin"
                  ? "Checkin"
                  : "Confirmed",
          "ROOM TYPE": k.room_type,
          "RATE PLAN": k.rate_plan,
          ROOM: k.room_name,
          GUESTS: k.guest?.adults,
          SOURCE: k.guest?.source,
          CHANNEL: k.guest?.channel,
          "CHECK-IN": commonFunction.convertToDate(
            k.reservationInDateAndTime,
            "MMM DD, Y"
          ),
          "CHECK-OUT": commonFunction.convertToDate(
            k.reservationOutDateAndTime,
            "MMM DD, Y"
          ),
          NIGHTS: moment(k.reservationOutDateAndTime).diff(
            moment(k.reservationInDateAndTime),
            "days"
          ),
          "ROOM CHARGES": Number(k.calculatedPriceWithoutTax).toFixed(2),
          TAX: Number(k.calCulatedTaxPrice).toFixed(2),
          EXTRAS: Number(k.extras).toFixed(2),
          "ROOM SERVICES": Number(k.roomService).toFixed(2),
          TOTAL: Number(k.calculatedPriceWithTax).toFixed(2),
          "BOOKED ON": commonFunction.convertToDate(
            k.reservationBookTime,
            "MMM DD, Y, h:mm A"
          ),
          "BOOKED BY": k?.reservationCreatedStaffUserId?.username,
        }))
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, name + ".xlsx");
    } else {
      csvLinkRef.current.link.click();
    }

    setModelVisible(false);
  };

  const formateData = () => {
    let data =
      selectType == "upcoming"
        ? upcomingList
        : selectType == "checkout"
          ? checkOutList
          : selectType == "departure"
            ? departureList
            : selectType == "in-house"
              ? inhouseList
              : selectType == "arrival"
                ? arrivalList
                : pastArrivalList;
    data = data.map((k) => ({
      customerInfo: k?.customerInfo?.name,
      reservationStringId: k?.reservationStringId,
      status:
        k.status == "cleaning"
          ? "Dirty"
          : k.status == "checkout"
            ? "Checkout"
            : k.status == "checkin"
              ? "Checkin"
              : "Confirmed",
      room_type: k.room_type?.name,
      rate_plan: k.rate_plan?.rate_name,
      room_name: k.room_name,
      guest: k.guest?.adults,
      source: k.general?.source,
      channel: k.general?.channel,
      reservationInDateAndTime: commonFunction.convertToDate(
        k.reservationInDateAndTime,
        "MMM DD, Y"
      ),
      reservationOutDateAndTime: commonFunction.convertToDate(
        k.reservationOutDateAndTime,
        "MMM DD, Y"
      ),
      totalNights: moment(k.reservationOutDateAndTime).diff(
        moment(k.reservationInDateAndTime),
        "days"
      ),
      calculatedPriceWithoutTax: Number(k.calculatedPriceWithoutTax).toFixed(2),
      calCulatedTaxPrice: Number(k.calCulatedTaxPrice).toFixed(2),
      extras: Number(k.extras).toFixed(2),
      roomService: Number(k.roomService).toFixed(2),
      calculatedPriceWithTax: k.calculatedPriceWithTax,
      reservationBookTime: commonFunction.convertToDate(
        k.reservationBookTime,
        "MMM DD, Y, h:mm A"
      ),
      reservationCreatedStaffUserId: k?.reservationCreatedStaffUserId?.username,
    }));

    return data;
  };
  const formateName = () => {
    let name =
      selectType == "upcoming"
        ? "Upcoming"
        : selectType == "checkout"
          ? "Checkout"
          : selectType == "departure"
            ? "Departure"
            : selectType == "in-house"
              ? "In-House"
              : selectType == "arrival"
                ? "Arrival"
                : "Past-Arrivals";
    return name;
  };
  return (
    <>
      <Main className="booking_tab bookin_tabtp">
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <CardToolbox>
                <PageHeader
                  className="changeBackGround"
                  ghost
                  title=""
                  subTitle={
                    <>
                      <div className="table_titles">
                        <h2>Room Reservation</h2>
                      </div>

                      <Input
                        suffix={<SearchOutlined />}
                        className="set_serbt"
                        autoFocus={
                          deviceWidth && deviceWidth <= 992 ? false : true
                        }
                        placeholder="Search by Name and Reference"
                        style={{
                          borderRadius: "30px",
                          width: "250px",
                        }}
                        onChange={(e) => {
                          if (e.target.value == "") {
                            setChangeTab("arrival");
                          } else {
                            setChangeTab("search");
                          }
                          setsearch(e.target.value);
                        }}
                        value={search}
                      />
                    </>
                  }
                  buttons={[
                    <div key="1" className="page-header-actions custom_action">
                      <div className="actionBtn">
                        <Popover
                          placement="bottomLeft"
                          content={content}
                          trigger="click"
                        >
                          <Button size="middle" type="white">
                            <FeatherIcon icon="download" size={14} />
                            Export
                          </Button>
                        </Popover>
                      </div>

                      {changeTab == "all" && (
                        <CalendarButtonPageHeader
                          key="6"
                          type="room-reservattion"
                        />
                      )}

                      <NavLink
                        to={`#`}
                        className="ant-btn ant-btn-primary ant-btn-md addprdpls"
                        onClick={() => {
                          setNewReservation(true);
                          setActiveReservationTab("CURRENT");
                          setReservationDetails();
                        }}
                      >
                        <FeatherIcon
                          icon="plus"
                          size={16}
                          className="pls_iconcs"
                        />
                        Add New Reservation
                      </NavLink>
                    </div>,
                  ]}
                />
              </CardToolbox>
              <Tabs
                className="reservationBooking-tabs"
                activeKey={changeTab}
                defaultActiveKey="arrival"
                size="small"
                onChange={(val) => {
                  setChangeTab(val);
                }}
              >
                {search != "" && (
                  <TabPane
                    tab={
                      <div className="tabData_count">
                        Search
                        <span className="arrival_count">
                          {reservationList?.length}
                        </span>
                      </div>
                    }
                    key="search"
                  />
                )}
                <TabPane
                  tab={
                    <div className="tabData_count">
                      Arrival
                      <span className="arrival_count">
                        {arrivalList?.length}
                      </span>
                    </div>
                  }
                  key="arrival"
                />
                <TabPane
                  tab={
                    <div className="tabData_count">
                      In House
                      <span className="inHouse_count">
                        {" "}
                        {inhouseList?.length}
                      </span>
                    </div>
                  }
                  key="in_house"
                />
                <TabPane
                  tab={
                    <div className="tabData_count">
                      Departure
                      <span className="departure_count">
                        {" "}
                        {departureList?.length}
                      </span>
                    </div>
                  }
                  key="departure"
                />
                <TabPane
                  tab={
                    <div className="tabData_count">
                      Checked Out
                      <span className="checkout_count">
                        {" "}
                        {checkOutList?.length}
                      </span>
                    </div>
                  }
                  key="check_out"
                />
                <TabPane
                  tab={
                    <div className="tabData_count">
                      Upcoming
                      <span className="upcoming_count">
                        {" "}
                        {upcomingList?.length}
                      </span>
                    </div>
                  }
                  key="upcoming"
                />
                {/* <TabPane
                  tab={
                    <div className="tabData_count">
                      On Hold
                      <span className="onHold_count">{onHoldList?.length}</span>
                    </div>
                  }
                  key="on_hold"
                /> */}
                <TabPane
                  key="past_arrivals"
                  tab={
                    <div className="tabData_count">
                      Past Arrivals
                      <span className="pastArrival_count">
                        {pastArrivalList?.length}
                      </span>
                    </div>
                  }
                />
                <TabPane
                  key="all"
                  tab={<div className="tabData_count">All</div>}
                />
              </Tabs>

              {changeTab ? (
                <UserTableStyleWrapper>
                  <div className="contact-table posProductTbl">
                    <TableWrapper className="table-responsive">
                      {loading ? (
                        <Table
                          className="receipt-custom-table"
                          rowKey="id"
                          locale={locale}
                          size="small"
                          dataSource={[]}
                          columns={columns}
                          fixed={true}
                          scroll={{ x: 2500 }}
                        />
                      ) : (
                        <Table
                          className="receipt-custom-table"
                          rowKey="_id"
                          size="small"
                          dataSource={reservationList}
                          columns={columns}
                          fixed={true}
                          scroll={{ x: 2500 }}
                          // onRow={(row) => ({
                          //   onClick: () => {
                          //     gotoCurrentPage(row);
                          //   },
                          // })}
                          onRow={(row) => ({
                            onClick: () => {
                              if (row.roomId) {
                                handleReservation(
                                  row.reservationId,
                                  row.roomId,
                                  row._id
                                );
                              } else {
                                handleReservation(
                                  row.reservationId,
                                  undefined,
                                  row._id
                                );
                              }
                            },
                          })}
                          pagination={
                            changeTab == "all"
                              ? false
                              : {
                                total: reservationList.length,
                                showSizeChanger: true,
                                pageSizeOptions: [
                                  "10",
                                  "50",
                                  "100",
                                  "500",
                                  "1000",
                                ],
                              }
                          }
                        />
                      )}
                    </TableWrapper>
                  </div>
                  {changeTab == "all" && (
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        cursor: "pointer",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      <Button
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50px",
                        }}
                        onClick={() => {
                          setChangePage(changePage - 1);
                        }}
                        disabled={changePage == 1 ? true : false}
                      >
                        <FeatherIcon
                          size={20}
                          icon="chevron-left"
                          style={{
                            position: "relative",
                            left: "-11px",
                            top: "2px",
                          }}
                        />
                      </Button>
                      <Button
                        style={{
                          width: "32px",
                          height: "32px",
                          borderRadius: "50px",
                          marginLeft: "40px",
                        }}
                        disabled={reservationList?.length < 10 ? true : false}
                        onClick={() => {
                          setChangePage(changePage + 1);
                        }}
                      >
                        <FeatherIcon
                          size={20}
                          icon="chevron-right"
                          style={{
                            position: "relative",
                            left: "-9px",
                            top: "2px",
                          }}
                        />
                      </Button>
                    </p>
                  )}
                </UserTableStyleWrapper>
              ) : (
                ""
              )}
            </Cards>
          </Col>
        </Row>

        {modalVisible && (
          <CSVLink
            style={{ display: "none" }}
            data={formateData()}
            headers={[...columns]
              .filter((val) => val.dataIndex != "action")
              .map((column) => ({
                label: column.title,
                dataIndex:
                  column.title == "CHANNEL"
                    ? "channel"
                    : column.title == "SOURCE"
                      ? "source"
                      : column.dataIndex,
                key:
                  column.title == "CHANNEL"
                    ? "channel"
                    : column.title == "SOURCE"
                      ? "source"
                      : column.dataIndex,
              }))}
            filename={formateName() + ".csv"}
            ref={csvLinkRef}
          >
            Hidden Download CSV Link
          </CSVLink>
        )}

        <Modal
          title="Download Report"
          visible={modalVisible}
          onOk={form1.submit}
          onCancel={() => setModelVisible(false)}
          width={600}
          okText="Download"
        >
          <Form
            style={{ width: "100%" }}
            name="Export"
            form={form1}
            onFinish={submitExport}
          >
            <div className="add-product-block">
              <div className="add-product-content">
                <Form.Item
                  label="Choose Report Type"
                  name="category"
                  initialValue="arrival"
                >
                  <Select onChange={(val) => setSelectType(val)}>
                    <Option value="arrival">Arrival</Option>
                    <Option value="in-house">In House</Option>
                    <Option value="departure">Departure</Option>
                    <Option value="checkout">Checked Out</Option>
                    <Option value="upcoming">Upcoming</Option>
                    <Option value="past-arrivals">Past Arrivals</Option>
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export { BookingList };
