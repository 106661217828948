import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import {
  Row,
  Col,
  Input,
  Form,
  Table,
  Space,
  Popover,
  Card,
  message,
  Radio,
  Typography,
  Spin,
  Checkbox,
  List,
  Select,
  Modal,
  DatePicker,
  Divider,
  Tag,
  AutoComplete,
} from "antd";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../../components/buttons/buttons";
import {
  DeleteOutlined,
  WalletOutlined,
  UnlockOutlined,
  EditOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { NavLink } from "react-router-dom";
import {
  getItem,
  setItem,
  getCartInfoFromLocalKey,
  tableStatusChange,
} from "../../../utility/localStorageControl";

import {
  checkRoomAvailblity,
  getAllroomTypeList,
} from "../../../redux/roomType/actionCreator";
import {
  addBookingInChannel,
  addOrUpdateReseration,
  customerFilter,
} from "../../../redux/reservation/actionCreator";
import checkoutUser from "../../../static/img/guest-traveler-svgrepo-com.svg";
import checkInUser from "../../../static/img/guest-svgrepo-com.svg";
import CustomerDetails from "./CustomerDetails";
import { getAllRateDateWise } from "../../../redux/roomLocation/actionCreator";
import Direct from "./diarect";
import OTA from "./ota";
import { getAllRegisterList } from "../../../redux/register/actionCreator";
import "./assign.css";
function CurrentReservation(props) {
  let { tabChangeToCurrentFunction, roomDetails, currentRegisterData } = props;
  const { Text } = Typography;
  const [editModalOpen, setEditModalOpen] = useState({
    visible: false,
    data: {},
    index: -1,
  });
  let [spinOn, setSpinOn] = useState(false);
  let userDetails = getItem("userDetails");
  let localData = getItem("setupCache");
  const [rsSymbol, setRsSymbol] = useState(
    localData?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)?.length > 0
        ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)[1]
        : localData.shopDetails.rs_symbol
      : "₹"
  );

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [reservationDisable, setReservationDisable] = useState(false);
  let isMounted = useRef(true);
  const [generalForm] = Form.useForm();
  const [stayForm] = Form.useForm();
  const [roomTypeForm] = Form.useForm();
  const [addForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const inputRef = useRef();
  let [roomTypeList, setRoomTypeList] = useState([]);
  let [isDisabled, setDisabled] = useState(true);
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [notUpdate, setNotUpdate] = useState(false);
  let [buclkDiscontDetails, setBulckDisountDetails] = useState({
    type: "FLAT",
    value: 0,
    click: false,
  });
  let [bulckdiscuntButtonText, setBulckDiscontButtonText] = useState({
    text: "Bulk discount",
    color: "#008cba",
    discountValue: 0,
  });
  let [PopoverVisible, setPopoverVisible] = useState(false);
  let [selectedRoomtype, setSelectedRoomType] = useState([
    {
      room_type: roomDetails
        ? {
          ...roomDetails.room_type,
          rate_plan_list: roomDetails.room_type.rate_plan,
        }
        : {
          _id: "",
          name: "",
          default_adult: 0,
          default_children: 0,
          default_infant: 0,
          tax_group: {},
          rate_plan_list: [],
          numberOfRooms: [],
          firstTimeSelect: false,
        },
      rate_plan: {},
      numberOfRooms: roomDetails ? 1 : 0,
      rooms: roomDetails
        ? [
          {
            adults: 1,
            children: 0,
            infants: 0,
            room_id: roomDetails._id,
            room_name: roomDetails.room_name,
          },
        ]
        : [],
      total_guest: 1,
      calculatedPriceWithoutTax: 0,
      calCulatedTaxPrice: 0,
      calculatedPriceWithTax: 0,
      roomTypeId: "",
      isManualPricing: false,
    },
  ]);
  const [customerErr, setCustomerErr] = useState(false);
  const [totalCalculatedPrice, setTotalCalculatedPrice] = useState(0);
  const [
    totalCalculatedPriceWithoutTax,
    setTotalCalculatedPriceWihoutTax,
  ] = useState(0);
  const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;
  const [totalCalculatedTax, setTotalCalculatedtax] = useState(0);
  const [reservationStartDate, setReservationStartDate] = useState(
    roomDetails?.reservation_status == "assign"
      ? moment(roomDetails?.reservation_start_date, "MM/D/YYYY").format(
        "ddd DD MMM YYYY"
      )
      : moment().format("ddd DD MMM YYYY")
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    roomDetails?.reservation_status == "assign"
      ? moment(roomDetails?.reservation_start_date, "MM/D/YYYY")
        .add(1, "day")
        .format("ddd DD MMM YYYY")
      : moment()
        .add(1, "day")
        .format("ddd DD MMM YYYY")
  );
  const [numberOfNights, setNumberOfNights] = useState(
    moment(reservationEndDate).diff(moment(reservationStartDate), "days")
  );
  const [customerModalVisible, setCustomerModalVisible] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({});
  const [source, setSource] = useState("Direct");
  const [channel, setChannel] = useState("Walk-in");
  const [searchValue, setSearchValue] = useState("");
  const [rateLoader, setRateLoader] = useState(false);
  const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});

  function disabledStartDate(current) {
    return current && current < moment().subtract(1, "days");
  }
  function disabledEndDate(current) {
    return current && current <= moment(reservationStartDate);
  }

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

  async function fetchRoomTypeList() {
    const getroomListData = await dispatch(
      getAllroomTypeList(currentRegisterData._id, "sell")
    );
    if (isMounted.current) {
      let list = [];
      if (getroomListData?.data?.length) {
        list = getroomListData?.data.map((val) => {
          val.tax_group.taxes = val.tax_group.taxes.map((k) => {
            if (val.tax_group.taxes_inclusive_in_product_price) {
              k["type"] = "inclusive";
            } else {
              k["type"] = "exclusive";
            }
            return k;
          });
          return val;
        });
      }
      setRoomTypeList(list);
    }
  }
  console.log(
    "selectedRoomtypedasdasdadadadadad",
    selectedRoomtype,
    roomDetails
  );
  useEffect(() => {
    if (currentRegisterData && currentRegisterData._id) {
      fetchRoomTypeList();
    }
  }, [currentRegisterData?._id]);

  const fetchDateWiseRate = async () => {
    setRateLoader(true);
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      setRateDatewiseRateObj(obj);

      setRateLoader(false);
      return obj;
    }
  };
  const fetchDateWiseRate1 = async () => {
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      return obj;
    }
  };
  useEffect(() => {
    if (
      getItem("rate_app_enable") &&
      currentRegisterData &&
      currentRegisterData._id
    ) {
      fetchDateWiseRate();
    }
  }, [currentRegisterData?._id, reservationStartDate, reservationEndDate]);

  const handleSubmit = (val) => { };

  const handleCancel = () => {
    setCustomerModalVisible(false);
    addForm.resetFields();
  };

  const handleSubmitBasicDetails = async (formData) => {
    setCustomerModalVisible(false);
    addForm.resetFields();
  };
  const [overBookingModal, setOverBookingModal] = useState(false);
  const checkRoomAvailble = async (
    reservationStartDate,
    reservationEndDate,
    room,
    roomTypeData,
    noRoom
  ) => {
    setReservationDisable(true);
    let startDate = moment(reservationStartDate).format("YYYY-MM-DD");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");
    console.log("asjdasjadhjaagjkagfafffafasf", roomTypeData)
    let sellQty =
      noRoom != undefined ? noRoom : roomTypeData?.numberOfRooms?.length;
    console.log("dasjgasgfaskfagfafafgkfafafafa", sellQty)
    if (
      getItem("rate_app_enable") &&
      currentRegisterData &&
      currentRegisterData._id
    ) {
      let dateObj = await fetchDateWiseRate1();
      let startDate1 = moment(reservationStartDate);
      let endDate1 = moment(reservationEndDate);

      while (moment(startDate1).isBefore(endDate1)) {
        let rate_id = roomTypeData?.rate_plan
          ? roomTypeData?.rate_plan[0]?.rate_id
          : roomTypeData?.rate_plan_list[0]?.rate_id;
        if (dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`]) {
          let thisObj =
            dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`];
          if (thisObj?.value?.sell && thisObj?.value?.sell != "") {
            if (Number(thisObj?.value?.sell) < sellQty) {
              sellQty = Number(thisObj?.value?.sell);
            }
          }
        }
        startDate1 = moment(startDate1).add(1, "days");
      }
    }

    let response = await dispatch(
      checkRoomAvailblity({
        reservationStartDate: startDate,
        reservationEndDate: endDate,
        roomTypeId: roomTypeData?._id,
        needRoom: room,
        totalRoom: sellQty,
      })
    );

    if (response && response.data) {
      if (response.data.avilableStatus == false) {
        setOverBookingModal(true);
        setReservationDisable(true);
        return true;
      } else {
        setReservationDisable(false);
        return false;
      }
    }
  };

  const onSelectRoomType = (roomType, index, firstTimeSelect) => {
    if (firstTimeSelect == false) {
      selectedRoomtype[index]["room_type"] = {
        _id: roomType._id,
        name: roomType.name,
        default_adult: roomType.default_adult,
        default_children: roomType.default_children,
        default_infant: roomType.default_infant,
        tax_group: roomType.tax_group,
        rate_plan_list: roomType.rate_plan,
        numberOfRooms: [...Array(roomType.numberOfRooms)],
        firstTimeSelect: true,
        max_adult: roomType.max_adult,
        max_children: roomType.max_children,
        max_infant: roomType.max_infant
      };
      selectedRoomtype[index]["roomTypeId"] = roomType._id;
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != "" &&
        roomType?.channel_room_code != ""
      ) {
        selectedRoomtype[index]["room_type"]["channel_room_code"] =
          roomType?.channel_room_code;
        selectedRoomtype[index]["room_type"]["channel_room_name"] =
          roomType?.channel_room_name;
      }
      console.log("roomType456465466544", roomType);
      setSelectedRoomType([...selectedRoomtype]);
    } else {
      console.log("roomType", roomType)
      selectedRoomtype[index]["room_type"] = {
        _id: roomType._id,
        name: roomType.name,
        default_adult: roomType.default_adult,
        default_children: roomType.default_children,
        default_infant: roomType.default_infant,
        tax_group: roomType.tax_group,
        rate_plan_list: roomType.rate_plan,
        numberOfRooms: [...Array(roomType.numberOfRooms)],
        firstTimeSelect: true,
        max_adult: roomType.max_adult,
        max_children: roomType.max_children,
        max_infant: roomType.max_infant
      };
      selectedRoomtype[index]["roomTypeId"] = roomType._id;
      selectedRoomtype[index].rate_plan = {};
      selectedRoomtype[index].numberOfRooms = 0;
      selectedRoomtype[index].rooms = [];
      selectedRoomtype[index].total_guest = 0;
      selectedRoomtype[index].calculatedPriceWithoutTax = 0;
      selectedRoomtype[index].calCulatedTaxPrice = 0;
      selectedRoomtype[index].calculatedPriceWithTax = 0;
      selectedRoomtype[index].roomTypeId = "";
      selectedRoomtype[index].isManualPricing = false;
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != "" &&
        roomType?.channel_room_code != ""
      ) {
        selectedRoomtype[index]["room_type"]["channel_room_code"] =
          roomType?.channel_room_code;
        selectedRoomtype[index]["room_type"]["channel_room_name"] =
          roomType?.channel_room_name;
      }
      let obj = {};
      obj[`rate_plan_${index}`] = undefined;
      obj[`no_rooms_${index}`] = undefined;
      roomTypeForm.setFieldsValue(obj);
      setSelectedRoomType([...selectedRoomtype]);
    }
  };

  const onSelectRatePlan = (ratePlan, index) => {
    let default_adult_rate = 0;
    let extra_adult_rate = 0;
    let extra_child_rate = 0;
    let extra_infant_rate = 0;

    const currentDate = new Date(reservationStartDate);
    const endDate = new Date(reservationEndDate);

    ratePlan?.adult_rates?.map((j, i) => {
      let myRate = 0;
      if (i) {
        const currentDate1 = new Date(reservationStartDate);
        const endDate1 = new Date(reservationEndDate);
        while (currentDate1 < endDate1) {
          let thatDay = currentDate1.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });

          if (
            rateDateWiseObj &&
            ratePlan &&
            ratePlan.rate_id &&
            rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
          ) {
            let latestObj =
              rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
            console.log("asjhjkafjkasfafasffaffafafafs", latestObj)
            if (latestObj[`default_adult${j.numberOfAdult}`]) {
              myRate += Number(latestObj[`default_adult${j.numberOfAdult}`]);
            } else {
              myRate += Number(j.rate);
            }
          } else {
            console.log("gasjfhasfasfasfasffafasf", j.rate, i)
            myRate += Number(j.rate);
          }

          currentDate1.setDate(currentDate1.getDate() + 1);
        }
        j.rate = (myRate / numberOfNights).toString();
      }
      return j;
    });
    while (currentDate < endDate) {
      let thatDay = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      if (
        rateDateWiseObj &&
        ratePlan &&
        ratePlan.rate_id &&
        rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
      ) {
        let latestObj =
          rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
        default_adult_rate += Number(
          latestObj.default_adult_rate != undefined
            ? latestObj.default_adult_rate
            : ratePlan?.default_adult_rate
        );
        extra_adult_rate += Number(
          latestObj.extra_adult_rate != undefined
            ? latestObj.extra_adult_rate
            : ratePlan.extra_adult_rate
        );
        extra_child_rate += Number(
          latestObj.extra_child_rate != undefined
            ? latestObj.extra_child_rate
            : ratePlan?.extra_child_rate
        );
        extra_infant_rate += Number(
          latestObj.extra_infant_rate != undefined
            ? latestObj.extra_infant_rate
            : ratePlan?.extra_infant_rate
        );
      } else {
        default_adult_rate += Number(ratePlan.default_adult_rate);
        extra_adult_rate += Number(ratePlan.extra_adult_rate);
        extra_child_rate += Number(ratePlan.extra_child_rate);
        extra_infant_rate += Number(ratePlan.extra_infant_rate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    ratePlan = {
      ...ratePlan,
      default_adult_rate: (default_adult_rate / numberOfNights).toString(),
      extra_adult_rate: (extra_adult_rate / numberOfNights).toString(),
      extra_child_rate: (extra_child_rate / numberOfNights).toString(),
      extra_infant_rate: (extra_infant_rate / numberOfNights).toString(),
    };

    selectedRoomtype[index]["rate_plan"] = ratePlan;
    setSelectedRoomType([...selectedRoomtype]);
  };

  function createArrayOfObjects(dataCount) {
    const arrayOfObjects = [];
    for (let i = 1; i <= dataCount; i++) {
      const object = {
        adults: 1,
        children: 0,
        infants: 0,
      };
      arrayOfObjects.push(object);
    }
    return arrayOfObjects;
  }

  function removeArrayOfObjects(originalArray, x) {
    const newArray = originalArray.slice(0, x);
    return newArray;
  }

  const handleArray = (x, array) => {
    if (array.length < x) {
      let checkPush = x - array.length;
      let newArray = createArrayOfObjects(checkPush);
      array = [...array, ...newArray];
      return array;
    } else if (array.length > x) {
      return removeArrayOfObjects(array, x);
    }
  };

  const onSelectRoomNo = (no, index, array) => {
    selectedRoomtype[index]["rooms"] = handleArray(no, array);
    selectedRoomtype[index]["numberOfRooms"] = no;
    checkRoomAvailble(
      reservationStartDate,
      reservationEndDate,
      no,
      selectedRoomtype[index]?.room_type
    );
    setSelectedRoomType([...selectedRoomtype]);
  };

  const updateGuest = (roomTypeIndex, roomIndex, guestType, checkPlusMinus) => {
    if (checkPlusMinus == "plus") {
      let updateNumber =
        1 + selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];

      if (
        guestType == "adults" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.room_type?.max_adult
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "children" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.room_type?.max_infant
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "infants" &&
        updateNumber >
        selectedRoomtype[roomTypeIndex]?.room_type?.max_children
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      selectedRoomtype[roomTypeIndex].rooms[roomIndex][
        guestType
      ] = ++selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
      setSelectedRoomType([...selectedRoomtype]);
      return selectedRoomtype;
    } else {
      if (selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] > 0) {
        selectedRoomtype[roomTypeIndex].rooms[roomIndex][
          guestType
        ] = --selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      } else {
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      }
    }
  };
  function calculateTaxInclusive(totalAmount, taxRate) {
    taxRate = taxRate / 100;
    var originalAmount = totalAmount / (1 + taxRate);
    var taxAmount = totalAmount - originalAmount;
    return {
      originalAmount: originalAmount,
      taxAmount: taxAmount,
    };
  }
  const calCulatedTaxPriceofThisRoomType = (
    totalPrice,
    taxes,
    checkIsInclusive
  ) => {
    let totalTax = 0;
    let inclusiveTax = 0;
    let totalTaxPercent = 0;
    taxes?.map((val) => {
      if (checkIsInclusive != "remove") {
        totalTaxPercent += Number(val.tax_percentage);
      }
    });
    if (checkIsInclusive == true) {
      totalTax = calculateTaxInclusive(totalPrice, totalTaxPercent).taxAmount;
      inclusiveTax = totalTax;
    } else if (checkIsInclusive == false) {
      totalTax = (totalPrice * totalTaxPercent) / 100;
    }
    inclusiveTax = parseFloat(inclusiveTax.toFixed(2));
    totalTax = parseFloat(totalTax.toFixed(2));
    return { totalTax, inclusiveTax };
  };

  const calculatedPriceOfThisRoomType = (selectRoomType) => {
    if (selectRoomType.room_type.name != "") {
      let totalPrice = 0;
      let totalTaxPrice = 0;
      let totalGuest = 0;

      selectRoomType.rooms?.map((val) => {
        // adult calculated
        let oneRoomCalCulatedPrice = 0;

        if (selectRoomType.room_type.default_adult >= val.adults) {
          let default_adult_rate = selectRoomType.rate_plan.default_adult_rate;
          if (selectRoomType.rate_plan?.adult_rates) {
            let checkadultsPrice = selectRoomType.rate_plan?.adult_rates.find(
              (g, i) => i > 0 && g.numberOfAdult == val.adults
            );
            console.log("sdjhaagvajgfafaafasf", selectRoomType.rate_plan)
            if (checkadultsPrice) {
              default_adult_rate = checkadultsPrice.rate;
            }
          }
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice + Number(default_adult_rate));
        } else {
          let checkExtraAdult =
            val.adults - selectRoomType.room_type.default_adult;
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice +
              Number(selectRoomType.rate_plan.default_adult_rate));
          let extraPriceOfAdult =
            numberOfNights *
            (checkExtraAdult *
              Number(selectRoomType.rate_plan.extra_adult_rate));
          oneRoomCalCulatedPrice = oneRoomCalCulatedPrice + extraPriceOfAdult;
        }

        // Extra children calculated
        if (val.children > selectRoomType.room_type.default_children) {
          {
            let checkExtraChildren =
              val.children - selectRoomType.room_type.default_children;

            let extraPriceOfChildren =
              numberOfNights *
              (checkExtraChildren *
                Number(selectRoomType.rate_plan.extra_child_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfChildren;
          }
        }

        // Extra infants calculated
        if (val.infants > selectRoomType.room_type.default_infant) {
          {
            let checkExtraInfants =
              val.infants - selectRoomType.room_type.default_infant;

            let extraPriceOfInfants =
              numberOfNights *
              (checkExtraInfants *
                Number(selectRoomType.rate_plan.extra_infant_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfInfants;
          }
        }

        totalPrice = totalPrice + oneRoomCalCulatedPrice;
        totalGuest = val.adults + val.children + val.infants;
      });

      let { totalTax, inclusiveTax } = calCulatedTaxPriceofThisRoomType(
        totalPrice,
        selectRoomType.room_type.tax_group.taxes,
        selectRoomType.room_type.tax_group.taxes_inclusive_in_product_price
      );
      selectRoomType["calculatedPriceWithoutTax"] = totalPrice - inclusiveTax;
      console.log(
        "CalCulatedPrice123123113313133131",
        selectRoomType["calculatedPriceWithoutTax"]
      );
      totalTaxPrice = totalTax;
      selectRoomType["calCulatedTaxPrice"] = totalTaxPrice;
      selectRoomType["calCulatedInclusiveTaxPrice"] = inclusiveTax;
      totalPrice = totalPrice + totalTaxPrice - inclusiveTax;
      selectRoomType["calculatedPriceWithTax"] = totalPrice;
      selectRoomType["total_guest"] = totalGuest;
      return selectRoomType;
    }
  };
  console.log("fasjhfaaaasasfasafafaafaf", selectedRoomtype)
  // edit price rate modal
  const submitEditRate = (val) => {
    if (
      editModalOpen.data.rate_name &&
      editModalOpen.data.rate_name != "" &&
      editModalOpen.index != -1
    ) {

      let adult_rates = []
      if (selectedRoomtype[editModalOpen.index]["rate_plan"]?.adult_rates) {
        adult_rates = selectedRoomtype[editModalOpen.index]["rate_plan"].adult_rates.map((h) => {
          h.rate = val.default_adult_rate
          return h
        })
      }
      let plan = {
        default_adult_rate: val.default_adult_rate
          ? val.default_adult_rate
          : "0",
        extra_adult_rate: val.extra_adult_rate ? val.extra_adult_rate : "0",
        extra_child_rate: val.extra_child_rate ? val.extra_child_rate : "0",
        extra_infant_rate: val.extra_infant_rate ? val.extra_infant_rate : "0",
        rate_id: editModalOpen.data.rate_id,
        rate_name: editModalOpen.data.rate_name,
        channel_rate_code: editModalOpen.data?.channel_rate_code,
        channel_rate_obj: editModalOpen.data?.channel_rate_obj,
        adult_rates: adult_rates
      };
      selectedRoomtype[editModalOpen.index]["rate_plan"] = plan;
      let ratePlanList =
        selectedRoomtype[editModalOpen.index]["room_type"]["rate_plan_list"];
      let findIndex = ratePlanList.find(
        (g) => g.rate_id == editModalOpen.data.rate_id
      );
      if (findIndex != -1) {
        ratePlanList[findIndex] = plan;
      }
      selectedRoomtype[editModalOpen.index]["room_type"][
        "rate_plan_list"
      ] = ratePlanList;
      selectedRoomtype[editModalOpen.index]["room_type"]["tax_group"] =
        editModalOpen.tax_group;

      selectedRoomtype[editModalOpen.index]["isManualPricing"] = true;
      setSelectedRoomType([...selectedRoomtype]);
      setEditModalOpen({
        visible: false,
        data: {},
        index: -1,
      });
      editForm.resetFields();
    } else {
      setEditModalOpen({
        visible: false,
        data: {},
        index: -1,
      });
      editForm.resetFields();
    }
  };

  const addRoom = () => {
    let obj = {
      room_type: {
        _id: "",
        name: "",
        default_adult: 0,
        default_children: 0,
        default_infant: 0,
        tax_group: {},
        rate_plan_list: [],
        numberOfRooms: [],
      },
      rate_plan: {},
      numberOfRooms: 0,
      rooms: [],
      total_guest: 0,
      calculatedPriceWithoutTax: 0,
      calCulatedTaxPrice: 0,
      calculatedPriceWithTax: 0,
      roomTypeId: "",
      firstTimeSelect: false,
      isManualPricing: false,
    };
    selectedRoomtype.push(obj);
    setSelectedRoomType([...selectedRoomtype]);
    setDisabled(true);
  };

  let locale = {
    emptyText: <span>Add Room Type</span>,
  };

  let cols = [
    {
      title: "Room type",
      dataIndex: "room_type",
      key: "room_type",
      width: "20%",
      fixed: "left",
      render(text, record, index) {
        return {
          children: (
            <>
              <div className="quantityies qucuentlft">
                {record.room_type.name == "" ? "-" : record.room_type.name}
              </div>
            </>
          ),
        };
      },
    },
    {
      title: "Rate Plan",
      dataIndex: "rate_plan",
      key: "rate_plan",
      width: "20%",
      render(text, record, index) {
        return {
          children: (
            <>
              <div className="quantityies qucuentlft">
                {record.rate_plan.rate_name ? record.rate_plan.rate_name : "-"}
              </div>
            </>
          ),
        };
      },
    },
    {
      title: "Rooms",
      dataIndex: "numberOfRooms",

      key: "numberOfRooms",
      render(text, record, index) {
        return {
          children: (
            <>
              <div className="quantityies qucuentlft">{text}</div>
            </>
          ),
        };
      },
    },
    {
      title: "Guest",
      dataIndex: "total_guest",
      key: "total_guest",
    },
    {
      title: "Price",
      dataIndex: "calculatedPriceWithoutTax",
      key: "calculatedPriceWithoutTax",
      align: "left",
      render(text, value) {
        return {
          children: (
            <div style={{ backgroundColor: "white" }}>
              {rsSymbol}
              {isNaN(text) ? 0 : Number(text).toFixed(2)}
            </div>
          ),
        };
      },
    },
    {
      title: "",
      align: "left",
      render(text, record, index) {
        return {
          children: (
            <div style={{ backgroundColor: "white" }}>
              <EditOutlined
                onClick={() => {
                  // editForm.setFieldsValue(record.rate_plan);
                  setEditModalOpen({
                    visible: true,
                    data: record.rate_plan,
                    index: index,
                    tax_group: record?.room_type?.tax_group,
                  });
                }}
              />
            </div>
          ),
        };
      },
    },
  ];
  let bulckDiscontCalculation = (total, totalTaxes) => {
    let crrentTotalPrice = total + totalTaxes;
    if (
      buclkDiscontDetails.type == "FLAT" &&
      buclkDiscontDetails.value > crrentTotalPrice
    ) {
      setBulckDiscontButtonText({
        color: "red",
        text: "Discount is more than total",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    } else if (
      buclkDiscontDetails.type == "FLAT" &&
      buclkDiscontDetails.value > 0
    ) {
      let bulkPrice = buclkDiscontDetails.value / total;
      let taxesArr = [];
      let Totaltaxandbulk = 0;
      let totalPrice = 0;
      selectedRoomtype.map((value, index) => {
        let calCulatedPrice = value.calculatedPriceWithoutTax;
        let productTaxes = 0;
        if (value?.room_type?.tax_group?.taxes?.length) {
          value.room_type.tax_group.taxes.map((j) => {
            if (j.type == "exclusive") {
              productTaxes += j.tax_percentage;
            }
          });
        }
        totalPrice += calCulatedPrice;

        let bulkprice2 = calCulatedPrice * bulkPrice;
        let bulkPrice3 = calCulatedPrice - bulkprice2;
        Totaltaxandbulk += (bulkPrice3 * productTaxes) / 100;

        // value.taxGroup.taxes.map((i) => {
        //   i.totalTaxPrice = (bulkPrice3 * i.tax_percentage) / 100;
        // });
      });
      // taxesArr.map((tax) => (Totaltaxandbulk += tax));

      let bulkfinalTotal = totalPrice - buclkDiscontDetails.value;
      setBulckDiscontButtonText({
        text: `Bulk discount ${rsSymbol}${Number(
          buclkDiscontDetails.value
        ).toFixed(2)}`,
        color: "#008cba",
        discountValue: Number(buclkDiscontDetails.value).toFixed(2),
      });

      return {
        total: bulkfinalTotal,
        totalTaxes: Totaltaxandbulk,
      };
    } else if (
      buclkDiscontDetails.type == "PERCENTAGE" &&
      buclkDiscontDetails.value > 0
    ) {
      selectedRoomtype.map((product) => {
        let calCulatedPrice =
          product.calculatedprice -
          Number(
            product.customDiscountedValue ? product.customDiscountedValue : 0
          );
        product?.taxGroup?.taxes?.length > 0 &&
          product.taxGroup.taxes.map((j) => {
            let bulckProductTax =
              j.tax_percentage -
              (j.tax_percentage * buclkDiscontDetails.value) / 100;

            return (j.totalTaxPrice =
              (bulckProductTax * calCulatedPrice) / 100);
          });
      });

      let calTax = totalTaxes - (totalTaxes * buclkDiscontDetails.value) / 100;
      let caltotal = total - (total * buclkDiscontDetails.value) / 100;
      setBulckDiscontButtonText({
        text: `Bulk discount ${rsSymbol}${Number(
          (total * buclkDiscontDetails.value) / 100
        ).toFixed(2)}`,
        color: "#008cba",
        discountValue: Number(
          (total * buclkDiscontDetails.value) / 100
        ).toFixed(2),
      });
      return {
        total: caltotal,
        totalTaxes: calTax,
      };
    } else if (
      buclkDiscontDetails.type == "PERCENTAGE" &&
      buclkDiscontDetails.value > (total * buclkDiscontDetails.value) / 100
    ) {
      setBulckDiscontButtonText({
        color: "red",
        text: "Discount is more than total",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    } else {
      setBulckDiscontButtonText({
        color: "#008cba",
        text: "Bulk discount",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    }
  };

  const reservationTable = useMemo(() => {
    let finalTotalPrice = 0;
    let finalTaxPrice = 0;
    let finalTotalPriceWithoutPrice = 0;

    selectedRoomtype?.map((roomType) => {
      if (roomType.room_type.name != "") {
        let oneRoomType = calculatedPriceOfThisRoomType(roomType);

        finalTotalPrice = finalTotalPrice + oneRoomType.calculatedPriceWithTax;
        finalTotalPriceWithoutPrice =
          finalTotalPriceWithoutPrice + finalTotalPrice;
        finalTaxPrice = finalTaxPrice + oneRoomType.calCulatedTaxPrice;
      }
    });
    let subTotal = finalTotalPriceWithoutPrice;
    if (buclkDiscontDetails?.value) {
    }
    console.log("buclkDiscontDetails", buclkDiscontDetails);
    setTotalCalculatedPriceWihoutTax(finalTotalPriceWithoutPrice);
    setTotalCalculatedPrice(finalTotalPrice);
    setTotalCalculatedtax(finalTaxPrice);
    let filterList = selectedRoomtype.filter((val) => val.room_type.name != "");
    const rowClassName = (record, index) => {
      return "changeBackground";
    };

    return (
      <>
        <Table
          locale={locale}
          rowKey="key"
          className={"tbl_data handleScroll-normal reservation"}
          dataSource={filterList}
          columns={cols}
          size="small"
          pagination={false}
          scroll={windowWidth <= 600 ? { x: 430 } : {}}
          rowClassName={rowClassName}
          summary={(pageData) => {
            return (
              <>
                {filterList?.length > 0 ? (
                  <Table.Summary.Row className="changeBackground">
                    <>
                      <Table.Summary.Cell>Taxes</Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell className="center-tax">
                        <Text></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text></Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text>
                          {rsSymbol}
                          {isNaN(finalTaxPrice) ? 0 : finalTaxPrice.toFixed(2)}
                        </Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text></Text>
                      </Table.Summary.Cell>
                    </>
                  </Table.Summary.Row>
                ) : (
                  ""
                )}
              </>
            );
          }}
        />
      </>
    );
  }, [selectedRoomtype, numberOfNights, buclkDiscontDetails]);

  const createRoomRates = (startDate1, endDate1, beforeTax, afterTax) => {
    let startdate = startDate1;
    let endDate = endDate1;
    let RoomRates = [];
    let currentDate = new Date(startdate);
    let end = new Date(endDate);
    while (currentDate < end) {
      RoomRates.push({
        Base: {
          AmountBeforeTax: Number(beforeTax?.toFixed(2)),
          AmountAfterTax: Number(afterTax?.toFixed(2)),
          CurrencyCode: "INR",
        },
        EffectiveDate: currentDate.toISOString().split("T")[0], // format as "YYYY-MM-DD"
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return RoomRates;
  };

  const createChannelPayload = (payload, reservationData) => {
    let roomsStays = [];
    let age10Geuset = 0;
    let age08Geuset = 0;
    payload?.roomTypes?.map((k) => {
      age10Geuset = age10Geuset + k.rooms[0].adults;
      age08Geuset = age08Geuset + k.rooms[0].children;
      if (k.numberOfRooms > 1) {
        for (let i = 0; i < k.numberOfRooms; i++) {
          let calculatedPriceWithoutTax =
            k.calculatedPriceWithoutTax / k.numberOfRooms;
          let calculatedPriceWithTax =
            k?.calculatedPriceWithTax / k.numberOfRooms;
          roomsStays.push({
            RoomTypes: [
              {
                RoomDescription: {
                  Name: k?.room_type?.channel_room_name,
                },
                NumberOfUnits: 1,
                RoomTypeCode: k?.room_type?.channel_room_code,
              },
            ],
            RatePlans: [
              {
                RatePlanCode: k?.rate_plan?.channel_rate_code,
                RatePlanName: k?.rate_plan?.channel_rate_obj?.RatePlanName,
              },
            ],
            GuestCounts: [
              {
                AgeQualifyingCode: "10",
                Count: k?.rooms[0]?.adults?.toString(),
              },
              {
                AgeQualifyingCode: "8",
                Count: k?.rooms[0]?.children?.toString(),
              },
            ],
            TimeSpan: {
              Start: payload?.reservationInDate,
              End: payload?.reservationOutDate,
            },
            RoomRates: createRoomRates(
              payload?.reservationInDate,
              payload?.reservationOutDate,
              calculatedPriceWithoutTax / payload?.totalNights,
              calculatedPriceWithTax / payload?.totalNights
            ),
            Total: {
              AmountBeforeTax: Number(calculatedPriceWithoutTax?.toFixed(2)),
              AmountAfterTax: Number(calculatedPriceWithTax?.toFixed(2)),
              CurrencyCode: "INR",
            },
          });
        }
      } else {
        roomsStays.push({
          RoomTypes: [
            {
              RoomDescription: {
                Name: k?.room_type?.channel_room_name,
              },
              NumberOfUnits: k.numberOfRooms,
              RoomTypeCode: k?.room_type?.channel_room_code,
            },
          ],
          RatePlans: [
            {
              RatePlanCode: k?.rate_plan?.channel_rate_code,
              RatePlanName: k?.rate_plan?.channel_rate_obj?.RatePlanName,
            },
          ],
          GuestCounts: [
            {
              AgeQualifyingCode: "10",
              Count: k?.rooms[0]?.adults?.toString(),
            },
            {
              AgeQualifyingCode: "8",
              Count: k?.rooms[0]?.children?.toString(),
            },
          ],
          TimeSpan: {
            Start: payload?.reservationInDate,
            End: payload?.reservationOutDate,
          },
          RoomRates: createRoomRates(
            payload?.reservationInDate,
            payload?.reservationOutDate,
            k?.calculatedPriceWithoutTax / payload?.totalNights,
            k?.calculatedPriceWithTax / payload?.totalNights
          ),
          Total: {
            AmountBeforeTax: Number(k?.calculatedPriceWithoutTax?.toFixed(2)),
            AmountAfterTax: Number(k?.calculatedPriceWithTax?.toFixed(2)),
            CurrencyCode: "INR",
          },
        });
      }
    });

    let channelPayload = {
      RoomStays: roomsStays,
      ResGuests: [
        {
          Customer: {
            PersonName: {
              NamePrefix: "Mr",
              GivenName: payload?.customer?.name,
              Surname: "",
            },
            Telephone: {
              PhoneNumber: payload?.customer?.mobile,
            },
            Email: payload?.customer?.email,
            Address: {
              AddressLine: [payload?.customer?.shipping_address],
              CityName: "",
              PostalCode: "",
              StateProv: "",
              CountryName: "",
            },
          },
          PrimaryIndicator: "1",
        },
      ],
      ResGlobalInfo: {
        UniqueID: {
          ID: reservationData?._id,
        },
        BasicPropertyInfo: {
          HotelCode: currentRegisterData?.ota_hotel_code,
          HotelName: localData?.shopDetails?.shop_name,
        },
        Source: {
          RequestorID: {
            ID: "Posease",
            Type: "ChannelManager",
          },
          BookingChannel: {
            Type: "OTA",
            CompanyName: "Posease",
            CompanyCode: "",
          },
        },
        CreateDateTime: moment().format("YYYY-MM-DDTHH:mm:ss"),
        ResStatus: "Commit",
        TimeSpan: {
          Start: payload?.reservationInDate,
          End: payload?.reservationOutDate,
        },
        GuestCounts: [
          {
            AgeQualifyingCode: "10",
            Count: age10Geuset,
          },
          {
            AgeQualifyingCode: "8",
            Count: age08Geuset,
          },
        ],
        Total: {
          Taxes: {
            Tax: {
              Amount: payload?.payment?.roomChargesTax,
              CurrencyCode: "INR",
            },
          },
          AmountBeforeTax: Number(
            Number(
              payload?.payment?.grandTotal - payload?.payment?.roomChargesTax
            ).toFixed(2)
          ),
          CurrencyCode: "INR",
          AmountAfterTax: Number(payload?.payment?.grandTotal.toFixed(2)),
        },
        PaymentTypeInfo: {
          PaymentType: "PayAtHotel",
        },
        SpecialRequests: [""],
      },
    };
    return channelPayload;
  };
  const handleSelectRoomType = (selectedRoomtype) => {
    let array = selectedRoomtype.map((val) => {
      if (val?.room_type?.rate_plan_list?.length) {
        let findIndex = val.room_type.rate_plan_list.findIndex(
          (k) => k.rate_id == val.rate_plan.rate_id
        );
        if (findIndex != -1) {
          val.room_type.rate_plan_list[findIndex] = val.rate_plan;
        }
        return val;
      } else {
        return val;
      }
    });
    return array;
  };

  const createReservationDetails = async () => {
    if (roomDetails) {
      let findRoomType = roomTypeList.find(
        (val) => val._id == roomDetails?.room_type._id
      );
      let check = await checkRoomAvailble(
        reservationStartDate,
        reservationEndDate,
        1,
        roomDetails?.room_type,
        findRoomType?.numberOfRooms
      );
      if (check) {
        return true;
      }
    }

    let formData = {};
    if (currentRegisterData) {
      formData["prefix"] = currentRegisterData.receipt_number_prefix;
      formData["customer"] = customerDetails;
      formData["reservationInDate"] = moment(reservationStartDate).format(
        "YYYY-MM-DD"
      );
      formData["reservationOutDate"] = moment(reservationEndDate).format(
        "YYYY-MM-DD"
      );

      let startObject = new Date(reservationStartDate);
      startObject.setHours(12, 30, 0, 0);
      let endObject = new Date(reservationEndDate);
      endObject.setHours(12, 30, 0, 0);
      formData["register_id"] = currentRegisterData?._id;
      formData["register_data"] = {
        _id: currentRegisterData?._id,
        subscription_status: currentRegisterData.subscription_status,
        shop_name: localData?.shopDetails?.shop_name,
        voucher_footer: currentRegisterData?.voucher_footer,
        bill_header: currentRegisterData?.bill_header,
        booking_notification_email:
          currentRegisterData?.booking_notification_email,
      };
      formData["reservationInDateAndTime"] = startObject;
      formData["reservationOutDateAndTime"] = endObject;
      formData["totalNights"] = numberOfNights;
      formData["roomTypes"] = handleSelectRoomType(selectedRoomtype);
      formData["quickReservation"] = roomDetails ? true : false;
      formData["reservation_status"] = roomDetails
        ? roomDetails?.reservation_status == "assign"
          ? "assign"
          : "checkin"
        : false;
      formData["reservationInTime"] = "12:30 pm";
      formData["reservationOutTime"] = "12:30 pm";
      formData["general"] = {
        source: source,
        channel: channel,
      };
      formData["reservationCreatedStaffUserId"] =
        userDetails.restaurant_user_id;
      formData["reservationUpdateStaffUserId"] = userDetails.restaurant_user_id;
      formData["reservationBookTime"] = new Date();
      formData["reservationUpdateTime"] = new Date();
      let paymentObj = {
        discount: 0,
        roomCharges: totalCalculatedPriceWithoutTax,
        roomChargesTax: totalCalculatedTax,
        grandTotal: totalCalculatedPrice,
        currency: rsSymbol,
        extraCharges: 0,
        received: 0,
      };

      if (bulckdiscuntButtonText?.discountValue) {
        paymentObj["discount"] = Number(bulckdiscuntButtonText?.discountValue);
        paymentObj["discountObj"] = buclkDiscontDetails;
      }
      formData["payment"] = paymentObj;
    }

    return formData;
  };

  const handleSubmitReservation = async () => {
    if (customerDetails.name == undefined || customerDetails.name == "") {
      setCustomerErr(true);
      return true;
    }
    setLoader(true);
    let payload = await createReservationDetails();

    if (payload == true) {
      setReservationDisable(false);
      setLoader(false);
      return true;
    }
    let formData = new FormData();
    const images = payload?.customer?.doc_images ?? [];
    if (
      payload?.customer?.doc_images &&
      payload?.customer?.doc_images.length > 0
    ) {
      for (const [index, oneImage] of (
        payload?.customer?.doc_images ?? []
      ).entries()) {
        if (oneImage.originFileObj) {
          formData.append("files", oneImage.originFileObj);
          images.splice(index, 1);
        }
      }
    }
    payload.customer.doc_images = images;
    console.log("asdghjadagdagdafgalfgafgafafafas45454545", payload);
    Object.keys(payload).forEach((key) => {
      formData.append(key, JSON.stringify(payload[key]));
    });
    const getReservation = await dispatch(addOrUpdateReseration(formData));
    if (getReservation && !getReservation.error) {
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != ""
      ) {
        let channelPayload = createChannelPayload(payload, getReservation.data);
        if (currentRegisterData?.ota_token) {
          channelPayload["ota_token"] = currentRegisterData?.ota_token;
        }
        if (channelPayload) {
          let channelResponse = await dispatch(
            addBookingInChannel(channelPayload)
          );
          console.log("zkljfljfafsjffssfssfsfsflsj", channelResponse)
          if (channelResponse && channelResponse?.data?.IsSuccess) {
          } else if (channelResponse && channelResponse?.data) {
            message.error({
              content: channelResponse?.data?.Message,
              style: {
                float: "center",
                marginTop: "2vh",
                duration: 5,
              },
            });
          }
        }
      }
      if (getReservation.data.subscription_status == "unsubscribed") {
        message.error({
          content: getReservation.message,
          style: {
            float: "center",
            marginTop: "2vh",
            duration: 5,
          },
        });
        await dispatch(getAllRegisterList("checkReceipt"));
        return true;
      }
      message.success("Reservation Completed");
      setLoader(false);

      if (payload.quickReservation) {
        getReservation.data.RoomId = roomDetails?._id;
        tabChangeToCurrentFunction("CURRENT", false, getReservation.data, true);
      } else {
        tabChangeToCurrentFunction("BOOKING");
      }
    } else {
      message.error(getReservation.message);
      setLoader(false);
    }
  };

  //customer filter
  const fetchCustomerSearchList = async (val) => {
    setSpinOn(true);
    const response = await dispatch(customerFilter({ searchText: val }));

    if (response && response.data.length) {
      setSpinOn(false);
      message.success({
        content: "Customer is match",
        style: {
          float: "right",
          marginTop: "2vh",
        },
      });
      setCustomerList(response.data);
    } else {
      setSpinOn(false);
      setCustomerList([{}]);
      message.error({
        content: "Customer is not match",
        style: {
          float: "right",
          marginTop: "2vh",
        },
      });
    }
  };
  const searchDidmount = useRef();
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [customerList, setCustomerList] = useState([]);

  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(() => {
      if (searchDidmount.current && searchValue != "") {
        fetchCustomerSearchList(searchValue);
      } else if (searchValue == "") {
        // setCustomerDetails({});
        setCustomerList([]);
        searchDidmount.current = true;
      } else {
        searchDidmount.current = true;
      }
    }, 500);
    setDebounceTimer(timer);
    return () => clearTimeout(timer);
  }, [searchValue]);

  const hanldSelectCustomer = (value, option) => {
    const find = customerList.find((j) => j._id == option.key);
    if (find) {
      setCustomerDetails(find);
      setSearchValue("");
      setCustomerList([]);
    }
  };

  const handleTaxEdit = (value) => {
    let tax_group = editModalOpen?.tax_group;
    if (tax_group?.taxes.length) {
      tax_group.taxes_inclusive_in_product_price = value;
      setEditModalOpen({ ...editModalOpen, tax_group: tax_group });
    }
  };
  const [viewFolio, setViewFolio] = useState(true);
  function renderBulkDiscountContent() {
    return (
      <div>
        <>
          {getItem("userDetails") != null &&
            getItem("userDetails").role == "cashier" &&
            (getItem("allow_cashier_to_discount") == null ||
              getItem("allow_cashier_to_discount") == false) ? (
            <p>This user not allow discount</p>
          ) : (
            <div
              style={{
                display: "block",
                boxSizing: "border-box",
                fontSize: "11px",
                padding: "3px",
              }}
            >
              <Radio.Group
                style={{ marginBottom: 10 }}
                onChange={(event) => {
                  setBulckDisountDetails({
                    ...buclkDiscontDetails,
                    type: event.target.value,
                  });
                }}
                value={buclkDiscontDetails.type}
              >
                <Radio value="FLAT">Cash</Radio>
                <Radio value="PERCENTAGE">Percentage</Radio>
              </Radio.Group>
              <br></br>
              <Input
                type="number"
                step="any"
                style={{ marginBottom: 10 }}
                placeholder="Discount values"
                min={0}
                onChange={(event) => {
                  setBulckDisountDetails({
                    ...buclkDiscontDetails,
                    value: event.target.value,
                  });
                }}
                value={buclkDiscontDetails.value}
                onKeyPress={(event) => {
                  return event.key.match("[0-9,.]+")
                    ? true
                    : event.preventDefault();
                }}
              />{" "}
              <br></br>
              {buclkDiscontDetails.type == "PERCENTAGE" &&
                buclkDiscontDetails.value > 100 ? (
                <p style={{ color: "red" }}>
                  Discount cannot be more than 100%.
                </p>
              ) : null}
              <Button
                size="middle"
                type="success"
                className="bulk_hovers"
                disabled={
                  buclkDiscontDetails.type == "PERCENTAGE" &&
                    buclkDiscontDetails.value > 100
                    ? true
                    : notUpdate
                }
                style={{
                  marginLeft: 55,
                  color: "white",
                  background: "#BD025D",
                  border: "#BD025D",
                  opacity:
                    buclkDiscontDetails.type == "PERCENTAGE" &&
                      buclkDiscontDetails.value > 100
                      ? 0.65
                      : "",
                }}
                onClick={() => {
                  setBulckDisountDetails({
                    ...buclkDiscontDetails,
                    click: !buclkDiscontDetails.click,
                    check: "bulck",
                  });

                  setPopoverVisible(false);
                }}
              >
                Done
              </Button>
            </div>
          )}
        </>
      </div>
    );
  }
  let col1 = () => (
    <Col xxl={14} lg={14} xl={14} sm={24} xs={24}>
      <Row>
        <Col span={24}>
          <div className="roomDetailBox">
            <div className="stayInfo">
              <div className="checkIn">
                <img src={checkInUser}></img>
              </div>
              <div className="roomCheckInDetail">
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationStartDate.split(" ")[0]}
                  </small>
                </p>
                <p className="checkInTime">
                  {reservationStartDate.split(" ")[1]}
                </p>
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationStartDate.split(" ")[2]}{" "}
                    {reservationStartDate.split(" ")[3]}
                  </small>
                </p>
              </div>
              <div className="arrowSign">→</div>
              <div className="roomCheckOutDetail">
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationEndDate.split(" ")[0]}
                  </small>
                </p>
                <p className="checkInTime">
                  {reservationEndDate.split(" ")[1]}
                </p>
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationEndDate.split(" ")[2]}{" "}
                    {reservationEndDate.split(" ")[3]}
                  </small>
                </p>
              </div>
              <div className="checkOut">
                <img src={checkoutUser}></img>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      {(roomDetails == undefined ||
        roomDetails?.reservation_status == "assign") && (
          <Form form={stayForm} name="stayDetails">
            <Row className="stayBox">
              <Col
                md={12}
                sm={12}
                lg={12}
                xxl={12}
                xs={24}
                className="gutter-row"
              >
                <Form.Item
                  name="stay_start_date"
                  label="Stay Start Date"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(reservationStartDate, "ddd DD MMM YYYY")}
                    size="large"
                    // disabledDate={disabledStartDate}
                    onChange={(date, string) => {
                      let nights = moment(reservationEndDate).diff(
                        moment(string),
                        "days"
                      );
                      setReservationStartDate(string);
                      let outDate = reservationEndDate;
                      if (nights <= 0) {
                        outDate = moment(string)
                          .add(1, "days")
                          .format("ddd DD MMM YYYY");
                        stayForm.setFieldsValue({
                          stay_end_date: moment(outDate, "ddd DD MMM YYYY"),
                        });
                        setReservationEndDate(outDate);
                        setNumberOfNights(
                          moment(outDate).diff(moment(string), "days")
                        );
                      } else {
                        setNumberOfNights(
                          moment(reservationEndDate).diff(moment(string), "days")
                        );
                      }
                      if (selectedRoomtype[0]?.numberOfRooms) {
                        checkRoomAvailble(
                          string,
                          outDate,
                          selectedRoomtype[0]?.numberOfRooms,
                          selectedRoomtype[0]?.room_type
                        );
                      }
                    }}
                    format="ddd DD MMM YYYY"
                  />
                </Form.Item>
              </Col>
              <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                <Form.Item
                  name="stay_end_date"
                  label="Stay End Date"
                  style={{
                    marginRight: "10px",
                  }}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment(reservationEndDate, "ddd DD MMM YYYY")}
                    size="large"
                    disabledDate={disabledEndDate}
                    onChange={(date, string) => {
                      setNumberOfNights(
                        moment(string).diff(moment(reservationStartDate), "days")
                      );
                      setReservationEndDate(string);
                      if (selectedRoomtype[0]?.numberOfRooms) {
                        checkRoomAvailble(
                          reservationStartDate,
                          string,
                          selectedRoomtype[0]?.numberOfRooms,
                          selectedRoomtype[0]?.room_type
                        );
                      }
                    }}
                    format="ddd DD MMM YYYY"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        )}

      <Row>
        <Col
          xxl={7}
          lg={7}
          xl={7}
          sm={24}
          xs={24}
          className="category-col"
          style={{ marginBottom: "15px" }}
        >
          <Card headless className="reservation-category-card">
            <div className="general_info_container">
              <div className="booking_Type">
                <p>{source}</p>
                <p
                  style={{
                    margin: "0px 5px",
                  }}
                >
                  |
                </p>
                <p>{channel}</p>
              </div>
              <div className="general_form">
                <Form
                  className="comman-input"
                  autoComplete="off"
                  style={{
                    width: "100%",
                  }}
                  form={generalForm}
                  name="generalDetail"
                  onFinish={handleSubmit}
                >
                  <Row>
                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={12}
                      sm={12}
                      xs={24}
                      className="stayBox gutter-box"
                    >
                      <Form.Item
                        name="source"
                        style={{
                          display: "inline-block",
                          width: "100%",
                        }}
                        label="Source"
                      >
                        <Select
                          style={{
                            marginBottom: "10px",
                          }}
                          className="high_addpd room_reservation generalSelect"
                          showSearch
                          defaultValue={"Direct"}
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select Source"
                          onChange={(val) => {
                            if (val == "OTA") {
                              setChannel();
                            } else {
                              setChannel("Walk-in");
                            }
                            setSource(val);
                          }}
                          dropdownRender={(menu) => <div>{menu}</div>}
                          options={[
                            {
                              value: "Direct",
                              label: "Direct",
                            },
                            {
                              value: "OTA",
                              label: "OTA",
                            },
                            {
                              value: "Agent",
                              label: "Agent",
                            },
                            {
                              value: "Corporate",
                              label: "Corporate",
                            },
                          ]}
                        ></Select>
                      </Form.Item>
                    </Col>
                    <Col
                      xxl={24}
                      xl={24}
                      lg={24}
                      md={12}
                      sm={12}
                      xs={24}
                      className="stayBox gutter-box"
                    >
                      <Form.Item
                        name="channel"
                        style={{
                          display: "inline-block",
                          width: "100%",
                        }}
                        label="Channel"
                      >
                        <p style={{ display: "none" }}>{channel}</p>
                        <Select
                          className="high_addpd room_reservation"
                          showSearch
                          value={channel}
                          onChange={(val) => setChannel(val)}
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select Channel"
                          dropdownRender={(menu) => <div>{menu}</div>}
                          options={source == "OTA" ? OTA : Direct}
                        ></Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Card>
        </Col>
        <Col xxl={17} lg={17} xl={17} sm={24} xs={24} className="menuitem-col">
          <Card headless className="menu-item-card">
            <div id="scrollableDiv" className="sell-table-parentss">
              <div className="room_form_container">
                {/* <p className="general_cardHeading">Room</p> */}
                <div className="roomInfo_form">
                  {rateLoader ? (
                    <div className="text-center d-flex justify-content-center align-items-center">
                      <Spin
                        style={{
                          color: "#BD025D",
                          textAlign: "center",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                  ) : (
                    <Form
                      autoComplete="off"
                      size="large"
                      onFinish={handleSubmit}
                      form={roomTypeForm}
                    >
                      {selectedRoomtype?.map((val, index) => {
                        if (index == 0) {
                          return (
                            <div className="room_info">
                              <Row>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    label="Room Type"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Room type is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      defaultValue={
                                        roomDetails ? val.room_type.name : null
                                      }
                                      disabled={roomDetails ? true : false}
                                      placeholder="Select room type"
                                      onChange={(l) => {
                                        let roomType = JSON.parse(l);
                                        onSelectRoomType(
                                          roomType,
                                          index,
                                          val.room_type.firstTimeSelect
                                        );
                                      }}
                                    >
                                      {roomTypeList?.map((data) => (
                                        <Select.Option
                                          key={data._id}
                                          value={JSON.stringify(data)}
                                        >
                                          {data?.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                {roomDetails && (
                                  <Col
                                    xxl={8}
                                    xl={8}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={24}
                                    className="gutter-box"
                                  >
                                    <Form.Item
                                      className="custom-input-text"
                                      label="Room name"
                                      name={`no_rooms_${index}`}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Numbers of room is Required.",
                                        },
                                      ]}
                                    >
                                      <Select
                                        className="high_addpd room_reservation"
                                        showSearch
                                        defaultValue={roomDetails.room_name}
                                        disabled={true}
                                        filterOption={(input, option) =>
                                          option.value
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        placeholder="Select No. of rooms"
                                        dropdownRender={(menu) => (
                                          <div>{menu}</div>
                                        )}
                                        onChange={(j) =>
                                          onSelectRoomNo(j, index, val.rooms)
                                        }
                                      >
                                        {val.room_type.numberOfRooms?.map(
                                          (data, i) => (
                                            <Select.Option
                                              key={i + 1}
                                              value={i + 1}
                                            >
                                              {i + 1}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                )}

                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    className="custom-input-text"
                                    label="Plan"
                                    name={`rate_plan_${index}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "plan type is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select plan type"
                                      onChange={(val) => {
                                        let ratePlan = JSON.parse(val);

                                        onSelectRatePlan(ratePlan, index);
                                      }}
                                      dropdownRender={(menu) => (
                                        <div>{menu}</div>
                                      )}
                                    >
                                      {val.room_type.rate_plan_list &&
                                        val.room_type.rate_plan_list?.map(
                                          (data) => (
                                            <Select.Option
                                              key={data._id}
                                              value={JSON.stringify(data)}
                                            >
                                              {data?.rate_name}
                                            </Select.Option>
                                          )
                                        )}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                {roomDetails == undefined && (
                                  <Col
                                    xxl={8}
                                    xl={8}
                                    lg={8}
                                    md={8}
                                    sm={8}
                                    xs={24}
                                    className="gutter-box"
                                  >
                                    <Form.Item
                                      className="custom-input-text"
                                      label="Number of Rooms"
                                      name={`no_rooms_${index}`}
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Numbers of room is Required.",
                                        },
                                      ]}
                                    >
                                      <Select
                                        className="high_addpd room_reservation"
                                        showSearch
                                        filterOption={(input, option) =>
                                          option.value
                                            .toString()
                                            .toLowerCase()
                                            .indexOf(
                                              input.toString().toLowerCase()
                                            ) >= 0
                                        }
                                        placeholder="Select No. of rooms"
                                        dropdownRender={(menu) => (
                                          <div>{menu}</div>
                                        )}
                                        onChange={(j) =>
                                          onSelectRoomNo(j, index, val.rooms)
                                        }
                                      >
                                        {val.room_type.numberOfRooms?.map(
                                          (data, i) => (
                                            <Select.Option
                                              key={i + 1}
                                              value={i + 1}
                                            >
                                              {i + 1}
                                            </Select.Option>
                                          )
                                        )}
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                )}
                              </Row>

                              {val.rooms.length ? (
                                <p>
                                  <small>
                                    This room type can occupy{" "}
                                    {val.room_type.default_adult} x adults,{" "}
                                    {val.room_type.default_children} x children,{" "}
                                    {val.room_type.default_infant} x infants{" "}
                                  </small>
                                </p>
                              ) : (
                                ""
                              )}

                              {val.rooms.length > 0
                                ? val.rooms?.map((r, i) => {
                                  return (
                                    <>
                                      <div className="roomNumber_info">
                                        {!roomDetails && (
                                          <p
                                            style={{
                                              marginLeft: "10px",
                                              marginBottom: "7px",
                                              fontSize: "15px",
                                            }}
                                          >
                                            <strong>Room {i + 1} </strong>
                                          </p>
                                        )}{" "}
                                        <div className="guest_numbersBox">
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "10px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              Adults
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "adults",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.adults}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "adults",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              children
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "children",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.children}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "children",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              Infants
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "infants",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.infants}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "infants",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                                : ""}
                            </div>
                          );
                        } else {
                          return (
                            <div className="room_info">
                              <Row>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    label="Room Type"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Room type is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select room type"
                                      onChange={(l) => {
                                        let roomType = JSON.parse(l);
                                        onSelectRoomType(
                                          roomType,
                                          index,
                                          val.room_type.firstTimeSelect
                                        );
                                      }}
                                    >
                                      {roomTypeList?.map((data) => (
                                        <Select.Option
                                          key={data._id}
                                          value={JSON.stringify(data)}
                                        >
                                          {data?.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    className="custom-input-text"
                                    label="Plan"
                                    name={`rate_plan_${index}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "plan type is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select plan type"
                                      onChange={(val) => {
                                        let ratePlan = JSON.parse(val);
                                        onSelectRatePlan(ratePlan, index);
                                      }}
                                      dropdownRender={(menu) => (
                                        <div>{menu}</div>
                                      )}
                                    >
                                      {val.room_type.rate_plan_list &&
                                        val.room_type.rate_plan_list?.map(
                                          (data) => (
                                            <Select.Option
                                              key={data._id}
                                              value={JSON.stringify(data)}
                                            >
                                              {data?.rate_name}
                                            </Select.Option>
                                          )
                                        )}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col
                                  xxl={7}
                                  xl={7}
                                  lg={7}
                                  md={7}
                                  sm={7}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    className="custom-input-text"
                                    label="Number of Rooms"
                                    name={`no_rooms_${index}`}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Numbers of room is Required.",
                                      },
                                    ]}
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.value
                                          .toString()
                                          .toLowerCase()
                                          .indexOf(
                                            input.toString().toLowerCase()
                                          ) >= 0
                                      }
                                      placeholder="Select No. of rooms"
                                      dropdownRender={(menu) => (
                                        <div>{menu}</div>
                                      )}
                                      onChange={(j) =>
                                        onSelectRoomNo(j, index, val.rooms)
                                      }
                                    >
                                      {val.room_type.numberOfRooms?.map(
                                        (data, i) => (
                                          <Select.Option
                                            key={i + 1}
                                            value={i + 1}
                                          >
                                            {i + 1}
                                          </Select.Option>
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col
                                  xxl={1}
                                  xl={1}
                                  lg={1}
                                  md={1}
                                  sm={12}
                                  xs={2}
                                  className="gutter-box"
                                  style={{
                                    marginTop: "25px",
                                    paddingRight: "15px",
                                  }}
                                >
                                  <Form.Item className="action-class">
                                    <DeleteOutlined
                                      onClick={() => {
                                        let obj = {};
                                        obj[`rate_plan_${index}`] = undefined;
                                        obj[`no_rooms_${index}`] = undefined;
                                        roomTypeForm.setFieldsValue(obj);
                                        selectedRoomtype.splice(index, 1);
                                        setSelectedRoomType([
                                          ...selectedRoomtype,
                                        ]);
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              {val.rooms.length ? (
                                <p>
                                  <small>
                                    This room type can occupy{" "}
                                    {val.room_type.default_adult} x adults,{" "}
                                    {val.room_type.default_children} x children,{" "}
                                    {val.room_type.default_infant} x infants{" "}
                                  </small>
                                </p>
                              ) : (
                                ""
                              )}
                              {val.rooms.length > 0
                                ? val.rooms?.map((r, i) => {
                                  return (
                                    <>
                                      <div className="roomNumber_info">
                                        <p
                                          style={{
                                            marginLeft: "10px",
                                            marginBottom: "7px",
                                            fontSize: "15px",
                                          }}
                                        >
                                          <strong>Room {i + 1} </strong>
                                        </p>
                                        <div className="guest_numbersBox">
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "10px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              Adults
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "adults",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.adults}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "adults",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              children
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "children",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.children}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "children",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                          <div className="guestCounter">
                                            <p
                                              style={{
                                                marginLeft: "5px",
                                                marginBottom: "5px",
                                              }}
                                            >
                                              Infants
                                            </p>
                                            <div className="quantityies qucuentlft">
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "infants",
                                                    "minus"
                                                  )
                                                }
                                              >
                                                −
                                              </span>
                                              {r.infants}
                                              <span
                                                className="qunatity-adjust"
                                                onClick={() =>
                                                  updateGuest(
                                                    index,
                                                    i,
                                                    "infants",
                                                    "plus"
                                                  )
                                                }
                                              >
                                                +
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })
                                : ""}
                            </div>
                          );
                        }
                      })}
                      {!roomDetails && (
                        <Form.Item>
                          <div className="add_room_btn" onClick={addRoom}>
                            <PlusOutlined /> Add Room
                          </div>
                        </Form.Item>
                      )}
                    </Form>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
  let col2 = () => (
    <Col
      xxl={10}
      lg={10}
      xl={10}
      sm={24}
      xs={24}
      className="cart-sell-data cart-col reservation"
    >
      <Card headless>
        <div className="order-summery-main">
          <p className="order-summry-header">
            <span className="sp-hide-if-sm-screen">
              <Tag color="#43ac6a">{`${numberOfNights} Nights`} </Tag>
              <small>
                <span
                  style={{
                    marginLeft: "10px",
                  }}
                >
                  |
                </span>
                <NavLink
                  to="#"
                  style={{
                    marginLeft: "10px",
                    fontSize: 13,
                    color: "#008cba",
                    position: "relative",
                    top: "0px",
                  }}
                  className="customer-data-btn"
                  onClick={() => {
                    setSearchValue("");
                    setCustomerList([]);
                    setCustomerModalVisible(true);
                  }}
                >
                  {spinOn ? (
                    <Spin indicator={antIcon} />
                  ) : customerDetails?.name ? (
                    customerDetails.name
                  ) : customerDetails?.mobile ? (
                    customerDetails.mobile
                  ) : (
                    " Add Customer"
                  )}
                </NavLink>
              </small>
            </span>
            <span className="pull-right sp-bill-actions">
              <Row>
                <NavLink
                  to="#"
                  className="customer-data-btn"
                  style={{
                    fontSize: 13,
                    marginRight: 8,
                    color: "#008cba",
                  }}
                  onClick={() => {
                    tabChangeToCurrentFunction("ORDER");
                  }}
                >
                  Go back
                </NavLink>
              </Row>
            </span>
          </p>
          <Form form={addForm} onFinish={handleSubmitBasicDetails}>
            <AutoComplete
              style={{ width: "100%" }}
              options={customerList.map((option) => ({
                value: option.mobile ? option.mobile : option.name,
                key: option._id,
                label: (
                  <div>
                    {option.name && <span>{option.name}</span>}
                    {option.mobile && <span> , {option.mobile}</span>}
                    {option.email && <span> , {option.email}</span>}
                  </div>
                ),
              }))}
              onSelect={(val, option) => {
                hanldSelectCustomer(val, option);
              }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "nowrap",
                      padding: 8,
                      cursor: "pointer",
                    }}
                  >
                    <NavLink
                      to="#"
                      style={{
                        flex: "none",
                        padding: "8px",
                        display: "block",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setSearchValue("");
                        setCustomerList([]);
                        setCustomerModalVisible(true);
                      }}
                    >
                      <PlusOutlined
                        style={{
                          color: "rgb(0, 140, 186)",
                          cursor: "pointer",
                        }}
                      />
                      <label
                        style={{
                          color: "rgb(0, 140, 186)",
                          cursor: "pointer",
                        }}
                      >
                        {" "}
                        Add New Customer
                      </label>
                    </NavLink>
                  </div>
                </>
              )}
              onSearch={(value) => setSearchValue(value)}
              value={searchValue}
              placeholder="Search by customer name , mobile , email"
            ></AutoComplete>

            {customerErr &&
              (customerDetails.name == undefined ||
                customerDetails.name == "") && (
                <p className="text-danger" style={{ marginTop: "10px" }}>
                  Customer name is required for reservation.
                </p>
              )}
            {/* {customerErr &&
                  (customerDetails.mobile == undefined ||
                    customerDetails.mobile == "") && (
                    <p className="text-danger">
                      Customer mobile number is required reservation.
                    </p>
                  )} */}
            <Space size="medium" />
            {reservationTable}
            {/* <div className="discount-section">
              <Popover
                content={renderBulkDiscountContent()}
                trigger={"click"}
                visible={PopoverVisible}
                onVisibleChange={(visible) => setPopoverVisible(visible)}
              >
                <Button
                  type="link"
                  className="onhover"
                  style={{
                    color: bulckdiscuntButtonText.color,
                    fontSize: "13px",
                    background: "#F4F5F7",
                    border: "none",
                  }}
                  onClick={() => {
                    setPopoverVisible(!PopoverVisible);
                  }}
                >
                  {bulckdiscuntButtonText.text}
                </Button>
              </Popover>
            </div> */}
            <div className="discount-section upper-btns orderfntbtn">
              <Button
                type="success"
                size="large"
                style={{
                  borderRadius: "inherit",
                  width: "100%",
                  height: "40px",
                  background: "#BD025D",
                }}
                disabled={
                  isNaN(totalCalculatedPrice)
                    ? true
                    : totalCalculatedPrice == 0
                      ? false
                      : reservationDisable
                }
                onClick={handleSubmitReservation}
              >
                {loader ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 16,
                          color: "white",
                          margin: "0px 8px",
                        }}
                        spin
                      />
                    }
                  />
                ) : roomDetails ? (
                  roomDetails?.reservation_status == "assign" ? (
                    `Confirm reservation ${rsSymbol}${isNaN(totalCalculatedPrice)
                      ? 0
                      : Number(totalCalculatedPrice).toFixed(2)
                    }`
                  ) : (
                    `Check in ${rsSymbol}${isNaN(totalCalculatedPrice)
                      ? 0
                      : Number(totalCalculatedPrice).toFixed(2)
                    }`
                  )
                ) : (
                  `Confirm reservation ${rsSymbol}${isNaN(totalCalculatedPrice)
                    ? 0
                    : Number(totalCalculatedPrice).toFixed(2)
                  }`
                )}
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Col>
  );
  console.log("Seleceededdeddededdededededede", selectedRoomtype);
  return (
    <>
      {windowWidth <= 992 && (
        <div>
          {viewFolio == true && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                style={{ marginBottom: "15px" }}
                onClick={() => setViewFolio(!viewFolio)}
              >
                {viewFolio ? "Add Details" : "View Folio"}
              </Button>
            </div>
          )}

          <Row>{viewFolio == false ? col1() : col2()}</Row>
          {viewFolio == false && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="primary"
                style={{ marginTop: "15px", width: "70%" }}
                onClick={() => setViewFolio(!viewFolio)}
              >
                {viewFolio ? "Add Details" : "View Folio"}
              </Button>
            </div>
          )}
        </div>
      )}
      {windowWidth <= 992 == false && (
        <Row>
          {col1()}
          {col2()}
        </Row>
      )}

      {editModalOpen?.visible && (
        <Modal
          title="Edit Rate Price"
          open={editModalOpen.visible}
          onOk={editForm.submit}
          onCancel={() =>
            setEditModalOpen({
              visible: false,
              data: {},
              index: -1,
            })
          }
        >
          <p>
            <strong>Note:</strong> Please note all the rates provided below must
            for per night rate.
          </p>
          <Form
            style={{ width: "100%" }}
            form={editForm}
            name="editNewRate"
            onFinish={submitEditRate}
            className="comman-input"
          >
            <Form.Item
              name="default_adult_rate"
              label="Default Rate"
              initialValue={0}
              rules={[
                {
                  pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
                  message: "rate cannot be negative",
                },
                {
                  required: true,
                  message: "Please enter default rate",
                },
              ]}
            >
              <Input
                min={0}
                type="number"
                step="any"
                placeholder="Enter default rate"
                style={{ marginBottom: 10 }}
                onKeyPress={(event) => {
                  if (event.key.match("[0-9,.]+")) {
                    return true;
                  } else {
                    return event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="extra_adult_rate"
              label="Extra adult rate"
              rules={[
                {
                  pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
                  message: "Price cannot be negative",
                },
              ]}
            >
              <Input
                min={0}
                type="number"
                step="any"
                defaultValue={0}
                placeholder="Enter default adult rate"
                style={{ marginBottom: 10 }}
                onKeyPress={(event) => {
                  if (event.key.match("[0-9,.]+")) {
                    return true;
                  } else {
                    return event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="extra_child_rate"
              label="Extra children rate"
              rules={[
                {
                  pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
                  message: "Price cannot be negative",
                },
              ]}
            >
              <Input
                min={0}
                type="number"
                step="any"
                defaultValue={0}
                placeholder="Enter children rate"
                style={{ marginBottom: 10 }}
                onKeyPress={(event) => {
                  if (event.key.match("[0-9,.]+")) {
                    return true;
                  } else {
                    return event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name="extra_infant_rate"
              label="Extra infant rate"
              rules={[
                {
                  pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
                  message: "Price cannot be negative",
                },
              ]}
            >
              <Input
                min={0}
                type="number"
                step="any"
                placeholder="Enter infant rate"
                style={{ marginBottom: 10 }}
                defaultValue={0}
                onKeyPress={(event) => {
                  if (event.key.match("[0-9,.]+")) {
                    return true;
                  } else {
                    return event.preventDefault();
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              name={`tax-group`}
              label={editModalOpen?.tax_group?.tax_group_name}
            >
              <Radio.Group
                className="tick-radio"
                onChange={(e) => handleTaxEdit(e.target.value)}
              >
                <Radio.Button
                  value="remove"
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {editModalOpen?.tax_group
                    ?.taxes_inclusive_in_product_price === "remove" ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  Remove
                </Radio.Button>
                <Radio.Button
                  value={true}
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {editModalOpen?.tax_group
                    ?.taxes_inclusive_in_product_price === true ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}{" "}
                  Inclusive
                </Radio.Button>

                <Radio.Button
                  value={false}
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {editModalOpen?.tax_group
                    ?.taxes_inclusive_in_product_price === false ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  Exclusive
                </Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        </Modal>
      )}
      {overBookingModal && (
        <Modal
          title="Not Available"
          visible={overBookingModal}
          onCancel={() => setOverBookingModal(false)}
          onOk={() => setOverBookingModal(false)}
        >
          <p>
            Selected rooms are currently occupied for this category. Please wait
            for availability or consider offering alternative category.
          </p>
        </Modal>
      )}
      {customerModalVisible && (
        <CustomerDetails
          customerModalVisible={customerModalVisible}
          setCustomerModalVisible={setCustomerModalVisible}
          setCustomerDetails={setCustomerDetails}
          customerDetails={customerDetails}
        />
      )}
    </>
  );
}

export default CurrentReservation;
