import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Tag, Spin, Skeleton, message, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "../../Sell/sell.css";
import "../style.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import checkoutUser from "../../../static/img/guest-traveler-svgrepo-com.svg";
import checkInUser from "../../../static/img/guest-svgrepo-com.svg";
import { addOrUpdateRoom } from "../../.././redux/room/actionCreator";
import {
  DeleteOutlined,
  PlusOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const AllRoom = (props) => {
  let {
    roomListData,
    loader,
    tabChangeToCurrentFunction,
    fetchRoomList,
    currentStatus,
    searchText,
  } = props;
  let dispatch = useDispatch();
  const [modalCleaningVisible, setCleaningModalVisible] = useState(false);
  const [cleaningRoomId, setCleningRoomId] = useState();
  const [roomListLoading, setRoomListLoading] = useState(loader);
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const handleCancel = (e) => {
    setCleaningModalVisible(false);
  };

  const showEditRoom = (value) => {
    tabChangeToCurrentFunction("CURRENT", false, value);
  };

  useEffect(() => {
    setRoomListLoading(loader);
  }, [loader]);

  const cleaningRoom = async () => {
    if (cleaningRoomId) {
      setLoading(true);
      let getRoom = await dispatch(
        addOrUpdateRoom(
          { status: "available" },
          cleaningRoomId.roomId,
          cleaningRoomId
        )
      );
      if (getRoom && getRoom.error == false) {
        fetchRoomList(undefined, "online");
        setLoading(false);
        setRoomListLoading(false);
        setCleaningModalVisible(false);
        setCleningRoomId();
        message.success({
          content: getRoom.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      } else {
        message.error({
          content: getRoom.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
    }
  };

  const hanldeFilter = (h) => {
    if (currentStatus == "ALL") {
      return true;
    } else if (
      currentStatus &&
      currentStatus.type == "room_type" &&
      currentStatus._id == h.room_type._id
    ) {
      return true;
    } else if (
      currentStatus &&
      currentStatus.type == "location" &&
      currentStatus._id == h.location._id
    ) {
      return true;
    } else if (currentStatus == "AVAILABLE" && h.status == "available") {
      return true;
    } else if (currentStatus == "CHECKIN" && h.status == "checkin") {
      return true;
    } else if (currentStatus == "DIRTY" && h.status == "cleaning") {
      return true;
    } else if (currentStatus == "BLOCK" && h.status == "maintainance") {
      return true;
    }
  };

  return (
    <div
      className="sell-table-parent all-parent list-boxmain"
      // style={{ alignContent: "center" }}
    >
      <div>
        <Row gutter={[2, 2]} className="all-row list-box-row">
          <Col xxl={6} lg={8} xl={8} sm={12} xs={24} className="sell-table-col">
            <div className="NewReservationCard">
              <div
                className="NightStay"
                style={{ marginTop: "36px" }}
                onClick={() => {
                  tabChangeToCurrentFunction("CURRENT", true);
                }}
              >
                <p>
                  <strong>New Reservation</strong>
                </p>
                <p style={{ fontSize: "14px", textAlign: "center" }}>
                  <PlusOutlined />
                </p>
              </div>
            </div>
          </Col>

          {roomListLoading ? (
            [...Array(10)].map((val, index) => {
              return (
                <Col
                  xxl={6}
                  lg={8}
                  xl={8}
                  sm={12}
                  xs={24}
                  className="sell-table-col"
                  key={index}
                >
                  <div className="roomReservationBox">
                    <div
                      className="cardHeader"
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="checkInUser">
                        <img src={checkInUser}></img>
                      </div>
                      <div className="checkInInfo">
                        <p>
                          <small style={{ color: "gray", fontSize: "13px" }}>
                            Check in
                          </small>
                        </p>

                        <Skeleton.Button
                          active={true}
                          size={"default"}
                          shape={"default"}
                          block={false}
                          style={{ marginLeft: "10px" }}
                        />
                      </div>
                    </div>
                    <div className="gestInfo">
                      <Skeleton.Input
                        active={true}
                        block={true}
                        style={{ marginTop: "10px", height: "20px" }}
                      />
                    </div>

                    <div className="NightStay">
                      <Skeleton.Button
                        active={true}
                        size={"default"}
                        shape={"default"}
                        block={true}
                        style={{ height: "50px" }}
                      />
                    </div>
                    <Skeleton.Input
                      active={true}
                      block={false}
                      style={{ height: "10px", marginLeft: "35px" }}
                    />
                    <div className="cardHeader">
                      <div className="checkOutInfo">
                        <p>
                          <small style={{ color: "gray", fontSize: "13px" }}>
                            Check Out
                          </small>
                        </p>
                        <Skeleton.Button
                          active={true}
                          size={"default"}
                          shape={"default"}
                          block={false}
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        />
                      </div>
                      <div className="checkOutUser">
                        <img src={checkoutUser}></img>
                      </div>
                    </div>
                  </div>
                </Col>
              );
            })
          ) : roomListData.length == 0 ? (
            <Col
              xxl={6}
              lg={8}
              xl={6}
              sm={12}
              xs={24}
              className="sell-table-col"
            >
              <div className="NewReservationCard">
                <div
                  className="NightStay"
                  style={{ marginTop: "36px" }}
                  onClick={() => {
                    history.push("/rooms/add");
                  }}
                >
                  <p style={{ textAlign: "center" }}>
                    <strong>Add New Rooms</strong>
                  </p>
                  {/* <p style={{ textAlign: "center" }}>
                    <strong>and</strong>
                  </p>
                  <p style={{ textAlign: "center" }}>
                    <strong>Room types</strong>
                  </p> */}
                  <p style={{ fontSize: "14px", textAlign: "center" }}>
                    <PlusOutlined />
                  </p>
                </div>
              </div>
            </Col>
          ) : (
            roomListData.filter(hanldeFilter).map((val, index) => {
              if (
                val.status == "available" ||
                val.status == "cleaning" ||
                val.status == "maintainance"
              ) {
                return (
                  <Col
                    xxl={6}
                    lg={8}
                    xl={8}
                    sm={12}
                    xs={24}
                    className="sell-table-col"
                    key={val._id}
                  >
                    <div className="roomReservationBox">
                      <div
                        className="cardHeader"
                        style={{ marginBottom: "10px" }}
                      >
                        <div className="checkInUser">
                          <img src={checkInUser}></img>
                        </div>
                        <div className="checkInInfo">
                          <p>
                            <small style={{ color: "gray", fontSize: "13px" }}>
                              Check in
                            </small>
                          </p>
                          <p className="checkIn_time">-</p>
                        </div>
                      </div>
                      <div className="gestInfo">
                        <p style={{ color: "#02a1d5" }}>
                          Waiting for the guest
                        </p>
                        {/* <p >Mobile No. 9937480209</p> */}
                        <div className="infoText">
                          <p>
                            <small style={{ color: "gray", fontSize: "13px" }}>
                              Guests : -
                            </small>
                          </p>
                          {/* <p>
                    <small style={{ color: "gray", fontSize: "13px" }}>-</small>
                  </p> */}
                        </div>
                      </div>

                      <div
                        className="NightStay"
                        style={{
                          backgroundColor:
                            val.status == "cleaning"
                              ? "#fce089"
                              : val.status == "maintainance"
                              ? "rgb(249,124,124)"
                              : "#F4F5F7",
                        }}
                        onClick={() => {
                          if (val.status == "cleaning") {
                            setCleningRoomId({
                              roomId: val._id,
                              reservationId: val?.reservationObjectId?._id,
                              roomInfoId: val.roomInfoId,
                            });
                            setCleaningModalVisible(true);
                          } else if (val.status != "maintainance") {
                            console.log("valvalvalvalvalval", val);
                            tabChangeToCurrentFunction("CURRENT", true, val);
                          }
                        }}
                      >
                        <p>
                          <strong>{`${val?.room_type?.name} ${val?.room_name}`}</strong>
                        </p>
                        <p style={{ fontSize: "14px", textAlign: "center" }}>
                          {val.status == "cleaning"
                            ? "Dirty"
                            : val.status == "maintainance"
                            ? "Block"
                            : "-"}
                        </p>
                      </div>

                      <p style={{ marginBottom: "0px", textAlign: "center" }}>
                        <small style={{ fontSize: "13px" }}>
                          No reservation
                        </small>
                      </p>
                      <div className="cardHeader">
                        <div className="checkOutInfo">
                          <p>
                            <small style={{ color: "gray", fontSize: "13px" }}>
                              Check Out
                            </small>
                          </p>
                          <p className="checkIn_time">-</p>
                        </div>
                        <div className="checkOutUser">
                          <img
                            src={checkoutUser}
                            style={{ marginLeft: "2px" }}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              } else if (val.reservationObjectId) {
                let findRoom = val.reservationObjectId.roomInfo.find(
                  (j) => j.roomId == val._id
                );

                let totalGuest = findRoom
                  ? Number(
                      findRoom.guest.adults +
                        findRoom.guest.children +
                        findRoom.guest.infants
                    )
                  : 0;

                return (
                  <>
                    <Col
                      xxl={6}
                      lg={8}
                      xl={8}
                      sm={12}
                      xs={24}
                      className="sell-table-col"
                      key={index}
                    >
                      <div className="roomReservationBox">
                        <div
                          className="cardHeader"
                          style={{ marginBottom: "10px" }}
                        >
                          <div className="checkInUser">
                            <img src={checkInUser}></img>
                          </div>
                          <div className="checkInInfo">
                            <p>
                              <small
                                style={{ color: "gray", fontSize: "13px" }}
                              >
                                Check in
                              </small>
                            </p>
                            <p className="checkIn_time">
                              {commonFunction.convertToDate(
                                findRoom?.reservationInDateAndTime,
                                "ddd DD MMM YYYY"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="gestInfo">
                          <p style={{ color: "#02a1d5" }}>
                            {findRoom?.customerInfo?.name}
                          </p>
                          <div className="infoText">
                            <p>
                              <small
                                style={{ color: "gray", fontSize: "13px" }}
                              >
                                Guests : {totalGuest}
                              </small>
                            </p>
                            <p>
                              <small
                                style={{ color: "gray", fontSize: "13px" }}
                              >
                                Rate Type : {findRoom?.rate_plan?.rate_name}
                              </small>
                            </p>
                          </div>
                        </div>

                        <div
                          className="NightStay"
                          onClick={() =>
                            showEditRoom({
                              ...val.reservationObjectId,
                              RoomId: val._id,
                            })
                          }
                          style={{ backgroundColor: "#ade9ad" }}
                        >
                          <p>
                            <strong>{`${val.room_type.name} ${val.room_name}`}</strong>
                          </p>
                          <p
                            style={{
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            {moment(findRoom?.reservationOutDateAndTime).diff(
                              moment(findRoom?.reservationInDateAndTime),
                              "days"
                            )}{" "}
                            Nights
                          </p>
                        </div>

                        <p
                          style={{
                            marginBottom: "0px",
                            textAlign: "center",
                          }}
                        >
                          <small style={{ fontSize: "13px" }}>
                            #{val?.reservationObjectId?.reservationStringId}
                          </small>
                        </p>
                        <div className="cardHeader">
                          <div className="checkOutInfo">
                            <p>
                              <small
                                style={{ color: "gray", fontSize: "13px" }}
                              >
                                Check Out
                              </small>
                            </p>
                            <p className="checkIn_time">
                              {commonFunction.convertToDate(
                                findRoom?.reservationOutDateAndTime,
                                "ddd DD MMM YYYY"
                              )}
                            </p>
                          </div>
                          <div className="checkOutUser">
                            <img src={checkoutUser}></img>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </>
                );
              }
            })
          )}
        </Row>
      </div>
      <Modal
        title="Confirm Cleaning"
        okText={
          loading ? (
            <Spin
              indicator={
                <LoadingOutlined
                  style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                  spin
                />
              }
            />
          ) : (
            "Clean"
          )
        }
        visible={modalCleaningVisible}
        onOk={cleaningRoom}
        onCancel={handleCancel}
        width={600}
      >
        <p>Are you sure you want to clean the room ?</p>
      </Modal>
    </div>
  );
};

export { AllRoom };
