import React, { useState, useRef, useEffect } from "react";
import { Table, Input, Modal, Button, message } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import { Popover } from "../../../../../components/popup/popup";
import FeatherIcon from "feather-icons-react";

import "../../setting.css";
import {
  SearchOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  CopyOutlined,
} from "@ant-design/icons";

import {
  getAlldynoList,
  deleteDynos,
  getAllBookingSetupList,
  deleteBookingSetup,
} from "../../../../../redux/onlineOrder/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getItem, setItem } from "../../../../../utility/localStorageControl";
const BookingSetup = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let [search, setsearch] = useState("");
  let isMounted = useRef(true);
  const [DynoList, setDynoList] = useState([]);
  const [selectionType] = useState("checkbox");
  const [modalDeleteVisible, setModelDeleteVisible] = useState(false);
  const offLineMode = useSelector((state) => state.auth.offlineMode);

  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  // console.log("windowWidth:::---dyno online order---->>>>>>::", deviceWidth)
  async function fetchDynoList() {
    setLoading(true);
    const getdaynolist = await dispatch(getAllBookingSetupList());
    if (isMounted.current && getdaynolist) {
      setDynoList(getdaynolist);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (isMounted.current) {
      fetchDynoList();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [state, setState] = useState({
    item: DynoList,
  });
  const { selectedRowKeys, item } = state;
  useEffect(() => {
    if (DynoList) {
      setState({
        item: DynoList,
        selectedRowKeys,
      });
    }
  }, [DynoList, selectedRowKeys]);

  const deleteSelectedRegister = async () => {
    setLoading(true);
    const { allSelectedRowsForDelete } = state;
    let allRegisterIdsForDelete = [];
    allSelectedRowsForDelete.map((item) => {
      allRegisterIdsForDelete.push(item._id);
    });
    const getDeletedBingages = await dispatch(
      deleteBookingSetup({ ids: allRegisterIdsForDelete })
    );
    if (getDeletedBingages && !getDeletedBingages.error) {
      setModelDeleteVisible(false);
      fetchDynoList();
    }
  };
  const [offLineModeCheck, setOfflineModeCheck] = useState(false);

  const contentforaction = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setLoading(false);
          setModelDeleteVisible(true);
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>Delete Selected item</span>
      </NavLink>
    </>
  );

  let searchArrTaxes = DynoList.filter((value) =>
    value.hotel_name?.toLowerCase().includes(search.toLowerCase())
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForDelete: selectedRows,
      });
    },
  };

  const handleCancel = (e) => {
    setModelDeleteVisible(false);
  };

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  const handleCopyClick = async (id) => {
    try {
      await navigator.clipboard.writeText(
        `https://booking.posease.com/hotel/${id}`
      );

      message.success("Text copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy text");
    }
  };
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={contentforaction}
            trigger="click"
          >
            <CaretDownOutlined />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
    },
    {
      title: "Hotel Name",
      dataIndex: "hotel_name",
    },
    {
      title: "Register Name",
      dataIndex: "registerId",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: <div>{text?.register_name}</div>,
        };
      },
    },
    {
      title: "Booking Url",
      dataIndex: "hotel_name",
      render(text, record) {
        return {
          props: {
            style: { textAlign: "left" },
          },
          children: (
            <div
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <a
                style={{ color: "rgb(0, 140, 186)" }}
                href={`https://booking.posease.com/hotel/${record?.registerId?._id}`}
                target="_blank"
              >
                {`https://booking.posease.com/hotel/${record?.registerId?._id}`}
              </a>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  handleCopyClick(record?.registerId?._id);
                }}
                style={{
                  cursor: "pointer",
                  fontSize: "17px",
                  marginLeft: 10,
                }}
              >
                <CopyOutlined />
              </Button>
            </div>
          ),
        };
      },
    },
  ];

  return (
    <>
      <Cards
        title={
          <div style={{ boxShadow: "none", margin: " 0 0 0 3px" }}>
            <Input
              suffix={<SearchOutlined />}
              className="set_serbt"
              autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
              placeholder="Search by hotel name"
              style={{
                borderRadius: "30px",
                width: "250px",
              }}
              onChange={(e) => setsearch(e.target.value)}
              value={search}
            />
          </div>
        }
        isbutton={
          <NavLink
            to={offLineMode ? "#" : "booking-engine-setup/add"}
            className="ant-btn ant-btn-primary ant-btn-md"
            style={{ color: "#FFF" }}
            onClick={() =>
              offLineMode
                ? setOfflineModeCheck(true)
                : setOfflineModeCheck(false)
            }
          >
            <FeatherIcon icon="plus" size={16} className="pls_iconcs" />
            Add Setup
          </NavLink>
        }
      >
        <p style={{ display: "none" }}>{loading}</p>
        <Modal
          title="Confirm Delete"
          okText={
            loading ? (
              <Spin
                indicator={
                  <LoadingOutlined
                    style={{ fontSize: 16, color: "white", margin: "0px 14px" }}
                    spin
                  />
                }
              />
            ) : (
              "Delete"
            )
          }
          visible={modalDeleteVisible}
          onOk={deleteSelectedRegister}
          onCancel={handleCancel}
          width={600}
        >
          <p>Are you sure you want to delete BookingSetup ?</p>
        </Modal>
        <Modal
          title="You are Offline"
          visible={offLineModeCheck}
          onOk={() => setOfflineModeCheck(false)}
          onCancel={() => setOfflineModeCheck(false)}
          width={600}
        >
          <p>You are offline not add and update </p>
        </Modal>

        {loading ? (
          <>
            <Table
              rowKey="_id"
              locale={locale}
              dataSource={[]}
              columns={columns}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              onRow={(row) => ({
                onClick: () =>
                  offLineMode
                    ? setOfflineModeCheck(true)
                    : history.push(`booking-engine-setup/add`, {
                      data: row,
                    }),
              })}
              size="small"
              style={{ marginTop: "8px" }}
            />
          </>
        ) : (
          <Table
            rowKey="_id"
            locale={loading ? locale : false}
            dataSource={searchArrTaxes}
            columns={columns}
            rowSelection={{
              type: selectionType,
              ...rowSelection,
            }}
            onRow={(row) => ({
              onClick: () =>
                offLineMode
                  ? setOfflineModeCheck(true)
                  : history.push(`booking-engine-setup/add`, {
                    data: row,
                  }),
            })}
            size="small"
            style={{ marginTop: "8px" }}
          />
        )}
      </Cards>
    </>
  );
};

export default BookingSetup;
