import React, { useState, useEffect, useRef } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Radio,
  Button,
  Modal,
  Form,
  Pagination,
  Popover,
  Checkbox,
  message,
} from "antd";
import {
  SearchOutlined,
  LockTwoTone,
  DeleteOutlined,
  CaretDownOutlined,
  CopyOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "./Style";
import { PageHeader } from "../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../styled";
import { Cards } from "../../components/cards/frame/cards-frame";
import { CalendarButtonPageHeader } from "../../components/buttons/calendar-button/calendar-button";
import commonFunction from "../../utility/commonFunctions";
// import { Popover } from "../../components/popup/popup";
import "./UserManagement.css";
import {
  getAllActiveUsersList,
  changeStatus,
  FilterActiveInactiveStatus,
  deleteUser,
  ExportUser,
  getAllActiveUsersApiResp,
  updateUserStatus,
} from "../../redux/users/actionCreator";
import { AutoCompleteStyled } from "../../components/autoComplete/style";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { getItem, setItem } from "../../utility/localStorageControl";

const UserManagement = () => {
  const [loading, setLoading] = useState(false);
  let [search, setsearch] = useState();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const [modalVisible, setModelVisible] = useState(false);
  let [modalVisibleExport, setModelVisibleExport] = useState(false);
  const [userId, setUserId] = useState(false);
  const [modalVisibleDelete, setModelVisibleDelete] = useState(false);
  const [exportType, setExportType] = useState();
  const userDetails = getItem("userDetails");
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [ActiveUsersList, setActiveUsersList] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  // console.log("🚀 ~ file: UserManagement.js:42 ~ UserManagement ~ paginationData:", paginationData)
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);

  const [confirmUpdateVisible, setConfirmUpdateVisible] = useState(false);
  const [selectionType] = useState("checkbox");
  const [checkedVal, setCheckedVal] = useState("");
  const [checked, setChecked] = useState(false);
  const [openPopover, setOpenPopover] = useState(false);
  const [filterStatus, setFilterStatus] = useState(undefined);

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  useEffect(() => {
    fetchDataListResp(currentPage, dataPerPage, filterStatus, search);
  }, [search]);

  const fetchDataListResp = async (page, pageNumber, filterStatus, search) => {
    let params = {
      page: page,
      limit: pageNumber,
      filterStatus:
        filterStatus === "enquiry" ||
          filterStatus === "posease free" ||
          filterStatus === "posease pro" ||
          filterStatus === "not intrested"
          ? filterStatus
          : undefined,
      status:
        filterStatus === "pending" ||
          filterStatus === "deactivated" ||
          filterStatus === "activated" ||
          filterStatus === "blocked"
          ? filterStatus
          : undefined,
      search: search,
    };
    const dataList = await dispatch(getAllActiveUsersApiResp(params));
    if (dataList?.data) {
      setActiveUsersList(dataList?.data?.data);
      setPaginationData(dataList?.data?.pagination);
    }
  };

  const handlePagination = (page, pageNumber) => {
    // console.log("🚀 ~ file: UserManagement.js:72 ~ handlePagination ~ page:", page)
    fetchDataListResp(page, pageNumber, filterStatus, search);
    setCurrentPage(page);
    setDataPerPage(pageNumber);
  };

  //change status
  let isDeActive = async () => {
    let changedata = {
      user_id: userId,
      status: "deactivated",
    };
    await dispatch(changeStatus(changedata));
    setModelVisible(false);
    fetchDataListResp(currentPage, dataPerPage, filterStatus, search);
    // dispatch(getAllActiveUsersList());
  };

  const showModal = (id) => {
    if (id && id !== "") {
      setModelVisible(true);
      setUserId(id);
    }
  };

  const submitExport = (values) => {
    values.type = exportType;
    if (exportType) {
      dispatch(ExportUser(values));
      setModelVisibleExport(false);
      form.resetFields();
    }
  };
  const DeleteUser = async () => {
    if (userId) {
      let allUserId = [];
      allUserId.push(userId);
      const getDeleteUser = await dispatch(deleteUser({ ids: allUserId }));
      fetchDataListResp(currentPage, dataPerPage, filterStatus, search);
      // dispatch(getAllActiveUsersList());
      setModelVisibleDelete(false);
    }
  };

  // let searchArr = ActiveUsersList.filter((value) =>
  //   value.number.toLowerCase().includes(search.toLowerCase())
  // );

  const handleChangeForFilter = (e) => {
    setFilterStatus(e.target.value);
    fetchDataListResp(currentPage, dataPerPage, e.target.value, search);
  };

  const showModalDelete = (id) => {
    if (id && id !== "") {
      setModelVisibleDelete(true);
      setUserId(id);
    }
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisibleExport(true);
          setExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );

  const dataSource = [];

  if (ActiveUsersList.length > 0) {
    ActiveUsersList.map((value, i) => {
      const {
        _id,
        first_name,
        last_name,
        email,
        number,
        status,
        total_receipts,
        registerList,
        business_type,
        created_at,
        pin,
        shop_name,
        isPoseaseWeb,
      } = value;

      return dataSource.push({
        id: _id,
        first_name: first_name,
        key: i,
        last_name: last_name,
        email: email,
        number: number,
        status: status,
        total_receipts: total_receipts,
        registerList: registerList,
        business_type,
        created_at,
        pin,
        shop_name,
        isPoseaseWeb,
        subscription_status: registerList.find(
          (val) => val.subscription_status == "subscribed"
        )
          ? "subscribed"
          : "unsubscribed",
      });
    });
  }

  const onChangeFilter = (e) => {
    setChecked(e.target.checked);
    e.target.checked == true
      ? setCheckedVal(e.target.value)
      : setCheckedVal("");
  };

  const handleCancel = (e) => {
    setLoading(false);
    setConfirmUpdateVisible(false);
    setChecked(false);
    setCheckedVal("");
  };
  const [state, setState] = useState({
    item: ActiveUsersList,
  });
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setState({
        ...state,
        allSelectedRowsForStatus: selectedRows,
      });
    },
  };

  const updateStatusFilter = async (val) => {
    setLoading(true);
    const { allSelectedRowsForStatus } = state;
    let allUsersForFilter = [];
    allSelectedRowsForStatus.map((item) => {
      allUsersForFilter.push(item.id);
    });

    const payload = {
      ids: allUsersForFilter,
      filterStatus: val,
    };

    if (
      (allUsersForFilter.length > 0 && val !== null && val !== "",
        val !== undefined)
    ) {
      const updateStatus = await dispatch(updateUserStatus(payload));
      if (updateStatus) {
        setLoading(false);
        setConfirmUpdateVisible(false);
        setChecked(false);
        setCheckedVal("");
        setState({
          ...state,
          allSelectedRowsForStatus: [],
        });
      }
    }
  };

  const handleOpenChange = (newOpen) => {
    setOpenPopover(newOpen);
  };

  const ResetFilter = () => {
    setOpenPopover(false);
    setChecked(false);
    setCheckedVal("");
    setState({
      ...state,
      allSelectedRowsForStatus: [],
    });
  };
  const handleCopyClick = async (id) => {
    try {
      await navigator.clipboard.writeText(
        `https://web.posease.com/app/user/login/${id}`
      );

      message.success("Text copied to clipboard!");
    } catch (err) {
      message.error("Failed to copy text");
    }
  };
  const sendMessage = (phoneNumber, message) => {
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };
  const columns = [
    {
      title: (
        <>
          <Popover
            placement="bottomLeft"
            content={
              <div className="filterPopoverFooter">
                <p onClick={ResetFilter} className="filterReset">
                  Reset
                </p>
                <p
                  onClick={() => {
                    setConfirmUpdateVisible(true);
                    setOpenPopover(false);
                  }}
                  className="filterOk"
                >
                  Ok
                </p>
              </div>
            }
            title={
              <div className="filterOptionBox">
                <div className="filterOption">
                  <Checkbox
                    onChange={onChangeFilter}
                    checked={
                      checked && checkedVal === "enquiry" ? checked : false
                    }
                    value="enquiry"
                  >
                    Enquiry
                  </Checkbox>
                </div>
                <div className="filterOption">
                  <Checkbox
                    onChange={onChangeFilter}
                    checked={
                      checked && checkedVal === "posease free" ? checked : false
                    }
                    value="posease free"
                  >
                    Posease Free
                  </Checkbox>
                </div>
                <div className="filterOption">
                  <Checkbox
                    onChange={onChangeFilter}
                    checked={
                      checked && checkedVal === "posease pro" ? checked : false
                    }
                    value="posease pro"
                  >
                    Posease Pro
                  </Checkbox>
                </div>
                <div className="filterOption">
                  <Checkbox
                    onChange={onChangeFilter}
                    checked={
                      checked && checkedVal === "not intrested"
                        ? checked
                        : false
                    }
                    value="not intrested"
                  >
                    Not Interested
                  </Checkbox>
                </div>
              </div>
            }
            trigger="click"
            open={openPopover}
            onOpenChange={handleOpenChange}
          >
            <CaretDownOutlined style={{ marginLeft: "12px" }} />
          </Popover>
        </>
      ),
      key: "action",
      dataIndex: "action",
      width: "3%",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render(text, record) {
        return <>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</>;
      },
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Shop",
      dataIndex: "shop_name",
      key: "shop_name",
    },
    // {
    //   title: "Web form",
    //   dataIndex: "isPoseaseWeb",
    //   key: "isPoseaseWeb",
    //   render(text, record) {
    //     return <>{text ? "Yes" : "No"}</>;
    //   },
    // },
    {
      title: "Subscription",
      dataIndex: "subscription_status",
      key: "subscription_status",
    },
    {
      title: "Business Type",
      dataIndex: "business_type",
      key: "business_type",
      render(text, record) {
        return (
          <>
            {text == "hotel_management"
              ? "Hotel"
              : text == "food_and_drink"
                ? "Restaurant"
                : text}
          </>
        );
      },
    },
    {
      title: "Phone Number",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Total Receipts",
      dataIndex: "total_receipts",
      key: "total_receipts",
    },
    {
      title: "User Pin",
      dataIndex: "pin",
      key: "pin",
      render(text, record) {
        return <>{text ? text : ""}</>;
      },
    },
    {
      title: "Login link",
      dataIndex: "id",
      key: "id",
      render(text, record) {
        return (
          <>
            {<Button
              onClick={() => handleCopyClick(text)}
              style={{
                cursor: "pointer",
                fontSize: "17px",
                marginRight: 10,
              }}
            >
              <CopyOutlined />
            </Button>}

          </>
        );
      },
    },
    {
      title: "Subscription",
      dataIndex: "subscription",
      key: "subscription",
      render(text, record) {
        return (
          <>
            {userDetails?._id == "632b0fb5adf7940748e64e8e" && <FeatherIcon
              size={18}
              icon="arrow-right"
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/admin/user-management/subscription-detail`, {
                  registerList: record?.registerList,
                  user_id: record.id,
                });
              }}
            />

            }

          </>
        );
      },
    },
    {
      title: "Action",
      width: "10%",
      render(value, record) {
        return {
          props: {
            style: { textAlign: "center", display: "flex" },
          },

          children: (
            <>
              {record.status !== "deactivated" ? (
                <div>{userDetails?._id == "632b0fb5adf7940748e64e8e" && <Button
                  onClick={showModal.bind("", record.id)}
                  style={{
                    cursor: "pointer",
                    fontSize: "17px",
                    marginRight: 10,
                  }}
                >
                  <LockTwoTone />
                </Button>}</div>

              ) : (
                ""
              )}
              <Button
                onClick={() => sendMessage(record?.number, "")}
                style={{ cursor: "pointer", fontSize: "17px" }}
              >
                <WhatsAppOutlined />
              </Button>
              {userDetails?._id == "632b0fb5adf7940748e64e8e" && <Button
                onClick={showModalDelete.bind("", record.id)}
                style={{ cursor: "pointer", fontSize: "17px" }}
              >
                <DeleteOutlined />
              </Button>}

            </>
          ),
        };
      },
    },
  ];
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            className="admin-custom-pageheader"
            ghost
            title="Users Management"
            subTitle={
              <>
                <span className="title-counter">
                  {ActiveUsersList.length} Users
                </span>
                {/* <AutoCompleteStyled
                  className="certain-category-search"
                  style={{ width: "350px" }}
                  placeholder="Search by mobile number"
                > */}
                <Input
                  suffix={<SearchOutlined />}
                  autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                  onChange={(e) => setsearch(e.target.value)}
                  value={search}
                  placeholder="Search by mobile or email"
                  style={{
                    borderRadius: "30px",
                    width: "250px",
                    marginLeft: "10px",
                  }}
                />
                {/* </AutoCompleteStyled> */}
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions">
                <CalendarButtonPageHeader key="1" />
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="click"
                >
                  <Button size="small" type="white">
                    <FeatherIcon icon="download" size={14} />
                    Export
                  </Button>
                </Popover>
              </div>,
            ]}
          />
        </CardToolbox>
        <Cards headless>
          {/* <Row gutter={15}>
            <Col xs={24}> */}
          <Row gutter={15} className="justify-content-center">
            <Col xxl={24} lg={24} xs={24}>
              <div
                className="table-toolbox-menu"
                style={{ float: "right", marginBottom: 10 }}
              >
                <span className="toolbox-menu-title">
                  {" "}
                  Status:&nbsp;&nbsp;&nbsp;&nbsp;{" "}
                </span>
                <Radio.Group
                  onChange={handleChangeForFilter}
                  defaultValue="all"
                  className="userFilterRadio"
                >
                  <Radio.Button value="all">All</Radio.Button>
                  <Radio.Button value="activated">Activated</Radio.Button>
                  <Radio.Button value="deactivated">DeActivated</Radio.Button>
                  <Radio.Button value="pending">Pending</Radio.Button>
                  <Radio.Button value="blocked">Blocked</Radio.Button>
                  <Radio.Button value="enquiry">Enquiry</Radio.Button>
                  <Radio.Button value="posease free">Posease Free</Radio.Button>
                  <Radio.Button value="posease pro">Posease Pro</Radio.Button>
                  <Radio.Button value="not intrested">
                    Not Interested
                  </Radio.Button>
                </Radio.Group>
              </div>
            </Col>
          </Row>
          {/* </Col>
          </Row> */}
          <Row gutter={15}>
            <Col md={24}>
              <TableWrapper className="table-order table-responsive">
                <Table
                  rowKey="id"
                  size="small"
                  dataSource={dataSource}
                  columns={columns}
                  fixed={true}
                  scroll={{ x: 800 }}
                  pagination={false}
                  rowSelection={{
                    type: selectionType,
                    ...rowSelection,
                  }}
                />
                <Pagination
                  className="userManagement_Pagination"
                  total={paginationData?.total}
                  current={currentPage}
                  onChange={handlePagination}
                  pageSizeOptions={[10, 20, 50, 100]}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                  pageSize={dataPerPage}
                  showSizeChanger
                />
              </TableWrapper>
            </Col>
          </Row>
          <Modal
            title="Confirm Delete"
            okText="Delete Entry"
            open={modalVisibleDelete}
            onOk={DeleteUser}
            onCancel={() => setModelVisibleDelete(false)}
            width={600}
          >
            <p>
              Deleting the entry will permanently remove it and will no longer
              appear on reports. Are you sure you want to proceed?
            </p>
          </Modal>
          <Modal
            title="Confirm Deactivate"
            okText="Deactivate User"
            open={modalVisible}
            onOk={isDeActive}
            onCancel={() => setModelVisible(false)}
            width={600}
          >
            <p>Are you sure you want to Deactivate this user?</p>
          </Modal>
          <Modal
            title="Export Users"
            open={modalVisibleExport}
            onOk={form.submit}
            onCancel={() => setModelVisibleExport(false)}
            width={600}
          >
            <div>
              <Form
                style={{ width: "100%" }}
                name="export"
                form={form}
                onFinish={submitExport}
              >
                <div className="add-product-block">
                  <div className="add-product-content">
                    <Form.Item
                      label="Send to Email Address"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your email",
                          type: "email",
                        },
                      ]}
                    >
                      <Input placeholder="Enter email" />
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          </Modal>

          <Modal
            title="Update Status"
            okText={
              loading ? (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 16,
                        color: "white",
                        margin: "0px 23px",
                      }}
                      spin
                    />
                  }
                />
              ) : (
                "Confirm"
              )
            }
            open={confirmUpdateVisible}
            onOk={() => updateStatusFilter(checkedVal)}
            onCancel={handleCancel}
            width={600}
          >
            <p>Are you sure you want to Update Status of this Users ?</p>
          </Modal>
        </Cards>
      </Main>
    </>
  );
};

export default UserManagement;
