import actions from "./actions";
import axios from "axios";
import moment from "moment";
import {
  getItem,
  setItem,
  acceptOnlineOrderList,
} from "../../utility/localStorageControl";

import { ConsoleSqlOutlined } from "@ant-design/icons";

import { message } from "antd";

const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const {
  kitchenUserAdd,
  kitchenUserAddErr,
  kitchenUserList,
  kitchenUserListErr,
  kitchenUserId,
  kitchenUserIdErr,
  kitchenUserDelete,
  kitchenUserDeleteErr,
} = actions;

const addOrUpdateDyno = (formData, dynoId) => {
  return async (dispatch) => {
    try {
      let getAddedDyno = {};
      if (dynoId) {
        let response = await axios.post(`${formData.dynoUrl}/login`);
        if (
          response?.data?.statusMessage == "Success" ||
          response?.data?.status?.status == "success"
        ) {
          let payLoad = {
            registerId: formData.registerId,
            orderType:
              formData.dynoUrl.indexOf("zomato") > -1 ? "zomato" : "swiggy",
            url: formData.dynoUrl,
            details: {},
          };
          getAddedDyno = await DataService.put(
            API.onlineOrder.updateDyno + "/" + dynoId,
            payLoad
          );
        }
      } else {
        let response = await axios.post(`${formData.dynoUrl}/login`);
        if (
          response?.data?.statusMessage == "Success" ||
          response?.data?.status?.status == "success"
        ) {
          let payLoad = {
            registerId: formData.registerId,
            orderType:
              formData.dynoUrl.indexOf("zomato") > -1 ? "zomato" : "swiggy",
            url: formData.dynoUrl,
            details: {},
          };
          getAddedDyno = await DataService.post(
            API.onlineOrder.addDyno,
            payLoad
          );
        }
      }

      if (!getAddedDyno.data.error) {
        return getAddedDyno.data.data;
      } else {
        return dispatch(kitchenUserAddErr(getAddedDyno.data.data));
      }
    } catch (err) {
      dispatch(kitchenUserAddErr(err));
    }
  };
};

const onlineOrderProductList = (url) => {
  return async (dispatch) => {
    try {
      let response = await axios.get(url);
      if (response?.data?.data) {
        return response.data.data;
      } else {
        return response.data.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const accetOrders = (onlineOrder, orderID, checkZomato) => {
  return async (dispatch) => {
    try {
      if (onlineOrder && onlineOrder.length > 0) {
        let zomatoUrl = onlineOrder.find((val) => val.orderType == "zomato")
          ?.url;
        let swiggyUrl = onlineOrder.find((val) => val.orderType == "swiggy")
          ?.url;

        if (swiggyUrl && checkZomato != "Zomato") {
          const response = await axios.post(
            `${swiggyUrl}/orders/accept?order_id=${orderID}`
          );
        } else if (zomatoUrl && checkZomato == "Zomato") {
          const response = await axios.post(
            `${zomatoUrl}/orders/accept_order?order_id=${orderID}`
          );
        }
        return true;
      }
    } catch (err) {
      return false;
    }
  };
};
const redayOrders = (onlineOrder, orderID, checkZomato) => {
  return async (dispatch) => {
    try {
      if (onlineOrder && onlineOrder.length > 0) {
        let zomatoUrl = onlineOrder.find((val) => val.orderType == "zomato")
          ?.url;
        let swiggyUrl = onlineOrder.find((val) => val.orderType == "swiggy")
          ?.url;
        // for (let i = 0; i < onlineOrder.length; i++) {
        if (swiggyUrl && checkZomato != "Zomato") {
          const response = await axios.post(
            `${swiggyUrl}/orders/ready?order_id=${orderID}`
          );
        } else if (zomatoUrl && checkZomato == "Zomato") {
          const response = await axios.post(
            `${zomatoUrl}/orders/mark_ready?order_id=${orderID}`
          );
        }
        // }
        return true;
      }
    } catch (err) {
      return false;
    }
  };
};
const markInOutOfStock = (item) => {
  return async (dispatch) => {
    try {
      if (item.available == 1) {
        //outOfStockApiCall
        let response = await axios.post(
          `${item.url}/items/outofstock?item_id=${item.itemId}`
        );
        return response;
      } else {
        //inStockApicall
        let response = await axios.post(
          `${item.url}/items/instock?item_id=${item.itemId}`
        );
        return response;
      }
    } catch (err) {
      return false;
    }
  };
};

const getAlldynoList = () => {
  return async (dispatch) => {
    try {
      const getAllList = await DataService.get(`${API.onlineOrder.dynoList}`);

      if (!getAllList.data.error) {
        return getAllList.data.data;
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};

const getAllBookingSetupList = () => {
  return async (dispatch) => {
    try {
      const getAllList = await DataService.get(
        `${API.bookingSetup.getAllBookingSetups}`
      );

      if (!getAllList.data.error) {
        return getAllList.data.data;
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const getAllOrderList = (onlineOrder) => {
  return async (dispatch) => {
    try {
      let allOrderList = [];
      // zomato dummy order
      // let orderdetails = zomatoOrder;
      // console.log("orderdetails.cartDetails.dishes", orderdetails.cartDetails);
      // let productList = orderdetails.cartDetails.items.dishes.map((val) => {
      //   let productNameArray = [val.name];
      //   if (val.customisations && val.customisations.length > 0) {
      //     val.customisations.map((i) => {
      //       productNameArray.push(" / " + i.name);
      //     });
      //   }
      //   return {
      //     item_id: val.id,
      //     name: productNameArray.toString().replace(/,/gi, ""),
      //     price: val.unitCost,
      //     calculatedprice: val.totalCost,
      //     quantity: val.quantity,
      //     category: val.subCategoryId,
      //   };
      // });

      // let obj = {
      //   id: orderdetails.id,
      //   tax: 0,
      //   Source: "Zomato",
      //   order_id: orderdetails.id,
      //   sub_total:
      //     orderdetails?.cartDetails?.subtotal?.amountDetails.amountTotalCost,
      //   Value: orderdetails?.cartDetails?.total?.amountDetails.amountTotalCost,
      //   Time: moment(orderdetails.createdAt).format("MMM DD, Y, h:mm A"),
      //   Items: productList,
      //   Customer: orderdetails?.creator?.name ? orderdetails.creator.name : "",
      //   comment: orderdetails.instruction?.text
      //     ? orderdetails.instruction?.text
      //     : "",
      //   disconut: "",
      //   clickRow: false,
      //   otp: "23323",
      // };
      // if (orderdetails?.cartDetails.discountApplied?.discounts) {
      //   let totaldiscount = 0;
      //   orderdetails?.cartDetails.discountApplied?.discounts.map((val) => {
      //     let discountValue = -1 * Number(val.discount.totalDiscountAmount);
      //     totaldiscount = totaldiscount + discountValue;
      //   });
      //   console.log("totaldiscount", totaldiscount);
      //   if (totaldiscount > 0) {
      //     obj.disconut = totaldiscount;
      //   }
      // }
      // console.log("objjbnjjjhh", obj);

      // allOrderList.push(obj);
      // //swiggy dummy order

      // swiggyOrder.map((val) => {
      //   console.log("asadadadadadadad", val);

      //   if (
      //     val.status.order_status == "ordered" &&
      //     val.status.placed_status == "unplaced"
      //   ) {
      //     const { order_id, bill, customer, status, customer_comment } = val;
      //     const { items } = val.cart;
      //     let productList = items.map((val) => {
      //       console.log("productName90900l", val);
      //       let productNameArray = [val.name];

      //       if (val.newVariants && val.newVariants.length > 0) {
      //         val.newVariants.map((i) => {
      //           productNameArray.push(" / " + i.name);
      //         });
      //       }
      //       if (val.newAddons && val.newAddons.length > 0) {
      //         val.newAddons.map((i) => {
      //           productNameArray.push(" + " + i.name);
      //         });
      //       }

      //       return {
      //         item_id: val.item_id,
      //         name: "Dabeli",
      //         price: val.final_sub_total,
      //         calculatedprice: val.total,
      //         quantity: val.quantity,
      //         category: val.category,
      //         comment: customer_comment ? customer_comment : "",
      //       };
      //     });

      //     let obj = {
      //       id: order_id,
      //       tax: val.GST_details.swiggy_liable_gst
      //         ? val.GST_details.swiggy_liable_gst
      //         : 0,
      //       Source: "Swiggy",
      //       order_id: order_id,
      //       sub_total: 0,
      //       Value: bill,
      //       Time: moment(status.ordered_time).format("MMM DD, Y, h:mm A"),
      //       Items: productList,
      //       Customer: customer.customer_name ? customer.customer_name : "",
      //       CommentsDetails: "",
      //       disconut: val.discount ? val.discount : 0,
      //       clickRow: false,
      //       otp: "23323",
      //     };
      //     if (val?.cart?.charges?.packing_charge) {
      //       obj.packingCharge = val.cart.charges.packing_charge;
      //     }
      //     allOrderList.push(obj);
      //   }
      // });
      // console.log("lastOrderListlastOrderList1", allOrderList);
      // return dispatch(kitchenUserList(allOrderList));
      if (onlineOrder && onlineOrder.length > 0) {
        let zomatoUrl = onlineOrder.find((val) => val.orderType == "zomato")
          ?.url;
        let swiggyUrl = onlineOrder.find((val) => val.orderType == "swiggy")
          ?.url;

        if (swiggyUrl) {
          const getAllList = await axios.get(`${swiggyUrl}/orders`);
          if (getAllList?.data?.statusMessage == "Success") {
            console.log("cdcdcdcdcdcdcdc", getAllList.data.restaurantData);
            getAllList.data.restaurantData[0].orders.map((val) => {
              if (
                val.status.order_status == "ordered" &&
                val.status.placed_status == "unplaced"
              ) {
                const {
                  order_id,
                  bill,
                  customer,
                  status,
                  customer_comment,
                } = val;
                const { items } = val.cart;
                let productList = items.map((val) => {
                  let productNameArray = [val.name];

                  if (val.newVariants && val.newVariants.length > 0) {
                    val.newVariants.map((i) => {
                      productNameArray.push(" / " + i.name);
                    });
                  }
                  if (val.newAddons && val.newAddons.length > 0) {
                    val.newAddons.map((i) => {
                      productNameArray.push(" + " + i.name);
                    });
                  }

                  return {
                    item_id: val.item_id,
                    name: productNameArray.toString().replace(/,/gi, ""),
                    price: val.final_sub_total,
                    calculatedprice: val.total,
                    quantity: val.quantity,
                    category: val.category,
                    comment: customer_comment ? customer_comment : "",
                  };
                });

                let obj = {
                  id: order_id,
                  tax: val.GST_details.swiggy_liable_gst
                    ? val.GST_details.swiggy_liable_gst
                    : 0,
                  Source: "Swiggy",
                  order_id: order_id,
                  sub_total: 0,
                  Value: bill,
                  Time: moment(status.ordered_time).format("MMM DD, Y, h:mm A"),
                  Items: productList,
                  Customer: customer.customer_name
                    ? customer.customer_name
                    : "",
                  CommentsDetails: "",
                  disconut: val.discount ? val.discount : 0,
                  clickRow: false,
                  otp: customer?.customer_id
                    ?.toString()
                    ?.replace(/\d(?=\d{4})/g, "")
                    ? customer?.customer_id
                        ?.toString()
                        ?.replace(/\d(?=\d{4})/g, "")
                    : "",
                };
                if (val?.cart?.charges?.packing_charge) {
                  obj.packingCharge = val.cart.charges.packing_charge;
                }
                allOrderList.push(obj);
              }
            });
          }
        }
        if (zomatoUrl) {
          const getAllList = await axios.get(`${zomatoUrl}/orders/current`);

          if (getAllList?.data[0]?.new_orders?.entities) {
            let newOrders = getAllList.data[0].new_orders.entities;

            for (let i = 0; i < newOrders.length; i++) {
              if (newOrders[i].tab_id) {
                const getZamatoOrdersDetails = await axios.get(
                  `${zomatoUrl}/order/details?order_id=${newOrders[i].tab_id}`
                );
                console.log("getZamatoOrdersDetails", getZamatoOrdersDetails);
                if (getZamatoOrdersDetails?.data?.order?.state == "NEW") {
                  let orderdetails = getZamatoOrdersDetails?.data?.order;

                  let productList = orderdetails.cartDetails.items.dishes.map(
                    (val) => {
                      let productNameArray = [val.name];
                      if (val.customisations && val.customisations.length > 0) {
                        val.customisations.map((i) => {
                          productNameArray.push(" / " + i.name);
                        });
                      }
                      return {
                        item_id: val.id,
                        name: productNameArray.toString().replace(/,/gi, ""),
                        price: val.unitCost,
                        calculatedprice: val.totalCost,
                        quantity: val.quantity,
                        category: val.subCategoryId,
                      };
                    }
                  );
                  console.log(
                    "howcancheccncncjkccscscsc",
                    getZamatoOrdersDetails?.data
                  );
                  let obj = {
                    id: orderdetails.id,
                    tax: 0,
                    Source: "Zomato",
                    order_id: orderdetails.id,
                    sub_total:
                      orderdetails?.cartDetails?.subtotal?.amountDetails
                        .amountTotalCost,
                    Value:
                      orderdetails?.cartDetails?.total?.amountDetails
                        .amountTotalCost,
                    Time: moment(orderdetails.createdAt).format(
                      "MMM DD, Y, h:mm A"
                    ),
                    Items: productList,
                    Customer: orderdetails?.creator?.name
                      ? orderdetails.creator.name
                      : "",
                    comment: orderdetails.instruction?.text
                      ? orderdetails.instruction?.text
                      : "",
                    disconut: "",
                    clickRow: false,
                    otp: orderdetails?.otp ? orderdetails?.otp : "",
                  };
                  if (orderdetails?.cartDetails.discountApplied?.discounts) {
                    let totaldiscount = 0;
                    orderdetails?.cartDetails.discountApplied?.discounts.map(
                      (val) => {
                        let discountValue =
                          -1 * Number(val.discount.totalDiscountAmount);
                        totaldiscount = totaldiscount + discountValue;
                      }
                    );

                    if (totaldiscount > 0) {
                      obj.disconut = totaldiscount;
                    }
                  }
                  allOrderList.push(obj);
                }
              }
            }
          }
        }

        return dispatch(kitchenUserList(allOrderList));

        // return allOrderList
      }
    } catch (err) {
      let zomatoUrl = onlineOrder.find((val) => val.orderType == "zomato")?.url;
      let swiggyUrl = onlineOrder.find((val) => val.orderType == "swiggy")?.url;
      if (zomatoUrl) {
        const getZamatoOrdersDetails = await axios.post(`${zomatoUrl}/login`);
      }
      if (swiggyUrl) {
        const getZamatoOrdersDetails = await axios.post(`${swiggyUrl}/login`);
      }
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const getAllQROrderList = (registerId) => {
  return async (dispatch) => {
    try {
      const getAllList = await DataService.get(
        `${API.onlineOrder.qrOrderList}/${registerId}`
      );
      console.log("habhaicheckkariendjdc", getAllList);
      if (getAllList && getAllList.data && !getAllList.data.error) {
        return dispatch(kitchenUserId(getAllList.data.data));
      } else {
        message.error(getAllList.data.message);
        return true;
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const qrOrderAcceptOrReject = (registerId) => {
  return async (dispatch) => {
    try {
      const getAllList = await DataService.delete(
        `${API.onlineOrder.acceptOrReject}/${registerId}`
      );
      if (getAllList && getAllList.data && !getAllList.data.error) {
        return getAllList.data;
      } else {
        message.error(getAllList.data.message);
        return true;
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const getZometoDetail = (url, orderId) => {
  return async (dispatch) => {
    try {
      const getZamatoOrdersDetails = await axios.get(
        `${url}/order/details?order_id=${orderId}`
      );

      if (getZamatoOrdersDetails && getZamatoOrdersDetails?.data?.order) {
        return getZamatoOrdersDetails?.data.order;
      }
      // if (getZamatoOrdersDetails && getZamatoOrdersDetails?.data?.order?.supportingRiderDetails[0]) {
      //   return {
      //     name: getZamatoOrdersDetails?.data?.order.supportingRiderDetails[0].name,
      //     phone: getZamatoOrdersDetails?.data?.order.supportingRiderDetails[0].phone
      //   }
      // }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const getSwiggyDetail = (url, orderId) => {
  return async (dispatch) => {
    try {
      const getAllList = await axios.get(`${url}/orders`);

      if (
        getAllList?.data?.statusMessage == "Success" &&
        getAllList.data.restaurantData[0].orders
      ) {
        let orderdetails = getAllList.data.restaurantData[0].orders.find(
          (val) => val.order_id == orderId
        );
        return orderdetails;
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};
const getkitchenUserById = (id) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      let kitchenUserDetails;
      if (allSetupcache != null && allSetupcache.userList.kitchenUserList) {
        kitchenUserDetails = allSetupcache.userList.kitchenUserList.find(
          (val) => val._id == id
        );
      }
      if (kitchenUserDetails) {
        return dispatch(
          kitchenUserId({
            ...kitchenUserDetails,
            register_assigned_to: kitchenUserDetails.register_assigned_to._id,
          })
        );
      } else {
        const kitchenUserByIdData = await DataService.get(
          `${API.onlineOrder.getKitchenUserById}/${id}`
        );
        if (!kitchenUserByIdData.data.error) {
          return dispatch(kitchenUserId(kitchenUserByIdData.data.data));
        } else {
          return dispatch(kitchenUserIdErr(kitchenUserByIdData.data));
        }
      }
    } catch (err) {
      dispatch(kitchenUserIdErr(err));
    }
  };
};

const deleteDynos = (ids) => {
  return async (dispatch) => {
    try {
      const getDeletedkitchenUser = await DataService.post(
        API.onlineOrder.deleteAllDyno,
        ids
      );
      if (!getDeletedkitchenUser.data.error) {
        return dispatch(kitchenUserDelete(getDeletedkitchenUser.data));
      } else {
        return dispatch(kitchenUserDeleteErr(getDeletedkitchenUser.data));
      }
    } catch (err) {
      dispatch(kitchenUserDeleteErr(err));
    }
  };
};
const deleteBookingSetup = (ids) => {
  return async (dispatch) => {
    try {
      const getDeletedkitchenUser = await DataService.post(
        API.bookingSetup.deleteAllBookingSetups,
        ids
      );
      if (!getDeletedkitchenUser.data.error) {
        return dispatch(kitchenUserDelete(getDeletedkitchenUser.data));
      } else {
        return dispatch(kitchenUserDeleteErr(getDeletedkitchenUser.data));
      }
    } catch (err) {
      dispatch(kitchenUserDeleteErr(err));
    }
  };
};

export {
  addOrUpdateDyno,
  onlineOrderProductList,
  getkitchenUserById,
  markInOutOfStock,
  getAlldynoList,
  getAllOrderList,
  accetOrders,
  redayOrders,
  deleteDynos,
  getZometoDetail,
  getSwiggyDetail,
  getAllQROrderList,
  qrOrderAcceptOrReject,
  getAllBookingSetupList,
  deleteBookingSetup,
};
