import axios from "axios";
import { getItem, setItem } from "../../utility/localStorageControl";
import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const {
  roomAdd,
  roomList,
  roomListErr,
  roomId,
  roomIdErr,
  roomDelete,
  roomDeleteErr,
} = actions;

const addOrUpdateRoomLocation = (formData, roomType_id) => {
  return async (dispatch) => {
    try {
      let getAddedroomType = {};
      if (roomType_id) {
        getAddedroomType = await DataService.put(
          `${API.roomLocation.updateRoomLocation}/${roomType_id}`,
          formData
        );
        if (!getAddedroomType.data.error) {
          return getAddedroomType.data;
        } else {
          return getAddedroomType.data;
        }
      } else {
        getAddedroomType = await DataService.post(
          API.roomLocation.addRoomLocation,
          formData
        );
      }
      if (!getAddedroomType.data.error) {
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getAllRoomLocationList = (registerId) => {
  return async (dispatch) => {
    try {
      let url = API.roomLocation.getAllRoomLocation;
      if (registerId) {
        url = `${API.roomLocation.getAllRoomLocation}?registerId=${registerId}`;
      }
      const getroomTypeList = await DataService.get(url);
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getRoomById = (id) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      let roomTypeDetails;
      if (allSetupcache != null && allSetupcache.roomType) {
        roomTypeDetails = allSetupcache.roomType.find((val) => val._id == id);
      }
      if (roomTypeDetails) {
        return dispatch(roomId(roomTypeDetails));
      } else {
        const roomTypeByIdData = await DataService.get(
          `${API.roomType.getroomTypeById}/${id}`
        );
        if (!roomTypeByIdData.data.error) {
          return dispatch(roomId(roomTypeByIdData.data.data));
        } else {
          return dispatch(roomIdErr(roomTypeByIdData.data));
        }
      }
    } catch (err) {
      dispatch(roomIdErr(err));
    }
  };
};

const deleteRoomLocation = (roomTypeIds) => {
  return async (dispatch) => {
    try {
      const getDeletedroomType = await DataService.post(
        API.roomLocation.deleteRoomLocation,
        roomTypeIds
      );
      if (!getDeletedroomType.data.error) {
        return getDeletedroomType.data;
      } else {
        return getDeletedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const addRate = (formData) => {
  return async (dispatch) => {
    try {
      let rate = await DataService.post(API.rateDate.addUpdateRate, formData);
      if (!rate.data.error) {
        return rate.data;
      } else {
        return rate.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const multiAddRate = (formData) => {
  return async (dispatch) => {
    try {
      let rate = await DataService.post(API.rateDate.multiAdd, formData);
      if (!rate.data.error) {
        return rate.data;
      } else {
        return rate.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const updateChannelRates = (channel_key, payload) => {
  return async (dispatch) => {
    try {
      let resoponse = await axios.post(
        `https://www.eglobe-solutions.com/webapichannelmanager/rates/${channel_key}/bulkupdate`,
        payload
      );
      console.log("Responsefasdfasf", resoponse);
      return resoponse;
    } catch (err) {
      if (err.response) {
        message.error(err?.response?.data)
        if (err.response.data) {
          console.log("API Error Message:", err.response.data);
        }
      } else {
        message.error(err.message)

      }
      return false;
    }
  };
};
const updateChannelAvailability = (channel_key, payload) => {
  return async (dispatch) => {
    try {
      let resoponse = await axios.post(
        `https://www.eglobe-solutions.com/webapichannelmanager/inventory/${channel_key}/bulkupdate/v2`,
        payload
      );
      console.log("Responsefasdfasf", resoponse);
      return resoponse;
    } catch (err) {
      if (err.response) {
        message.error(err?.response?.data)
        if (err.response.data) {
          console.log("API Error Message:", err.response.data);
        }
      } else {
        message.error(err.message)

      }
      return false;
    }
  };
};
const getAllRateDateWise = (registerId, obj) => {
  return async (dispatch) => {
    try {
      let url = API.rateDate.getAllRates;
      if (registerId && obj) {
        url = `${API.rateDate.getAllRates}?registerId=${registerId}&startDate=${obj.startDate}&endDate=${obj.endDate}`;
      }
      const getroomTypeList = await DataService.get(url);
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export {
  addOrUpdateRoomLocation,
  getAllRoomLocationList,
  getRoomById,
  deleteRoomLocation,
  addRate,
  getAllRateDateWise,
  multiAddRate,
  updateChannelRates,
  updateChannelAvailability,
};
