import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Modal,
  DatePicker,
  Button,
  Form,
  Select,
  Divider,
  Popover,
  Spin,
  Radio,
  Table,
  Typography,
  Tabs,
  Segmented,
  message,
  Tag,
  Space,
  Checkbox,
  Badge,
  TreeSelect,
  Tooltip,
} from "antd";
import {
  EditOutlined,
  ArrowLeftOutlined,
  CaretDownOutlined,
  SearchOutlined,
  PlusOutlined,
  PrinterOutlined,
  DeleteOutlined,
  UnlockOutlined,
  EyeOutlined,
  LoadingOutlined,
  StarOutlined,
  ConsoleSqlOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import FeatherIcon from "feather-icons-react";
import allRoomTableUser from "../../../static/img/guest-svgrepo-white.svg";
import checkoutUser from "../../../static/img/guest-traveler-svgrepo-com.svg";
import checkInUser from "../../../static/img/guest-svgrepo-com.svg";
import { NavLink } from "react-router-dom/cjs/react-router-dom";
import { getItem, setItem } from "../../../utility/localStorageControl";
import ReactDOMServer from "react-dom/server";
import CustomerPrint from "../CustomerPrint";
import ConformationPrint from "../conformationPrint";
import HotelInvoice from "../HotelInvoice";
import { useDispatch, useSelector } from "react-redux";
import { getAllRoomList } from "../../../redux/room/actionCreator";
import {
  checkRoomAvailblity,
  getAllroomTypeList,
} from "../../../redux/roomType/actionCreator";
import {
  reservationCheckInCheckOut,
  createAllRoomInvoice,
  roomIdByUpdate,
  addUpdateNotes,
  addOrDeleteInvoice,
  getAllRoomReceipts,
  customerAddUpdate,
  addAddtionalExtraRoom,
  getReservationList,
  getRoomReservation,
  sendConfimationLater,
} from "../../../redux/reservation/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import moment from "moment";
import { SellModuleNav } from "../../Sell/Style";
import RoomCustomer from "./RoomCustomer";
import AddAddtionalRoom from "./AddSingleAddtionalRoom";
import EditRoomPrice from "./EditRoomPrice";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllRateDateWise,
  updateChannelAvailability,
} from "../../../redux/roomLocation/actionCreator";
import { getAllPaymentTypeList } from "../../../redux/customField/actionCreator";
import "./assign.css";
import OTA from "./ota";
import Direct from "./diarect";
const { TextArea } = Input;
const { Text } = Typography;
const { TabPane } = Tabs;

function AssignRoom(props) {
  let {
    tabChangeToCurrentFunction,
    reservationDetails,
    currentRegisterData,
  } = props;
  const [importantNotes, setImportantNotes] = useState(false);

  const [reservationData, setReservationData] = useState(
    reservationDetails?.reservationInDateAndTime
      ? reservationDetails
      : undefined
  );
  const history = useHistory();
  const [invoiceList, setInvoiceList] = useState([]);
  const { SHOW_PARENT } = TreeSelect;
  const [totalIds, setTotalId] = useState([]);

  const [source, setSource] = useState(
    reservationDetails?.reservationInDateAndTime
      ? reservationDetails?.general?.source
      : "Direct"
  );
  const [channel, setChannel] = useState(
    reservationDetails?.reservationInDateAndTime
      ? reservationDetails?.general?.channel
      : "Walk-in"
  );
  let [roomTypeList, setRoomTypeList] = useState([]);
  let [roomList, setRoomList] = useState([]);
  let [roomName, setRoomName] = useState([]);

  const [addForm] = Form.useForm();

  const [notesForm] = Form.useForm();
  const [invoiceForm] = Form.useForm();
  const [editRoomDetailsForm] = Form.useForm();
  const [mailForm] = Form.useForm();
  const [transactionForm] = Form.useForm();
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteInvoiceModal, setDeleteInvoiceModal] = useState({
    id: "",
    visible: false,
  });
  const [cancelReservation, setCancelReservation] = useState({
    visible: false,
    type: "room",
  });
  const [deleteTransacationModal, setDeleteTransacation] = useState({
    id: "",
    visible: false,
    value: 0,
  });
  const [overBookingModal, setOverBookingModal] = useState(false);
  const [addtionalUpdateId, setAddtionalUpdateId] = useState();
  const [invoiceType, setInvoiceType] = useState("All");
  const [folioType, setFoliotype] = useState("All");
  const [customerModalVisible, setCustomerModalVisible] = useState({
    visible: false,
    type: "room",
  });
  const { Option } = Select;
  const [singleRoomCustomerInfo, setSingleRoomCustomerInfo] = useState({});
  const [folioForm] = Form.useForm();
  const [addtionalForm] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [addFolio, setAddFolio] = useState(false);
  const [addRoomModalVisible, setAddRoomModalVisible] = useState(false);
  const [addAdtioanlModal, setAddtionalModal] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [invoiceModal, setInvoiceModal] = useState(false);
  const [currentStatus, setStatus] = useState("All");
  const [logList, setLogList] = useState([]);
  const [editRoomInfo, setEditRoomInfo] = useState(false);
  const [notesModal, setNotesModal] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [treeDataObj, setTreeDataObj] = useState({});
  const [treevalues, setTreeValues] = useState([]);
  const [addtionalCharge, setAddtionalCharge] = useState(
    getItem("setupCache")?.additionalCharges
  );
  const [totalAddtionalValue, setTotalAddtionalValue] = useState({
    tax: 0,
    total: 0,
  });
  const [invoiceCalCulatedValue, setInvoiceCalCulatedValue] = useState({
    tax: 0,
    total: 0,
  });
  const [selectAddtionalCharge, setSelectAddtionalCharge] = useState();
  let userDetails = getItem("userDetails");
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  let localData = getItem("setupCache");
  const [rsSymbol, setRsSymbol] = useState(
    localData?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)?.length > 0
        ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)[1]
        : localData.shopDetails.rs_symbol
      : "₹"
  );

  const [roomListData, setRoomListData] = useState([]);
  let [roomDetails, setRoomDetails] = useState();

  const [reservationStartDate, setReservationStartDate] = useState(
    moment(reservationDetails?.reservationInDateAndTime).format(
      "ddd DD MMM YYYY"
    )
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    moment(reservationDetails?.reservationOutDateAndTime).format(
      "ddd DD MMM YYYY"
    )
  );
  const [roomReservarionStartDate, setRoomReservationStartDate] = useState(
    moment(reservationDetails?.reservationInDateAndTime).format(
      "ddd DD MMM YYYY"
    )
  );
  const [roomReservationEndDate, setRoomReservationEndDate] = useState(
    moment(reservationDetails?.reservationOutDateAndTime).format(
      "ddd DD MMM YYYY"
    )
  );

  const [numberOfNights, setNumberOfNights] = useState(
    moment(reservationEndDate).diff(moment(reservationStartDate), "days")
  );
  const [PaymentTypeList, setPaymentTypeList] = useState([]);
  let isMounted = useRef(true);

  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const [fetchRoomLoader, setFetchRoomLoader] = useState(true);
  // const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});
  const fetchDateWiseRate = async (startDate1, endDate1) => {
    setFetchRoomLoader(true);
    let startDate = moment(startDate1).format("MM/DD/YYYY");
    let endDate = moment(endDate1)
      .subtract(1, "days")
      .format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      // setRateDatewiseRateObj(obj);
      setFetchRoomLoader(false);
      return obj;
    }
  };
  async function fetchRoomList(registerId) {
    setFetchRoomLoader(true);
    const getRoomList = await dispatch(getAllRoomList(registerId, "sell"));
    if (getRoomList && getRoomList.error == false) {
      if (reservationData && reservationData.RoomId) {
        setFetchRoomLoader(true);
      } else {
        setFetchRoomLoader(false);
      }
      setRoomListData(getRoomList?.roomList.reverse());
    } else {
      message.error({
        content: getRoomList.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  }

  async function fetchRoomWithReservation(
    registerId,
    startDate,
    endDate,
    roomInfoId,
    roomType
  ) {
    setFetchRoomLoader(true);
    let getReservationListData = await dispatch(
      getRoomReservation(
        currentRegisterData._id,
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate)
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        roomInfoId
      )
    );
    if (
      getReservationListData?.error == false &&
      getReservationListData?.data
    ) {
      let { data } = getReservationListData;

      const getRoomList = await dispatch(getAllRoomList(registerId, "sell"));
      if (getRoomList && getRoomList.error == false) {
        getRoomList?.roomList.reverse().map((room) => {
          if (
            data[room._id] &&
            (data[room._id].status == "assign" ||
              data[room._id].status == "checkin" ||
              data[room._id].status == "cleaning")
          ) {
            room.status = data[room._id].status;
          } else {
            room.status = "available";
          }
        });

        setFetchRoomLoader(false);
        setRoomListData(getRoomList?.roomList.reverse());
        return getRoomList?.roomList.reverse();
      } else {
        setFetchRoomLoader(false);
        message.error({
          content: getRoomList.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
    }
  }

  function disabledEndDate(current) {
    return current && current <= moment(roomReservarionStartDate);
  }

  async function fetchRoomTypeList(registerId) {
    const getroomListData = await dispatch(
      getAllroomTypeList(registerId, "sell")
    );
    if (isMounted.current) {
      let list = [];
      if (getroomListData?.data?.length) {
        list = getroomListData?.data.map((val) => {
          val.tax_group.taxes = val.tax_group.taxes.map((k) => {
            if (val.taxes_inclusive_in_product_price) {
              k["type"] = "inclusive";
            } else {
              k["type"] = "exclusive";
            }
            return k;
          });
          return val;
        });
      }
      setRoomTypeList(list);
    }
  }
  async function fetchPaymentType() {
    const getPaymentTypeList = await dispatch(getAllPaymentTypeList("sell"));
    if (
      isMounted.current &&
      getPaymentTypeList &&
      getPaymentTypeList.PaymentTypeList
    ) {
      setPaymentTypeList([...getPaymentTypeList.PaymentTypeList.reverse()]);
    }
  }
  useEffect(() => {
    if (currentRegisterData && currentRegisterData._id) {
      fetchRoomList(currentRegisterData._id);
      fetchRoomTypeList(currentRegisterData._id);
      fetchPaymentType();
    }
  }, [currentRegisterData?._id]);

  const [pendingPrice, setPendingPrice] = useState(0);
  const [checkOneTimeInAllPayment, setCheckOneTimeInALlPayment] = useState(
    false
  );
  const [addtionalValue, setAddtionalValue] = useState(0);
  const [addtionalQty, setAddtionalQty] = useState(1);
  const [checkDeleteIcon, setCheckDeleteIcon] = useState(false);
  const [pendingButtonDisable, setPendingButtonDisable] = useState(true);
  const [allCheckoutRoomDone, setALlCheckOutRoomDone] = useState(false);
  let [buclkDiscontDetails, setBulckDisountDetails] = useState(
    reservationDetails?.payment?.discountObj
      ? reservationDetails?.payment?.discountObj
      : {
        type: "FLAT",
        value: 0,
        click: false,
      }
  );
  let [bulckdiscuntButtonText, setBulckDiscontButtonText] = useState({
    text: "Bulk discount",
    color: "#008cba",
    discountValue: 0,
  });
  let bulckDiscontCalculation = (total, totalTaxes, selectedRoomtype) => {
    let crrentTotalPrice = total + totalTaxes;
    if (
      buclkDiscontDetails.type == "FLAT" &&
      buclkDiscontDetails.value > crrentTotalPrice
    ) {
      setBulckDiscontButtonText({
        color: "red",
        text: "Discount is more than total",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    } else if (
      buclkDiscontDetails.type == "FLAT" &&
      buclkDiscontDetails.value > 0
    ) {
      let bulkPrice = buclkDiscontDetails.value / total;
      let taxesArr = [];
      let Totaltaxandbulk = 0;
      let totalPrice = 0;
      selectedRoomtype.map((value, index) => {
        let calCulatedPrice = value.calculatedPriceWithoutTax;
        let productTaxes = 0;
        if (value?.room_type?.tax_group?.taxes?.length) {
          value.room_type.tax_group.taxes.map((j) => {
            if (j.type == "exclusive") {
              productTaxes += j.tax_percentage;
            }
          });
        }
        totalPrice += calCulatedPrice;

        let bulkprice2 = calCulatedPrice * bulkPrice;
        let bulkPrice3 = calCulatedPrice - bulkprice2;
        Totaltaxandbulk += (bulkPrice3 * productTaxes) / 100;

        // value.taxGroup.taxes.map((i) => {
        //   i.totalTaxPrice = (bulkPrice3 * i.tax_percentage) / 100;
        // });
      });
      // taxesArr.map((tax) => (Totaltaxandbulk += tax));

      let bulkfinalTotal = totalPrice - buclkDiscontDetails.value;
      setBulckDiscontButtonText({
        text: `Bulk discount ${rsSymbol}${Number(
          buclkDiscontDetails.value
        ).toFixed(2)}`,
        color: "#008cba",
        discountValue: Number(buclkDiscontDetails.value).toFixed(2),
      });

      return {
        total: bulkfinalTotal,
        totalTaxes: Totaltaxandbulk,
      };
    } else if (
      buclkDiscontDetails.type == "PERCENTAGE" &&
      buclkDiscontDetails.value > 0
    ) {
      selectedRoomtype.map((product) => {
        let calCulatedPrice =
          product.calculatedprice -
          Number(
            product.customDiscountedValue ? product.customDiscountedValue : 0
          );
        product?.taxGroup?.taxes?.length > 0 &&
          product.taxGroup.taxes.map((j) => {
            let bulckProductTax =
              j.tax_percentage -
              (j.tax_percentage * buclkDiscontDetails.value) / 100;

            return (j.totalTaxPrice =
              (bulckProductTax * calCulatedPrice) / 100);
          });
      });

      let calTax = totalTaxes - (totalTaxes * buclkDiscontDetails.value) / 100;
      let caltotal = total - (total * buclkDiscontDetails.value) / 100;
      setBulckDiscontButtonText({
        text: `Bulk discount ${rsSymbol}${Number(
          (total * buclkDiscontDetails.value) / 100
        ).toFixed(2)}`,
        color: "#008cba",
        discountValue: Number(
          (total * buclkDiscontDetails.value) / 100
        ).toFixed(2),
      });
      return {
        total: caltotal,
        totalTaxes: calTax,
      };
    } else if (
      buclkDiscontDetails.type == "PERCENTAGE" &&
      buclkDiscontDetails.value > (total * buclkDiscontDetails.value) / 100
    ) {
      setBulckDiscontButtonText({
        color: "red",
        text: "Discount is more than total",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    } else {
      setBulckDiscontButtonText({
        color: "#008cba",
        text: "Bulk discount",
        discountValue: 0,
      });
      return {
        totalTaxes,
        total,
      };
    }
  };
  const createRoomDetails = () => {
    if (reservationData.payment.currency) {
      setRsSymbol(reservationData.payment.currency);
    }
    let rooms = [];
    let checkAssign = false;
    let roomName = [{ label: `All`, value: "All" }];
    let pendingAmount = 0;
    let checkOutRoom = 0;
    let checkOutCount = 0;
    let withoutCancelRoom = 0;
    let checkAll = false;
    let totalWithoutTax = 0;
    let totalTax = 0;

    reservationData.roomInfo.map((val, index) => {
      console.log("valvalvalvalvalval", val);

      if (!val.isCancelled) {
        pendingAmount = pendingAmount + Number(val.calculatedPriceWithTax);
        withoutCancelRoom = withoutCancelRoom + 1;
        totalWithoutTax =
          totalWithoutTax + Number(val.calculatedPriceWithoutTax);
        totalTax = totalTax + Number(val.calCulatedTaxPrice);
      }
      if (val.isCheckedIn && !val.isCancelled) {
        checkOutRoom = checkOutRoom + 1;
      }
      if (val.isCheckedOut && !val.isCancelled) {
        checkOutCount = checkOutCount + 1;
      }
      val.avialbleRoomList = [];
      console.log("dasdjadjakdajdadadada", moment(val?.reservationInDateAndTime).format("YYYY-MM-DD HH:mm:ss"))
      if (
        moment()
          .startOf("day")
          .set({ hour: 12, minute: 30 }) < moment(val?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
      ) {
        val["checkUpcoming"] = true;
      } else {
        val["checkUpcoming"] = false;
      }
      // let { total, totalTaxes } = bulckDiscontCalculation(
      //   totalWithoutTax,
      //   totalTax,
      //   reservationData.roomInfo
      // );

      rooms.push({
        ...val,
        cancel: val.isCancelled ? true : false,
        type: "room",
        addTime: val.checkOutDateAndTime
          ? val.checkOutDateAndTime
          : val.checkInDateAndTime
            ? val.checkInDateAndTime
            : val.assignDateAndTime
              ? val.assignDateAndTime
              : val.reservationBookTime,
      });
      roomName.push({
        label: `${val.room_type.name} ${val.room_name}`,
        value: val._id,
      });
      if (val.isAssignIn || val.isCancelled) {
        checkAssign = true;
      }
      val?.addtionalItem?.map((r) => {
        rooms.push({
          type: "addtional-item",
          item: r.itemName,
          calculatedPriceWithoutTax: Number(r.amount.total),
          paid_room: r.roomName,
          room_type: val.room_type,
          customerInfo: val.customerInfo,
          roomInfoId: val._id,
          itemId: r.itemId,
          amount: r.amount,
          updateId: r._id,
          poseaseBill: r.type == "posease-restaurant" ? true : false,
          cancel: r.cancel ? true : false,
          addTime: r.addTime,
          notes: r.notes,
        });
        if (!r.cancel) {
          pendingAmount = pendingAmount + Number(r.amount.total);
        }
      });

      val?.transationList?.map((r) => {
        if (r) {
          if (r.type != "pending") {
            rooms.push({
              type: "payment",
              item: r.type,
              calculatedPriceWithoutTax: r.amount,
              paid_room: r.roomName,
              room_type: val.room_type,
              customerInfo: val.customerInfo,
              transId: r._id,
              cancel: r.cancel ? true : false,
              cancel_notes: r.cancel_notes,
              addTime: r.paidTime,
              notes: r.notes,
            });
          }

          if (!r.cancel && r.type != "pending") {
            if (r.folio_type == "All") {
              checkAll = true;
            }
            pendingAmount = pendingAmount - Number(r.amount);
          }
        }
      });

      if (reservationData.RoomId && reservationData.RoomId == val.roomId) {
        setSelectRoomIndex(index);
        editFromData(val);
        setRoomDetails(val);
        setStatus(val._id);
      }
    });
    if (checkAssign) {
      setCheckDeleteIcon(false);
    } else {
      setCheckDeleteIcon(true);
    }
    setPendingPrice(pendingAmount);
    setCheckOneTimeInALlPayment(checkAll);
    const sortByDate = (a, b) => {
      return new Date(b.addTime) - new Date(a.addTime);
    };
    console.log("roomsroomsroomsrooms", rooms);
    setRoomList(rooms.sort(sortByDate));
    setRoomName(roomName);

    if (withoutCancelRoom == checkOutRoom) {
      setPendingButtonDisable(false);
    } else {
      setPendingButtonDisable(true);
    }
    if (withoutCancelRoom == checkOutCount) {
      setALlCheckOutRoomDone(true);
    } else {
      setALlCheckOutRoomDone(false);
    }
    setReservationStartDate(
      moment(reservationData?.reservationInDateAndTime).format(
        "ddd DD MMM YYYY"
      )
    );
    setReservationEndDate(
      moment(reservationData?.reservationOutDateAndTime).format(
        "ddd DD MMM YYYY"
      )
    );
    setNumberOfNights(
      moment(reservationData?.reservationOutDateAndTime).diff(
        moment(reservationData?.reservationInDateAndTime),
        "days"
      )
    );
  };

  useEffect(() => {
    if (reservationData && reservationData.reservationInDateAndTime) {
      createRoomDetails();
    }
  }, [reservationData]);

  let roomMount = useRef(true);

  useEffect(() => {
    if (roomMount.current == false && reservationData.RoomId) {
      setCategory(reservationData.RoomId, true);
    } else {
      roomMount.current = false;
    }
  }, [roomTypeList]);
  // ROOM CATEGORY LIST
  const [seleroomIndex, setSelectRoomIndex] = useState();

  const editFromData = (roomData, startDate, endDate) => {
    editRoomDetailsForm.setFieldsValue({
      room_edit_room_type: JSON.stringify(
        roomTypeList.find((val) => val._id == roomData.room_type._id)
      ),
      room_edit_rate_plan:
        roomData.rate_plan == "-" ? "-" : JSON.stringify(roomData.rate_plan),
      room_edit_room_name: roomData.roomId,
      reservationInDateAndTime: moment(
        startDate
          ? startDate
          : moment(roomData?.reservationInDateAndTime).format(
            "ddd DD MMM YYYY"
          ),
        "ddd DD MMM YYYY"
      ),
      reservationOutDateAndTime: moment(
        endDate
          ? endDate
          : moment(roomData?.reservationOutDateAndTime).format(
            "ddd DD MMM YYYY"
          ),
        "ddd DD MMM YYYY"
      ),
    });
  };

  const handleShowAvailableRoomList = async (
    startDate,
    endDate,
    roomDetails
  ) => {
    let startObject = new Date(startDate);
    startObject.setHours(12, 30, 0, 0);
    let endObject = new Date(endDate);
    endObject.setHours(12, 30, 0, 0);
    let roomListData = await fetchRoomWithReservation(
      currentRegisterData?._id,
      startObject,
      endObject,
      roomDetails._id,
      roomDetails?.room_type
    );
    if (roomListData) {
      let avialbleRoomList = roomListData
        .reverse()
        .filter(
          (j) =>
            j.status == "available" &&
            j.room_type._id == roomDetails?.room_type?._id
        );
      setRoomDetails({ ...roomDetails, avialbleRoomList });
    }
  };

  const setCategory = async (type, checkIsRoomId) => {
    setReservationDisable(false);
    // let roomList = rooms ? rooms : roomList;
    if (type != "All") {
      let index = roomList.findIndex((l) =>
        checkIsRoomId ? l.roomId == type : l._id == type
      );
      setSelectRoomIndex(index);
      setStatus(roomList[index]._id);
      if (roomList[index]?.roomId) {
        roomList[index]["avialbleRoomList"] = roomListData.filter(
          (j) =>
            (j.status == "available" &&
              j.room_type._id == roomList[index]?.room_type?._id) ||
            j._id == roomList[index]?.roomId
        );
      } else if (roomList[index] && roomListData) {
        roomList[index]["avialbleRoomList"] = roomListData.filter(
          (j) =>
            (j.status == "available" &&
              j.room_type._id == roomList[index]?.room_type?._id) ||
            j._id == roomList[index]?.roomId
        );
      }
      if (roomList[index]) {
        console.log("dasdsadasdadadadaadadadad", roomList[index])
        setRoomDetails(roomList[index]);
        setRoomReservationStartDate(
          moment(roomList[index]?.reservationInDateAndTime).format(
            "ddd DD MMM YYYY"
          )
        );
        setRoomReservationEndDate(
          moment(roomList[index]?.reservationOutDateAndTime).format(
            "ddd DD MMM YYYY"
          )
        );

        editFromData(roomList[index]);
        if (checkIsRoomId) {
          setStatus(roomList[index]._id);
          setFetchRoomLoader(false);
        }
      } else {
        setRoomDetails();
        setSelectRoomIndex();
      }
      if (checkIsRoomId == undefined) {
        setStatus(type);
      }
    } else if (type == "All") {
      setStatus(type);
    }
  };

  const [notesLoader, setNotesLoader] = useState(false);
  const [invoiceLoader, setInvoiceLoader] = useState(false);
  const [editNote, setEditNote] = useState(false);

  const submitNotes = async (formData) => {
    setNotesLoader(true);

    let payload = {
      reservationId: reservationData?._id,
      notesDetails: {
        createAt: new Date(),
        updateAt: new Date(),
        text: formData.notes,
        important: importantNotes,
        delete: editNote && editNote.check == "delete" ? true : false,
      },
    };

    const getallRoomTable = await dispatch(
      addUpdateNotes(payload, editNote?._id)
    );
    if (getallRoomTable && getallRoomTable.error == false) {
      setReservationData(getallRoomTable.data);
      setNotesLoader(false);
      setNotesModal(false);
    }
  };

  const [fetchInvoiceLoader, setFetchInvoiceLoader] = useState(true);
  const getInvoice = async (type) => {
    if (type) {
      setStatus("invoice");
    }
    setFetchInvoiceLoader(true);
    let response = await dispatch(getAllRoomReceipts(reservationData?._id));
    if (response && response.data) {
      setFetchInvoiceLoader(false);
      setInvoiceList(response.data);
    }
  };

  const submitInvoice = async () => {
    if (invoiceLoader == false) {
      invoiceForm.validateFields().then(async (formData) => {
        let items = [];
        setInvoiceLoader(true);
        treevalues.map((id) => {
          let val = treeDataObj[id];
          if (val.type == "room") {
            items.push({
              itemId: val.value,
              title: val.title,
              value: val.calculatedPriceWithoutTax,
              item: val.item,
              room_type_name: val.room_type.name,
              tax_group: val.room_type.tax_group,
              rate_plan: val.rate_plan.rate_name,
              calCulatedTaxPrice: val.calCulatedTaxPrice,
              room_name: val.room_name,
              guest: val.guest,
              type: val.type,
              reservationInDateAndTime: val.reservationInDateAndTime,
              reservationOutDateAndTime: val.reservationOutDateAndTime,
            });
          } else if (
            val.type == "addtional" ||
            val.type == "posease-restaurant"
          ) {
            items.push({
              ...val,
              itemId: val.value,
              title: val.title,
              value: val.calculatedPriceWithoutTax,
              type: val.type,
            });
          }
        });
        let gest = JSON.parse(formData.guest);
        let payload = {
          type: "add",
          reservationId: reservationData?._id,
          invoiceDetails: {
            createAt: new Date(),
            customerId: gest.customerId,
            items: items,
            notes: formData.notes,
            roomInfoId: gest._id,
            prefix: currentRegisterData.receipt_number_prefix,
            payment: {
              total: invoiceCalCulatedValue.total,
              totalTax: invoiceCalCulatedValue.tax,
            },
          },
        };

        const getallRoomTable = await dispatch(addOrDeleteInvoice(payload));
        console.log("asaasasadadadaadadadadasadadadadadadadada", getallRoomTable)
        if (getallRoomTable && getallRoomTable.error == false) {
          setReservationData(getallRoomTable.data);
          setInvoiceLoader(false);
          setInvoiceModal(false);
          getInvoice();
          let items = [];

          treevalues.map((id) => {
            let val = treeDataObj[id];

            if (val.type == "room") {
              items.push({
                itemId: val.value,
                title: val.title,
                value: val.calculatedPriceWithoutTax,
                item: val.item,
                room_type_name: val.room_type.name,
                tax_group: val.room_type.tax_group,
                rate_plan: val.rate_plan.rate_name,
                calCulatedTaxPrice: val.calCulatedTaxPrice,
                room_name: val.room_name,
                guest: val.guest,
                type: val.type,
                reservationInDateAndTime: val.reservationInDateAndTime,
                reservationOutDateAndTime: val.reservationOutDateAndTime,
              });
            } else if (
              val.type == "addtional" ||
              val.type == "posease-restaurant"
            ) {
              items.push({
                ...val,
                itemId: val.value,
                title: val.title,
                value: Number(val.calculatedPriceWithoutTax),
                type: val.type,
              });
            }
          });
          let gest = JSON.parse(formData.guest);
          let customer = reservationData.roomInfo.find(
            (val) => val.customerInfo._id == gest.customerId
          )?.customerInfo;
          let {
            reservationBookTime,
            reservationInDateAndTime,
            reservationStringId,
            reservationOutDateAndTime,
          } = reservationData;
          let payload = {
            reservationData: {
              reservationBookTime,
              reservationInDateAndTime,
              reservationStringId,
              reservationOutDateAndTime,
              numberOfNights,
            },
            payment: {
              total: invoiceCalCulatedValue.total,
              totalTax: invoiceCalCulatedValue.tax,
              grandTotal: reservationData.payment?.grandTotal,
            },
            items: items,
            customer: customer,
            invoiceSringId: getallRoomTable.data?.invoiceSringId
          };

          PrintReceiptFunction("invoice", payload);
        }
      });
    }
  };

  const [allRoomTableLoader, setallRoomTableLoader] = useState({
    type: "",
    status: false,
  });

  const handleRoomCheckInCheckout = async (status, selectRoomData) => {
    setallRoomTableLoader({
      type: status,
      status: true,
    });

    if (detailsLock == false) {
      await handleRoomUpdate();
    }
    let roomIds = [];
    roomIds.push({
      _id: selectRoomData._id,
      roomId: selectRoomData.roomId,
      room_name: selectRoomData.room_name,
    });

    let formData = {
      roomIds: roomIds,
      status: status,
      roomDetails: roomDetails,
    };

    const getallRoomTable = await dispatch(
      reservationCheckInCheckOut(formData, reservationData?._id)
    );

    if (getallRoomTable && getallRoomTable.error == false) {
      let findRoom = getallRoomTable.data.roomInfo.find(
        (x) => x._id == roomDetails._id
      );

      if (
        currentRegisterData?.channel_key != "" &&
        currentRegisterData?.connect_channel &&
        (status == "checkOut" || status == "undoCheckOut")
      ) {
        await updateOTAAvailability([findRoom]);
      }
      setallRoomTableLoader({
        type: "",
        status: false,
      });
      message.success({
        content: getallRoomTable.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });

      let availableList = roomDetails.avialbleRoomList.map((l) => {
        if (findRoom && findRoom.roomId == l._id) {
          l.status = findRoom?.isCheckedOut
            ? "checkout"
            : findRoom?.isCheckedIn
              ? "checkin"
              : findRoom?.isAssignIn
                ? "assign"
                : "available";
          return l;
        } else {
          return l;
        }
      });
      if (
        moment()
          .startOf("day")
          .set({ hour: 12, minute: 30 }) <
        moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
      ) {
        findRoom["checkUpcoming"] = true;
      } else {
        findRoom["checkUpcoming"] = false;
      }
      setRoomDetails({
        ...findRoom,
        avialbleRoomList: availableList,
      });
      editFromData({
        ...findRoom,
        avialbleRoomList: availableList,
      });
      setReservationData(getallRoomTable.data);
      setRoomList([...getallRoomTable.data.roomInfo]);
      setDetailsLock(true);
    } else {
      message.error({
        content: getallRoomTable.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };

  const [loaderCharge, setChargeLoader] = useState(false);
  const [generalLoader, setGenralLoader] = useState(false);
  const [openALlCheckOutModal, setOpenAllCheckoutModal] = useState(false);
  const [allcheckoutLoader, setAllCheckOutLoader] = useState(false);

  const handleAllCheckOutAndComplate = async () => {
    if (allcheckoutLoader == false) {
      setChargeLoader(true);
      let formData = {};
      formData["isReceipt"] = true;
      formData["reservationId"] = reservationData?._id;
      formData["isAllCheckout"] = true;
      for (let val of reservationData.roomInfo) {
        if (!val.isCheckedOut) {
          let roomIds = [];
          roomIds.push({
            _id: val._id,
            roomId: val.roomId,
            room_name: val.room_name,
          });
          let formData1 = {
            roomIds: roomIds,
            status: "checkOut",
          };
          const getallRoomTable = await dispatch(
            reservationCheckInCheckOut(formData1, reservationData?._id)
          );
          if (getallRoomTable) {
            if (
              getallRoomTable.data &&
              currentRegisterData?.channel_key != "" &&
              currentRegisterData?.connect_channel
            ) {
              await updateOTAAvailability([val]);
            }
          }
        }
      }

      const getInvoice = await dispatch(createAllRoomInvoice(formData));
      if (getInvoice && getInvoice.error == false) {
        setChargeLoader(false);
        tabChangeToCurrentFunction("ORDER");
      }
    }
  };

  const handleAllRoomInvoice = async (check, customer) => {
    let formData = {};
    if (check == "complate" && pendingPrice > 0) {
      let findIndex = roomList.findIndex((val) => val.item == "pending");
      if (findIndex == -1) {
        createTransacationForm();
        return true;
      }
    }
    let withoutCancelRoom = 0;
    let checkOutRoom = 0;
    reservationData.roomInfo.map((val) => {
      if (!val.isCancelled) {
        withoutCancelRoom = withoutCancelRoom + 1;
      }
      if (val.isCheckedOut) {
        checkOutRoom = checkOutRoom + 1;
      }
    });
    if (check == "complate" && checkOutRoom != withoutCancelRoom) {
      handleAllCheckOutAndComplate();
      return true;
    }
    if (check == "update") {
      setGenralLoader(true);
    } else if (check == "complate") {
      setChargeLoader(true);
    } else if (check == "customer") {
      setLoader(true);
    }

    formData["reservationId"] = reservationData?._id;
    if (check === "complate") {
      formData["isReceipt"] = true;
    } else if (check == "update") {
      formData["general"] = {
        source: source,
        channel: channel,
      };
    } else if (check == "customer") {
      formData["customer"] = customer;
    }

    const getInvoice = await dispatch(createAllRoomInvoice(formData));

    if (getInvoice && getInvoice.error == false) {
      if (check == "update") {
        setReservationData(getInvoice.data);
        setGenralLoader(false);
      } else if (check == "complate") {
        setChargeLoader(false);
        tabChangeToCurrentFunction("ORDER");
      } else if (check == "customer") {
        setReservationData(getInvoice.data);
        setLoader(false);
        setCustomerModalVisible({ visible: false, type: "room" });
      }
      message.success({
        content: getInvoice.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    } else {
      setGenralLoader(false);
      setLoader(false);
      message.error({
        content: getInvoice.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };

  const onSelectRoom = (id, index, avialbleList) => {
    let value = avialbleList.find((val) => val._id == id);
    if (value) {
      roomList[index]["roomId"] = value._id;
      roomList[index]["room_name"] = value.room_name;
      roomList[index]["avialbleRoomList"] = avialbleList;
      editFromData(roomList[index]);
      setRoomDetails({ ...roomList[index] });
      setRoomList([...roomList]);
    }
  };

  const cols = [
    {
      title: "Room",
      dataIndex: "room_name",
      key: "room_name",
      fixed: "left",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {value.paid_room
                ? value.paid_room
                : `${value?.room_type?.name} ${text}`}
            </div>
          ),
        };
      },
    },
    {
      title: "Status",
      dataIndex: "isAssignIn",
      key: "isAssignIn",
      render(text, value) {
        if (value) {
          return {
            children: (
              <div
                style={value?.cancel ? { textDecoration: "line-through" } : {}}
              >
                {value.type == "payment"
                  ? value.item == "pending"
                    ? "Unpaid"
                    : "Paid"
                  : value.type == "item" || value.type == "addtional-item"
                    ? value.poseaseBill
                      ? "Room Services"
                      : "Extra Services"
                    : value.isCheckedOut
                      ? "Checkout"
                      : value.isCheckedIn
                        ? "CheckIn"
                        : value.isAssignIn
                          ? "Assign"
                          : value?.cancel
                            ? "Cancel"
                            : "Not assign"}
                <p
                  style={
                    value?.cancel
                      ? { textDecoration: "line-through", fontSize: "12px" }
                      : {
                        fontSize: "12px",
                      }
                  }
                >
                  {commonFunction.convertToDate(
                    value.addTime,
                    "MMM DD, Y, h:mm A"
                  )}
                </p>
              </div>
            ),
          };
        }
      },
    },
    {
      title: "Customer",
      dataIndex: "customerInfo",
      key: "customerInfo",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {text.name}
            </div>
          ),
        };
      },
    },
    {
      title: "Item",
      dataIndex: "item",
      key: "item",
      align: "left",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {text == "cash"
                ? "Cash"
                : text == "card"
                  ? "Card"
                  : text == "other"
                    ? "Other"
                    : text == "pending"
                      ? "Pending"
                      : text}

              {value?.notes && value.notes != "" && (
                <Tooltip
                  title={
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                      }}
                    >
                      {value?.notes}
                    </p>
                  }
                >
                  <ExclamationCircleOutlined
                    style={{
                      cursor: "pointer",
                      marginLeft: "6px",
                      fontSize: "14px",
                    }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "Price",
      dataIndex: "calculatedPriceWithoutTax",
      key: "calculatedPriceWithoutTax",
      align: "left",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {rsSymbol}
              {Number(
                value.type == "room" ? value.calculatedPriceWithTax : text
              ).toFixed(2)}
            </div>
          ),
        };
      },
    },
    {
      title: "",
      align: "left",
      render(record) {
        if (record?.type == "payment" && record.transId) {
          return {
            children: (
              <div>
                {!record.cancel && (
                  <DeleteOutlined
                    onClick={() => {
                      setDeleteTransacation({
                        id: record.transId,
                        visible: true,
                        value: record.calculatedPriceWithoutTax,
                      });
                    }}
                  />
                )}
              </div>
            ),
          };
        } else if (record.poseaseBill) {
          return {
            children: (
              <div>
                <EyeOutlined
                  onClick={() => {
                    history.push(`receipts/${record.itemId}`);
                  }}
                />
              </div>
            ),
          };
        } else if (!record?.cancel) {
          return {
            children: (
              <EditOutlined
                onClick={() => {
                  if (record?.type == "addtional-item") {
                    let finditem = addtionalCharge?.find(
                      (val) => val._id == record?.itemId
                    );
                    if (finditem) {
                      setSelectAddtionalCharge(finditem);
                    }
                    setAddtionalUpdateId(record?.updateId);
                    addtionalForm.setFieldsValue({
                      guest: JSON.stringify({
                        _id: record?.roomInfoId,
                        customerId: record?.customerInfo._id,
                        roomName: record?.paid_room,
                      }),
                      item: finditem ? JSON.stringify(finditem) : undefined,
                      qty: record?.amount?.qty
                        ? record?.amount?.qty
                        : undefined,
                      amount: record?.amount?.value
                        ? record?.amount?.value
                        : undefined,
                    });
                    setAddtionalValue(
                      record?.amount?.value ? record?.amount?.value : undefined
                    );
                    setAddtionalQty(
                      record?.amount?.qty ? record?.amount?.qty : undefined
                    );
                    setTotalAddtionalValue({
                      total: record?.amount?.total ? record?.amount?.total : 0,
                      tax: record?.amount?.tax ? record?.amount?.tax : 0,
                    });
                    setAddtionalModal(true);
                  } else {
                    setCategory(record?._id);
                    setEditModalOpen(true);
                  }
                }}
              />
            ),
          };
        }
      },
    },
  ];

  let locale = {
    emptyText: <span>Add Room items</span>,
  };
  const rowClassName = () => {
    return "changeBackground";
  };
  console.log("roomListroomList", roomList);
  const allRoomTable = useMemo(() => {
    return (
      <>
        <Table
          locale={locale}
          rowClassName={rowClassName}
          rowKey="_id"
          className={"tbl_data handleScroll-normal reservation"}
          dataSource={roomList}
          columns={cols}
          size="small"
          scroll={windowWidth <= 600 ? { x: 600 } : {}}
          pagination={false}
          summary={() => {
            return (
              <>
                {roomList.length > 0 && (
                  <Table.Summary.Row className="changeBackground">
                    <>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ fontWeight: 700 }}>Total</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text
                          className="center-tax"
                          style={{ fontWeight: 700 }}
                        >
                          {`${rsSymbol}${Number(
                            reservationData?.payment?.grandTotal
                          ).toFixed(2)}`}
                        </Text>
                      </Table.Summary.Cell>
                    </>
                  </Table.Summary.Row>
                )}
                {roomList.length > 0 && (
                  <Table.Summary.Row className="changeBackground">
                    <>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text style={{ fontWeight: 700 }}>Received</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text
                          className="center-tax"
                          style={{ fontWeight: 700 }}
                        >
                          {`${rsSymbol}${Number(
                            reservationData?.payment?.grandTotal - pendingPrice
                          ).toFixed(2)}`}
                        </Text>
                      </Table.Summary.Cell>
                    </>
                  </Table.Summary.Row>
                )}
              </>
            );
          }}
        />
      </>
    );
  }, [roomList, loader]);
  function calculateTaxInclusive(totalAmount, taxRate) {
    taxRate = taxRate / 100;
    var originalAmount = totalAmount / (1 + taxRate);
    var taxAmount = totalAmount - originalAmount;
    return {
      originalAmount: originalAmount,
      taxAmount: taxAmount,
    };
  }
  const calCulatedTaxPriceofThisRoomType = (
    totalPrice,
    taxes,
    checkIsInclusive
  ) => {
    let totalTax = 0;
    let inclusiveTax = 0;
    let totalTaxPercent = 0;
    taxes?.map((val) => {
      if (checkIsInclusive != "remove") {
        totalTaxPercent += Number(val.tax_percentage);
      }
    });
    if (checkIsInclusive == true) {
      totalTax = calculateTaxInclusive(totalPrice, totalTaxPercent).taxAmount;
      inclusiveTax = totalTax;
    } else if (checkIsInclusive == false) {
      totalTax = (totalPrice * totalTaxPercent) / 100;
    }
    inclusiveTax = parseFloat(inclusiveTax.toFixed(2));
    totalTax = parseFloat(totalTax.toFixed(2));

    return { totalTax, inclusiveTax };
  };

  const calculatedPriceOfThisRoomType = (
    rate_plan,
    roomDetails,
    startDate,
    endDate
  ) => {
    let totalPrice = 0;
    let totalTaxPrice = 0;
    let totalGuest = 0;
    let numberOfNights = moment(roomReservationEndDate).diff(
      moment(roomReservarionStartDate),
      "days"
    );
    if (startDate) {
      numberOfNights = moment(endDate).diff(moment(startDate), "days");
    }

    // adult calculated
    if (roomDetails.room_type.default_adult >= roomDetails.guest.adults) {
      let default_adult_rate = rate_plan.default_adult_rate;
      let checkadultsPrice = rate_plan?.adult_rates.find(
        (g, i) => i > 0 && g.numberOfAdult == roomDetails.guest.adults
      );
      if (checkadultsPrice) {
        default_adult_rate = checkadultsPrice.rate;
      }
      totalPrice = numberOfNights * (totalPrice + Number(default_adult_rate));
    } else {
      let checkExtraAdult =
        roomDetails.guest.adults - roomDetails.room_type.default_adult;
      totalPrice =
        numberOfNights * (totalPrice + Number(rate_plan.default_adult_rate));
      let extraPriceOfAdult =
        numberOfNights * (checkExtraAdult * Number(rate_plan.extra_adult_rate));
      totalPrice = totalPrice + extraPriceOfAdult;
    }

    // Extra children calculated
    if (roomDetails.guest.children > roomDetails.room_type.default_children) {
      {
        let checkExtraChildren =
          roomDetails.guest.children - roomDetails.room_type.default_children;

        let extraPriceOfChildren =
          numberOfNights *
          (checkExtraChildren * Number(rate_plan.extra_child_rate));
        totalPrice = totalPrice + extraPriceOfChildren;
      }
    }

    // Extra infants calculated
    if (roomDetails.guest.infants > roomDetails.room_type.default_infant) {
      {
        let checkExtraInfants =
          roomDetails.guest.infants - roomDetails.room_type.default_infant;

        let extraPriceOfInfants =
          numberOfNights *
          (checkExtraInfants * Number(rate_plan.extra_infant_rate));
        totalPrice = totalPrice + extraPriceOfInfants;
      }
    }
    totalGuest =
      roomDetails.guest.adults +
      roomDetails.guest.children +
      roomDetails.guest.infants;

    let { totalTax, inclusiveTax } = calCulatedTaxPriceofThisRoomType(
      totalPrice,
      roomDetails.room_type.tax_group.taxes,
      roomDetails.room_type.tax_group.taxes_inclusive_in_product_price
    );
    roomDetails["calculatedPriceWithoutTax"] = totalPrice - inclusiveTax;
    totalTaxPrice = totalTax;
    roomDetails["calCulatedTaxPrice"] = totalTaxPrice;
    totalPrice = totalPrice + totalTaxPrice - inclusiveTax;
    roomDetails["calCulatedInclusiveTaxPrice"] = inclusiveTax;
    roomDetails["calculatedPriceWithTax"] = totalPrice;
    roomDetails["total_guest"] = totalGuest;
    roomDetails["rate_plan"] = rate_plan;

    if (
      roomDetails &&
      roomDetails.room_type &&
      roomDetails.room_type.rate_plan_list
    ) {
      let findIndex = roomDetails.room_type.rate_plan_list.findIndex(
        (v) => v.rate_name == rate_plan.rate_name
      );

      if (findIndex != -1) {
        roomDetails.room_type.rate_plan_list[findIndex] = rate_plan;
      }
    }
    roomList[seleroomIndex] = roomDetails;

    setRoomDetails({ ...roomDetails });
    editFromData({ ...roomDetails }, startDate, endDate);
    setRoomList([...roomList]);
    return roomDetails;
  };

  // edit price rate modal
  const submitAddFolio = async (val) => {
    setallRoomTableLoader({
      type: "invoice",
      status: true,
    });
    let room = JSON.parse(val.guest);
    let transObj = {
      roomName: room.roomName,
      customerId: room.customerId,
      notes: val.notes,
      type: PaymentType,
      amount: Number(val.amount),
      paidTime: new Date(),
      folio_type: val.folio_type,
      paidOutStaffUserId: userDetails.restaurant_user_id,
    };
    let roomIds = [];
    roomIds.push({
      _id: room._id,
      room_name: val.room_name,
    });
    let formData1 = {
      roomIds: roomIds,
      status: "payment",
      transationObj: transObj,
    };

    let getallRoomTable = await dispatch(
      reservationCheckInCheckOut(formData1, reservationData?._id)
    );
    if (getallRoomTable && getallRoomTable.error == false) {
      if (roomDetails) {
        let findRoom = getallRoomTable.data.roomInfo.find(
          (x) => x._id == roomDetails._id
        );
        if (
          moment()
            .startOf("day")
            .set({ hour: 12, minute: 30 }) <
          moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
        ) {
          findRoom["checkUpcoming"] = true;
        } else {
          findRoom["checkUpcoming"] = false;
        }
        setRoomDetails({
          ...findRoom,
          avialbleRoomList: roomDetails.avialbleRoomList,
        });
        editFromData({
          ...findRoom,
          avialbleRoomList: roomDetails.avialbleRoomList,
        });
      }

      setallRoomTableLoader({
        type: "",
        status: false,
      });
      message.success({
        content: getallRoomTable.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });

      setReservationData(getallRoomTable.data);
      setRoomList([...getallRoomTable.data.roomInfo]);

      setAddFolio(false);
      folioForm.resetFields();
    } else {
      message.error({
        content: getallRoomTable.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };

  const submitAddtional = async (type) => {
    addtionalForm.validateFields().then(async (val) => {
      setallRoomTableLoader({
        type: "addtional",
        status: true,
      });
      let room = JSON.parse(val.guest);
      let item = JSON.parse(val.item);

      let findRoomInfo = roomList.find((val) => val._id == room._id);

      let itemObj = {
        itemId: item?._id,
        itemName: item?.charge_name,
        roomName: room.roomName,
        customerId: room.customerId,
        notes: val.notes,
        tax_group: item.tax_group,
        room_name: findRoomInfo?.room_name,
        rate_plan: findRoomInfo?.rate_plan?.rate_name,
        room_type: findRoomInfo?.room_type?.name,
        amount: {
          qty: val.qty,
          value: val.amount,
          total: totalAddtionalValue?.total,
          tax: totalAddtionalValue?.tax,
        },
        addTime: new Date(),
        userName: userDetails?.username,
      };
      let roomIds = [];
      roomIds.push({
        _id: room._id,
        room_name: val.room_name,
      });
      let formData1 = {
        roomIds: roomIds,
        status: "item",
        itemObj: itemObj,
        type: type,
      };
      if (addtionalUpdateId) {
        formData1["addtionalUpdateId"] = addtionalUpdateId;
      }

      let getallRoomTable = await dispatch(
        reservationCheckInCheckOut(formData1, reservationData?._id)
      );
      if (getallRoomTable && getallRoomTable.error == false) {
        if (roomDetails) {
          let findRoom = getallRoomTable.data.roomInfo.find(
            (x) => x._id == roomDetails._id
          );
          if (
            moment()
              .startOf("day")
              .set({ hour: 12, minute: 30 }) <
            moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
          ) {
            findRoom["checkUpcoming"] = true;
          } else {
            findRoom["checkUpcoming"] = false;
          }
          setRoomDetails({
            ...findRoom,
            avialbleRoomList: roomDetails.avialbleRoomList,
          });
          editFromData({
            ...findRoom,
            avialbleRoomList: roomDetails.avialbleRoomList,
          });
        }

        setallRoomTableLoader({
          type: "",
          status: false,
        });
        message.success({
          content: getallRoomTable.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });

        setReservationData(getallRoomTable.data);
        setRoomList([...getallRoomTable.data.roomInfo]);

        setAddtionalModal(false);
        setAddtionalUpdateId();
        addtionalForm.resetFields();
      } else {
        message.error({
          content: getallRoomTable.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
    });
  };

  const PrintReceiptFunction = (check, receiptsDetails) => {
    window.frames[
      "print_frame"
    ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
      check == "confirm" ? (
        <ConformationPrint
          reservationData={reservationData}
          currentRegisterData={currentRegisterData}
          pendingPrice={pendingPrice}
          roomList={roomList}
          numberOfNights={numberOfNights}
        />
      ) : check == "invoice" ? (
        <HotelInvoice
          rsSymbol={rsSymbol}
          shopDetails={getItem("setupCache")?.shopDetails}
          receiptsDetails={receiptsDetails}
          registerData={currentRegisterData}
          pendingPrice={pendingPrice}
          invoiceNo={receiptsDetails?.invoiceSringId}
        />
      ) : (
        <CustomerPrint
          roomDetails={roomDetails}
          currentRegisterData={currentRegisterData}
        />
      )
    );
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
  };
  const [emailVisible, setEmailVisible] = useState(false);
  const emailDataSet = () => {
    setEmailVisible(true);
    mailForm.setFieldsValue({
      email: reservationData?.customerId?.email
        ? reservationData?.customerId?.email
        : undefined,
    });
  };
  const handleRepetInvoicePrint = (RecepitsData) => {
    let {
      reservationBookTime,
      reservationInDateAndTime,
      reservationStringId,
      reservationOutDateAndTime,
      numberOfNights,
    } = reservationData;
    let { payment, items, invoiceSringId } = RecepitsData;
    let customer = RecepitsData.customerId;
    let receiptsDetails = {
      reservationData: {
        reservationBookTime,
        reservationInDateAndTime,
        reservationStringId,
        reservationOutDateAndTime,
        numberOfNights,
      },
      payment: {
        total: payment.total,
        totalTax: payment.totalTax,
        grandTotal: reservationData.payment?.grandTotal,
      },
      items: items,
      customer: customer,
    };
    window.frames[
      "print_frame"
    ].document.body.innerHTML = ReactDOMServer.renderToStaticMarkup(
      <HotelInvoice
        shopDetails={getItem("setupCache")?.shopDetails}
        receiptsDetails={receiptsDetails}
        registerData={currentRegisterData}
        invoiceNo={invoiceSringId}
        rsSymbol={rsSymbol}
        pendingPrice={pendingPrice}
        reservationData={reservationData}
      />
    );
    window.frames["print_frame"].window.focus();
    window.frames["print_frame"].window.print();
  };

  const [PaymentType, setPaymentType] = useState();
  const [detailsLock, setDetailsLock] = useState(true);

  // let checkAllOut =
  //   roomList.filter((val) => val.paid_room == undefined).length ==
  //   roomList.filter((val) => val.paid_room == undefined && val.isCheckedOut)
  //     .length
  //     ? false
  //     : true;

  const updateGuest = (rate_plan, roomDetails, guestType, checkPlusMinus) => {
    if (detailsLock == false) {
      if (checkPlusMinus == "plus") {
        let updateNumber =
          1 + roomDetails.guest[guestType];
        if (
          guestType == "adults" &&
          updateNumber >
          roomDetails?.room_type?.max_adult
        ) {
          message.error("Guest limit exceeded.");
          return true;
        }
        if (
          guestType == "children" &&
          updateNumber >
          roomDetails?.room_type?.max_infant
        ) {
          message.error("Guest limit exceeded.");
          return true;
        }
        if (
          guestType == "infants" &&
          updateNumber >
          roomDetails?.room_type?.max_children
        ) {
          message.error("Guest limit exceeded.");
          return true;
        }
        roomDetails.guest[guestType] = ++roomDetails.guest[guestType];
        calculatedPriceOfThisRoomType(rate_plan, roomDetails);
        return roomDetails;
      } else {
        if (roomDetails.guest[guestType] > 0) {
          roomDetails.guest[guestType] = --roomDetails.guest[guestType];
          calculatedPriceOfThisRoomType(rate_plan, roomDetails);
          return roomDetails;
        } else {
          calculatedPriceOfThisRoomType(rate_plan, roomDetails);
          return roomDetails;
        }
      }
    }
  };

  const handleEditRateplan = async (val, roomDetails, startDate, endDate1) => {
    let ratePlan = JSON.parse(val);
    let default_adult_rate = 0;
    let extra_adult_rate = 0;
    let extra_child_rate = 0;
    let extra_infant_rate = 0;

    const currentDate = new Date(
      startDate ? startDate : roomDetails.reservationInDateAndTime
    );
    const endDate = new Date(
      endDate1 ? endDate1 : roomDetails.reservationOutDateAndTime
    );
    let numberOfNights = moment(
      endDate1 ? endDate1 : roomDetails.reservationOutDateAndTime
    ).diff(
      moment(startDate ? startDate : roomDetails.reservationInDateAndTime),
      "days"
    );
    let rateDateWiseObj = getItem("rate_app_enable")
      ? await fetchDateWiseRate(currentDate, endDate)
      : {};


    ratePlan?.adult_rates?.map((j, i) => {
      let myRate = 0;
      if (i) {
        const currentDate1 = new Date(currentDate);
        const endDate2 = new Date(endDate);
        while (currentDate1 < endDate2) {
          let thatDay = currentDate1.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });

          if (
            rateDateWiseObj &&
            ratePlan &&
            ratePlan.rate_id &&
            rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
          ) {
            let latestObj =
              rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
            if (latestObj[`default_adult${j.numberOfAdult}`]) {
              myRate += Number(latestObj[`default_adult${j.numberOfAdult}`]);
            } else {
              myRate += Number(j.rate);
            }
          } else {
            myRate += Number(j.rate);
          }

          currentDate1.setDate(currentDate1.getDate() + 1);
        }
        j.rate = (myRate / numberOfNights).toString();
      }
      return j;
    });
    while (currentDate < endDate) {
      let thatDay = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      if (
        rateDateWiseObj &&
        ratePlan &&
        ratePlan.rate_id &&
        rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
      ) {
        let latestObj =
          rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
        default_adult_rate += Number(
          latestObj.default_adult_rate != undefined
            ? latestObj.default_adult_rate
            : ratePlan?.default_adult_rate
        );
        extra_adult_rate += Number(
          latestObj.extra_adult_rate != undefined
            ? latestObj.extra_adult_rate
            : ratePlan.extra_adult_rate
        );
        extra_child_rate += Number(
          latestObj.extra_child_rate != undefined
            ? latestObj.extra_child_rate
            : ratePlan?.extra_child_rate
        );
        extra_infant_rate += Number(
          latestObj.extra_infant_rate != undefined
            ? latestObj.extra_infant_rate
            : ratePlan?.extra_infant_rate
        );
      } else {
        default_adult_rate += Number(ratePlan.default_adult_rate);
        extra_adult_rate += Number(ratePlan.extra_adult_rate);
        extra_child_rate += Number(ratePlan.extra_child_rate);
        extra_infant_rate += Number(ratePlan.extra_infant_rate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    ratePlan = {
      ...ratePlan,
      default_adult_rate: (default_adult_rate / numberOfNights).toString(),
      extra_adult_rate: (extra_adult_rate / numberOfNights).toString(),
      extra_child_rate: (extra_child_rate / numberOfNights).toString(),
      extra_infant_rate: (extra_infant_rate / numberOfNights).toString(),
    };
    calculatedPriceOfThisRoomType(ratePlan, roomDetails, startDate, endDate1);
  };
  const [reservationDisable, setReservationDisable] = useState(false);
  const checkRoomAvailble = async (
    reservationStartDate,
    reservationEndDate,
    room,
    roomTypeData,
    roomInfoId
  ) => {
    setReservationDisable(true);
    let startDate = moment(reservationStartDate).format("YYYY-MM-DD");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let totalRoom = 0;

    if (roomTypeData?.numberOfRooms == undefined) {
      let findRoomType = roomTypeList.find(
        (val) => val._id == roomTypeData._id
      );
      totalRoom = findRoomType.numberOfRooms;
    } else if (typeof roomTypeData?.numberOfRooms == "number") {
      totalRoom = roomTypeData?.numberOfRooms;
    } else {
      totalRoom = roomTypeData?.numberOfRooms?.length;
    }

    let response = await dispatch(
      checkRoomAvailblity({
        reservationStartDate: startDate,
        reservationEndDate: endDate,
        roomTypeId: roomTypeData?._id,
        needRoom: room,
        totalRoom: totalRoom,
        roomInfoId: roomInfoId,
      })
    );

    if (response && response.data) {
      if (response.data.avilableStatus == false) {
        setOverBookingModal(true);
        setReservationDisable(true);
        return true;
      } else {
        setReservationDisable(false);
        return false;
      }
    }
  };

  const updateOTAAvailability = async (roomInfoList) => {
    let roomTypeObj = {};
    console.log("roomInfoListroomInfoList", roomInfoList);
    roomInfoList?.map((g) => {
      if (
        !roomTypeObj[
        `${g?.room_type._id}/${g.reservationInDateAndTime}/${g.reservationOutDateAndTime}`
        ] &&
        g.room_type.channel_room_code != ""
      ) {
        roomTypeObj[g.room_type._id] = {
          room_type_id: g.room_type._id,
          channel_room_code: g.room_type.channel_room_code,
          totalRooms: g.room_type.numberOfRooms?.length,
          reservationInDateAndTime: g.reservationInDateAndTime,
          reservationOutDateAndTime: g.reservationOutDateAndTime,
          rate_plan: g.room_type.rate_plan_list,
        };
      }
    });
    console.log("roomTypeObjroomTypeObj", roomTypeObj);
    let checkRoomTypeList = Object.values(roomTypeObj);
    if (checkRoomTypeList && checkRoomTypeList?.length) {
      for (let oneRoomType of checkRoomTypeList) {
        let dateObj = await fetchDateWiseRate(
          oneRoomType?.reservationInDateAndTime,
          oneRoomType?.reservationOutDateAndTime
        );
        let startDate1 = moment(oneRoomType?.reservationInDateAndTime);
        let endDate1 = moment(oneRoomType?.reservationOutDateAndTime);

        while (moment(startDate1).isBefore(endDate1)) {
          let rate_id = oneRoomType?.rate_plan
            ? oneRoomType?.rate_plan[0]?.rate_id
            : oneRoomType?.rate_plan_list[0]?.rate_id;
          if (
            dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`]
          ) {
            let thisObj =
              dateObj[`${moment(startDate1).format("MM/DD/YYYY")}-${rate_id}`];
            let sellQty = Number(
              thisObj?.value?.ota_sell && thisObj?.value?.ota_sell != ""
                ? thisObj?.value?.ota_sell
                : oneRoomType?.totalRooms
            );
            let startDate = moment(startDate1).format("YYYY-MM-DD");

            let response = await dispatch(
              checkRoomAvailblity({
                reservationStartDate: startDate,
                reservationEndDate: startDate,
                roomTypeId: oneRoomType?.room_type_id,
                needRoom: 0,
                totalRoom: sellQty,
              })
            );

            if (response.data) {
              let available = response.data.avilableRoom;
              let channelRatePayload = {
                RoomWiseInventory: [
                  {
                    DateFrom: moment(startDate1).format("DD-MMM-YYYY"),
                    DateTill: moment(startDate1).format("DD-MMM-YYYY"),
                    RoomCode: oneRoomType?.channel_room_code,
                    Availability: Number(available),
                  },
                ],
              };
              let channelResponse = await dispatch(
                updateChannelAvailability(
                  currentRegisterData?.channel_key,
                  channelRatePayload
                )
              );
              console.log("channelResponse", channelResponse);
            }
          } else {
            let sellQty = Number(oneRoomType?.totalRooms);
            let startDate = moment(startDate1).format("YYYY-MM-DD");

            let response = await dispatch(
              checkRoomAvailblity({
                reservationStartDate: startDate,
                reservationEndDate: startDate,
                roomTypeId: oneRoomType?.room_type_id,
                needRoom: 0,
                totalRoom: sellQty,
              })
            );

            if (response.data) {
              let available = response.data.avilableRoom;
              let channelRatePayload = {
                RoomWiseInventory: [
                  {
                    DateFrom: moment(startDate1).format("DD-MMM-YYYY"),
                    DateTill: moment(startDate1).format("DD-MMM-YYYY"),
                    RoomCode: oneRoomType?.channel_room_code,
                    Availability: Number(available),
                  },
                ],
              };
              let channelResponse = await dispatch(
                updateChannelAvailability(
                  currentRegisterData?.channel_key,
                  channelRatePayload
                )
              );
              console.log("channelResponse", channelResponse);
            }
          }
          startDate1 = moment(startDate1).add(1, "days");
        }
      }
    }
  };
  const handleRoomUpdate = async (latestRoomDetails, transactionCancel) => {
    if (loader == false) {
      setLoader(true);
      let payment = {
        ...reservationData.payment,
      };

      let body = {
        reservationId: reservationData._id,
        roomDetails: latestRoomDetails ? latestRoomDetails : roomDetails,
        payment: payment,
        reservationUpdateTime: new Date(),
        reservationUpdateStaffUserId: userDetails.restaurant_user_id,
      };

      if (transactionCancel) {
        body["transactionCancel"] = transactionCancel;
      }
      if (body.roomDetails) {
        let startObject = new Date(roomReservarionStartDate);
        startObject.setHours(12, 30, 0, 0);
        let endObject = new Date(roomReservationEndDate);
        endObject.setHours(12, 30, 0, 0);
        body.roomDetails["reservationInDateAndTime"] = startObject;
        body.roomDetails["reservationOutDateAndTime"] = endObject;
      }
      let response = await dispatch(roomIdByUpdate(body));
      console.log("bodyroomDetails", body?.roomDetails);
      if (response && response.data) {
        if (
          currentRegisterData?.channel_key != "" &&
          currentRegisterData?.connect_channel &&
          body?.roomDetails?.isCancelled
        ) {
          let updatedRoomInfo = [];
          if (body?.roomDetails?.type == "all") {
            updatedRoomInfo = response.data?.roomInfo;
          } else {
            updatedRoomInfo = response.data?.roomInfo.filter(
              (w) => w._id == roomDetails?._id
            );
          }
          await updateOTAAvailability(updatedRoomInfo);
        } else if (
          !latestRoomDetails &&
          currentRegisterData?.channel_key != "" &&
          currentRegisterData?.connect_channel
        ) {
          let oldReservationData = reservationData;
          let newReservationData = response.data;
          let findOldRoomInfo = oldReservationData.roomInfo.find(
            (l) => l._id == roomDetails._id
          );
          let findNewRoomInfo = newReservationData.roomInfo.find(
            (l) => l._id == roomDetails._id
          );
          if (
            findOldRoomInfo.reservationInDateAndTime !=
            findNewRoomInfo.reservationInDateAndTime ||
            findOldRoomInfo.reservationOutDateAndTime !=
            findNewRoomInfo.reservationOutDateAndTime
          ) {
            let lowestReservationStartDate =
              new Date(findOldRoomInfo.reservationInDateAndTime) >
                new Date(findNewRoomInfo.reservationInDateAndTime)
                ? findNewRoomInfo.reservationInDateAndTime
                : findOldRoomInfo.reservationInDateAndTime;
            let highestReservationEndDate =
              new Date(findOldRoomInfo.reservationOutDateAndTime) >
                new Date(findNewRoomInfo.reservationOutDateAndTime)
                ? findOldRoomInfo.reservationOutDateAndTime
                : findNewRoomInfo.reservationOutDateAndTime;

            await updateOTAAvailability([
              {
                ...findNewRoomInfo,
                reservationInDateAndTime: lowestReservationStartDate,
                reservationOutDateAndTime: highestReservationEndDate,
              },
            ]);
          }
        }
        setReservationData({
          ...response.data,
        });
        if (roomDetails) {
          let findRoom = response.data.roomInfo.find(
            (x) => x._id == roomDetails._id
          );
          if (
            moment()
              .startOf("day")
              .set({ hour: 12, minute: 30 }) <
            moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
          ) {
            findRoom["checkUpcoming"] = true;
          } else {
            findRoom["checkUpcoming"] = false;
          }
          setRoomDetails({
            ...findRoom,
            avialbleRoomList: roomDetails.avialbleRoomList,
          });
          editFromData({
            ...findRoom,
            avialbleRoomList: roomDetails.avialbleRoomList,
          });
        }

        setLoader(false);
        setEditRoomInfo(false);
        setSaveModal(false);
        setEditModalOpen(false);
        setDeleteTransacation({ id: "", visible: false, value: 0 });
        setCancelReservation({ visible: false, type: "room" });
        setDetailsLock(true);
      }
    }
  };

  const handleSingleCustomerAddUpdate = async (customerData) => {
    setLoader(true);
    let response = await dispatch(
      customerAddUpdate({
        reservationId: reservationData._id,
        roomDetails: { _id: roomDetails._id, customer: customerData },
      })
    );
    setLoader(false);

    if (response && response.data) {
      setReservationData({ ...response.data });
      let findRoom = response.data.roomInfo.find(
        (x) => x._id == roomDetails._id
      );
      if (
        moment()
          .startOf("day")
          .set({ hour: 12, minute: 30 }) <
        moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
      ) {
        findRoom["checkUpcoming"] = true;
      } else {
        findRoom["checkUpcoming"] = false;
      }
      setRoomDetails({
        ...findRoom,
        avialbleRoomList: roomDetails.avialbleRoomList,
      });
      editFromData({
        ...findRoom,
        avialbleRoomList: roomDetails.avialbleRoomList,
      });

      setLoader(false);
      setCustomerModalVisible({ visible: false, type: "room" });
    }
  };

  const onSelectRoomType = (room_type, index) => {
    roomList[index]["room_type"] = {
      ...room_type,
      rate_plan_list: room_type.rate_plan,
    };

    roomList[index]["avialbleRoomList"] = roomListData.filter(
      (j) => j.status == "available" && j.room_type._id == room_type._id
    );
    roomList[index]["roomId"] = "-";
    roomList[index]["room_name"] = "-";
    roomList[index]["rate_plan"] = "-";
    setRoomDetails({ ...roomList[index] });
    editFromData({ ...roomList[index] });
    setRoomList([...roomList]);
  };

  const editNotes = (value, check) => {
    value.check = check;
    setNotesModal(true);
    setEditNote(value);
    notesForm.setFieldsValue({ notes: value.text });
    if (value.important) {
      setImportantNotes(true);
    } else {
      setImportantNotes(false);
    }
  };

  const generateInvoce = () => {
    let assignRoom = [];
    let idValue = [];
    let assignRoomObj = {};
    let totalCalCulatedPrice = 0;
    let totalCalClutaedTax = 0;

    let generateInvoiceItemsObj = {};
    invoiceList.map((val) => {
      if (val.delete == false) {
        val.items.map((k) => {
          generateInvoiceItemsObj[`${k.itemId}`] = k;
        });
      }
    });
    reservationData?.roomInfo.map((val) => {
      if (val.isAssignIn) {
        let obj = {
          title: `${val.room_type.name} ${val.room_name
            } | Room Charges (${rsSymbol}${Number(
              val.calculatedPriceWithTax
            ).toFixed(2)})`,
          value: `${val._id}-${val.room_name}`,
          ...val,
          type: "room",
        };

        if (!generateInvoiceItemsObj[obj.value]) {
          totalCalCulatedPrice =
            totalCalCulatedPrice + Number(val.calculatedPriceWithTax);
          totalCalClutaedTax =
            totalCalClutaedTax + Number(val.calCulatedTaxPrice);
          assignRoom.push(obj);
          idValue.push(`${val._id}-${val.room_name}`);
          assignRoomObj[`${val._id}-${val.room_name}`] = obj;
        }
      }
      if (val.addtionalItem) {
        val.addtionalItem.map((q) => {
          let id = q._id;
          let title = `${val.room_type.name} ${val.room_name} | ${q.itemName
            } (${rsSymbol}${Number(q.amount?.total).toFixed(2)})`;
          if (q.type == "posease-restaurant") {
            title = `${val.room_type.name} ${val.room_name
              } | In Room Service | ${q.itemName} (${rsSymbol}${Number(
                q.amount?.total
              ).toFixed(2)})`;
          }

          let obj = {
            title: title,
            value: `${val._id}-${q.itemId}-${id}`,
            calculatedPriceWithoutTax: q.amount?.total,
            type: "addtional",
            ...q,
          };

          if (!generateInvoiceItemsObj[obj.value]) {
            totalCalCulatedPrice =
              totalCalCulatedPrice + Number(q.amount?.total);
            assignRoom.push(obj);
            idValue.push(`${val._id}-${q.itemId}-${id}`);
            assignRoomObj[`${val._id}-${q.itemId}-${id}`] = obj;
          }
        });
      }
    });

    setTreeDataObj(assignRoomObj);
    setTreeData(assignRoom);
    setTotalId(idValue);
    setTreeValues(idValue);
    setInvoiceCalCulatedValue({
      tax: totalCalClutaedTax,
      total: totalCalCulatedPrice,
    });
    reservationData.roomInfo = reservationData.roomInfo.filter(
      (val) => !val.isCancelled
    );
    invoiceForm.setFieldsValue({
      invoice_type: "All",
      guest: JSON.stringify({
        _id: reservationData.roomInfo[0]._id,
        customerId: reservationData.roomInfo[0].customerInfo._id,
        roomName: `${reservationData.roomInfo[0].room_type.name} ${reservationData.roomInfo[0].room_name}`,
      }),
    });
    setInvoiceType("All");
    setInvoiceModal(true);
  };

  const deleteInvoice = async () => {
    let payload = {
      type: "delete",
      reservationId: reservationData?._id,
      invoiceDetails: {
        _id: deleteInvoiceModal.id,
        deleteAt: new Date(),
      },
    };

    const getallRoomTable = await dispatch(addOrDeleteInvoice(payload));
    if (getallRoomTable && getallRoomTable.error == false) {
      setReservationData(getallRoomTable.data);
      setDeleteInvoiceModal({ visible: false, id: "" });
      getInvoice();
    }
  };

  const deleteTrancation = async (value) => {
    if (deleteTransacationModal?.id) {
      handleRoomUpdate(roomDetails, {
        transaction_id: deleteTransacationModal?.id,
        cancel_notes: value.cancel_notes,
        cancelTime: new Date(),
        cancelType: value.cancelType,
        value: deleteTransacationModal.value,
      });
    }
  };

  useEffect(() => getInvoice(), []);

  let createTotalInvoiceValue = (itemArray) => {
    let totalCalCulatedPrice = 0;
    let totalCalClutaedTax = 0;
    itemArray.map((id) => {
      let val = treeDataObj[id];
      if (val.type == "room") {
        totalCalCulatedPrice =
          totalCalCulatedPrice + Number(val.calculatedPriceWithTax);
        totalCalClutaedTax =
          totalCalClutaedTax + Number(val.calCulatedTaxPrice);
      } else {
        totalCalCulatedPrice =
          totalCalCulatedPrice + Number(val.calculatedPriceWithoutTax);
      }
    });
    return {
      total: totalCalCulatedPrice,
      tax: totalCalClutaedTax,
    };
  };

  const changeInvoiceType = (e) => {
    setInvoiceType(e.target.value);
    if (e.target.value == "All") {
      setTreeValues([...totalIds]);
      setInvoiceCalCulatedValue(createTotalInvoiceValue([...totalIds]));
    } else {
      let currentGuest = JSON.stringify({
        _id: reservationData.roomInfo[0]._id,
        customerId: reservationData.roomInfo[0].customerInfo._id,
        roomName: `${reservationData.roomInfo[0].room_type.name} ${reservationData.roomInfo[0].room_name}`,
      });
      let roomId = JSON.parse(currentGuest)?._id;
      if (roomId) {
        let filter = totalIds.filter(
          (p) => p.split("-") && p.split("-")[0] && p.split("-")[0] == roomId
        );
        setTreeValues([...filter]);
        setInvoiceCalCulatedValue(createTotalInvoiceValue([...filter]));
      }
    }
  };

  const changeGuestInvoice = (val) => {
    if (invoiceType == "Split") {
      let roomId = JSON.parse(val)?._id;
      if (roomId) {
        let filter = totalIds.filter(
          (p) => p.split("-") && p.split("-")[0] && p.split("-")[0] == roomId
        );
        setTreeValues([...filter]);
        setInvoiceCalCulatedValue(createTotalInvoiceValue([...filter]));
      }
    }
  };

  useEffect(() => {
    addtionalForm.setFieldsValue({
      amount: addtionalValue,
      qty: addtionalQty,
    });
    if (selectAddtionalCharge) {
      let totalTax = 0;
      selectAddtionalCharge.tax_group.taxes.map((j) => {
        totalTax = totalTax + j.tax_percentage;
      });
      let totalTaxPrice = (addtionalValue * addtionalQty * totalTax) / 100;
      let totalValue = addtionalValue * addtionalQty + totalTaxPrice;
      if (selectAddtionalCharge?.tax_group.taxes_inclusive_in_product_price) {
        totalValue = totalValue - totalTaxPrice;
      }

      setTotalAddtionalValue({
        tax: totalTaxPrice,
        total: totalValue,
      });
    }
  }, [addtionalValue, addtionalQty, selectAddtionalCharge]);

  // add addtional Room
  const handleAddAddtionalRoom = async (roomtypes) => {
    let totalRoomCharges = 0;
    let totalTax = 0;
    [...roomList, ...roomtypes].map((val) => {
      if (val.isCancelled == undefined) {
        totalRoomCharges = totalRoomCharges + val.calculatedPriceWithTax;
        totalTax = totalTax + val.calCulatedTaxPrice;
      }
    });
    let payment = {
      ...reservationData.payment,
      grandTotal: totalRoomCharges,
      roomCharges: totalRoomCharges,
      roomChargesTax: totalTax,
    };
    setLoader(true);
    let response = await dispatch(
      addAddtionalExtraRoom({
        reservationId: reservationData._id,
        roomTypesList: roomtypes,
        payment: payment,
      })
    );

    if (response && response.data) {
      if (
        currentRegisterData?.channel_key != "" &&
        currentRegisterData?.connect_channel
      ) {
        await updateOTAAvailability(roomtypes);
      }
      setReservationData({ ...response.data });
      if (roomDetails) {
        let findRoom = response.data.roomInfo.find(
          (x) => x._id == roomDetails._id
        );

        if (
          moment()
            .startOf("day")
            .set({ hour: 12, minute: 30 }) <
          moment(findRoom?.reservationInDateAndTime).set({ hour: 12, minute: 30 })
        ) {
          findRoom["checkUpcoming"] = true;
        } else {
          findRoom["checkUpcoming"] = false;
        }
        setRoomDetails({
          ...findRoom,
          avialbleRoomList: roomDetails.avialbleRoomList,
        });
        editFromData({
          ...findRoom,
          avialbleRoomList: roomDetails.avialbleRoomList,
        });
      }

      setLoader(false);
      setAddRoomModalVisible(false);
    }
  };

  // handle Edit rate save
  const handleEditRatePlanSave = (ratePlan, roomData) => {
    let latestRoom = calculatedPriceOfThisRoomType(ratePlan, roomData);
    handleRoomUpdate(latestRoom);
  };

  // handle cancel reservation
  const handleCancelReservation = async () => {
    handleRoomUpdate(
      { ...roomDetails, isCancelled: true, type: cancelReservation?.type },
      false
    );
  };

  const createTransacationForm = () => {
    reservationData.roomInfo = reservationData.roomInfo.filter(
      (val) => !val.isCancelled
    );
    folioForm.setFieldsValue({
      guest: JSON.stringify({
        _id: reservationData.roomInfo[0]._id,
        customerId: reservationData.roomInfo[0].customerInfo._id,
        roomName: `${reservationData.roomInfo[0].room_type.name} ${reservationData.roomInfo[0].room_name}`,
      }),
      folio_type: "All",
      amount: pendingPrice,
      type: undefined,
    });
    setAddFolio(true);
    setPaymentType();
  };

  const createFormChangesGuest = (roomInfoId, type) => {
    let pending = 0;
    let thisRoom = reservationData.roomInfo.find((k) => k._id == roomInfoId);
    if (thisRoom) {
      if (!thisRoom.isCancelled) {
        pending = thisRoom.calculatedPriceWithTax;
        thisRoom?.addtionalItem?.map((r) => {
          if (!r.cancel) {
            pending = pending + Number(r.amount.total);
          }
        });
        thisRoom?.transationList?.map((r) => {
          if (r && !r.cancel) {
            pending = pending - Number(r.amount);
          }
        });
      }
    }
    folioForm.setFieldsValue({
      folio_type: type,
      amount: pending,
    });
  };

  const changeFolioType = (e) => {
    if (e.target.value == "All") {
      folioForm.setFieldsValue({
        folio_type: "All",
        amount: pendingPrice,
      });
    } else {
      let roomInfoId = JSON.parse(folioForm.getFieldValue("guest"))?._id;
      createFormChangesGuest(roomInfoId, e.target.value);
    }
    setFoliotype(e.target.value);
  };
  const handleGuestSelection = (value) => {
    if (folioType == "Split") {
      let roomInfoId = JSON.parse(value)?._id;
      createFormChangesGuest(roomInfoId, "Split");
    }
  };

  const totalRoomPrice = (room) => {
    if (room && room.addtionalItem && room.addtionalItem.length > 0) {
      let total = room.calculatedPriceWithTax;
      room.addtionalItem.forEach((element) => {
        if (!element.cancel) {
          total = total + Number(element.amount.total);
        }
        return total;
      });
      return Number(total).toFixed(2);
    } else {
      return Number(room.calculatedPriceWithTax).toFixed(2);
    }
  };
  // return (
  //   <HotelInvoice
  //     shopDetails={getItem("setupCache")?.shopDetails}
  //     receiptsDetails={{ customer: reservationData.customerId }}
  //     registerData={currentRegisterData}
  //  pendingPrice = { pendingPrice };
  //   />
  // );
  const handleInvoicePrint = () => {
    invoiceForm.validateFields().then(async (formData) => {
      let items = [];

      treevalues.map((id) => {
        let val = treeDataObj[id];

        if (val.type == "room") {
          items.push({
            itemId: val.value,
            title: val.title,
            value: val.calculatedPriceWithoutTax,
            item: val.item,
            room_type_name: val.room_type.name,
            tax_group: val.room_type.tax_group,
            rate_plan: val.rate_plan.rate_name,
            calCulatedTaxPrice: val.calCulatedTaxPrice,
            room_name: val.room_name,
            guest: val.guest,
            type: val.type,
            reservationInDateAndTime: val.reservationInDateAndTime,
            reservationOutDateAndTime: val.reservationOutDateAndTime,
          });
        } else if (
          val.type == "addtional" ||
          val.type == "posease-restaurant"
        ) {
          items.push({
            ...val,
            itemId: val.value,
            title: val.title,
            value: Number(val.calculatedPriceWithoutTax),
            type: val.type,
          });
        }
      });
      let gest = JSON.parse(formData.guest);
      let customer = reservationData.roomInfo.find(
        (val) => val.customerInfo._id == gest.customerId
      )?.customerInfo;
      let {
        reservationBookTime,
        reservationInDateAndTime,
        reservationStringId,
        reservationOutDateAndTime,
      } = reservationData;
      let payload = {
        reservationData: {
          reservationBookTime,
          reservationInDateAndTime,
          reservationStringId,
          reservationOutDateAndTime,
          numberOfNights,
        },
        payment: {
          total: invoiceCalCulatedValue.total,
          totalTax: invoiceCalCulatedValue.tax,
          grandTotal: reservationData.payment?.grandTotal,
        },
        items: items,
        customer: customer,
      };

      PrintReceiptFunction("invoice", payload);
    });
  };
  const [viewFolio, setViewFolio] = useState(true);

  const onFinish = async (values) => {
    values["register_data"] = {
      _id: currentRegisterData?._id,
      subscription_status: currentRegisterData.subscription_status,
      shop_name: localData?.shopDetails?.shop_name,
      voucher_footer: currentRegisterData?.voucher_footer,
      bill_header: currentRegisterData?.bill_header,

    };
    const reponse = await dispatch(
      sendConfimationLater(values, reservationData?._id)
    );

    if (reponse && !reponse.error) {
      message.success({
        content: reponse.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
    setEmailVisible(false);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const content = (
    <div>
      <Form
        name="email_form"
        layout="vertical"
        form={mailForm}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          label=""
          name="email"
          rules={[
            {
              type: "email",
              message: "Please enter a valid email!",
            },
            {
              required: true,
              message: "Email is required!",
            },
          ]}
        >
          <Input placeholder="Enter Email" />
        </Form.Item>
        <br />
        <Form.Item>
          <Button
            size="middle"
            type="primary"
            htmlType="submit"
            style={{ marginLeft: "30%" }}
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
  const col1 = (
    <Col xxl={12} lg={12} xl={12} sm={24} xs={24}>
      <Row>
        <Col span={24}>
          <div className="roomDetailBox" style={{ marginRight: "13px" }}>
            <div className="stayInfo">
              <div className="checkIn">
                <img src={checkInUser}></img>
              </div>
              <div className="roomCheckInDetail">
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationStartDate.split(" ")[0]}
                  </small>
                </p>
                <p className="checkInTime">
                  {reservationStartDate.split(" ")[1]}
                </p>
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationStartDate.split(" ")[2]}{" "}
                    {reservationStartDate.split(" ")[3]}
                  </small>
                </p>
              </div>
              <div className="arrowSign">→</div>
              <div className="roomCheckOutDetail">
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationEndDate.split(" ")[0]}
                  </small>
                </p>
                <p className="checkInTime">
                  {reservationEndDate.split(" ")[1]}
                </p>
                <p>
                  <small
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    {reservationEndDate.split(" ")[2]}{" "}
                    {reservationEndDate.split(" ")[3]}
                  </small>
                </p>
              </div>
              <div className="checkOut">
                <img src={checkoutUser}></img>
              </div>
            </div>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xxl={6} lg={6} xl={6} sm={24} xs={24} className="category-col">
          <Card
            headless
            className="reservation-category-card"
            style={{ marginRight: "3px" }}
          >
            <Card
              headless
              className="category-card"
              style={{ marginRight: "4px" }}
            >
              {windowWidth && windowWidth >= 992 ? (
                <SellModuleNav>
                  <ul className="currentbuild-ul">
                    {roomName.map((val) => {
                      return (
                        <li
                          style={{
                            fontSize: 13,
                          }}
                        >
                          <NavLink
                            to="#"
                            className={
                              currentStatus === val.value
                                ? "active"
                                : "not-active"
                            }
                            onClick={() => setCategory(val.value)}
                          >
                            <span className="nav-text">
                              <span>
                                {val.label == "All" ? "General" : val.label}
                              </span>
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "logs" ? "active" : "not-active"
                        }
                        onClick={() => {
                          let list = [];

                          reservationData.roomInfo.map((val) => {
                            if (val.isAssignIn) {
                              list.push({
                                status: "Assign",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.assignDateAndTime,
                                user: val?.assingStaffUserId?.username,
                              });
                            }
                            if (val.isCheckedIn) {
                              list.push({
                                status: "Checkin",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.checkInDateAndTime,
                                user: val?.assingStaffUserId?.username,
                              });
                            }
                            if (val.isCheckedOut) {
                              list.push({
                                status: "Checkout",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.checkOutDateAndTime,
                                user: val?.CheckOutStaffUserId?.username,
                              });
                            }
                          });
                          setLogList(list.reverse());
                          setStatus("logs");
                        }}
                      >
                        <span className="nav-text">
                          <span>Logs</span>
                        </span>
                      </NavLink>
                    </li>
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "notes" ? "active" : "not-active"
                        }
                        onClick={() => setStatus("notes")}
                      >
                        <span className="nav-text">
                          <span>
                            {reservationData?.notes?.length > 0
                              ? `Notes (${reservationData?.notes?.length})`
                              : "Notes"}
                          </span>
                        </span>
                      </NavLink>
                    </li>
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "invoice" ? "active" : "not-active"
                        }
                        onClick={() => getInvoice("invoice")}
                      >
                        <span className="nav-text">
                          <span>Invoice</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </SellModuleNav>
              ) : (
                <div className="smallDeviceMenuList">
                  <ul className="current-reservation-ul">
                    {roomName.map((val) => {
                      return (
                        <li
                          style={{
                            fontSize: 13,
                          }}
                        >
                          <NavLink
                            to="#"
                            className={
                              currentStatus === val.value
                                ? "active"
                                : "not-active"
                            }
                            onClick={() => setCategory(val.value)}
                          >
                            <span className="nav-text">
                              <span>
                                {val.label == "All" ? "General" : val.label}
                              </span>
                            </span>
                          </NavLink>
                        </li>
                      );
                    })}
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "logs" ? "active" : "not-active"
                        }
                        onClick={() => {
                          let list = [];

                          reservationData.roomInfo.map((val) => {
                            if (val.isAssignIn) {
                              list.push({
                                status: "Assign",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.assignDateAndTime,
                                user: val?.assingStaffUserId?.username,
                              });
                            }
                            if (val.isCheckedIn) {
                              list.push({
                                status: "Checkin",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.checkInDateAndTime,
                                user: val?.assingStaffUserId?.username,
                              });
                            }
                            if (val.isCheckedOut) {
                              list.push({
                                status: "Checkout",
                                room_name: `${val.room_type.name} ${val.room_name}`,
                                time: val?.checkOutDateAndTime,
                                user: val?.CheckOutStaffUserId?.username,
                              });
                            }
                          });
                          setLogList(list.reverse());
                          setStatus("logs");
                        }}
                      >
                        <span className="nav-text">
                          <span>Logs</span>
                        </span>
                      </NavLink>
                    </li>
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "notes" ? "active" : "not-active"
                        }
                        onClick={() => setStatus("notes")}
                      >
                        <span className="nav-text">
                          <span>Notes</span>
                        </span>
                      </NavLink>
                    </li>
                    <li
                      style={{
                        fontSize: 13,
                      }}
                    >
                      <NavLink
                        to="#"
                        className={
                          currentStatus === "invoice" ? "active" : "not-active"
                        }
                        onClick={() => getInvoice("invoice")}
                      >
                        <span className="nav-text">
                          <span>Invoice</span>
                        </span>
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </Card>
          </Card>
        </Col>
        <Col xxl={18} lg={18} xl={18} sm={24} xs={24} className="menuitem-col">
          <Card
            headless
            className="menu-item-card"
            style={{ marginRight: "5px" }}
          >
            <div id="scrollableDiv" className="sell-table-parentss">
              <div className="room_form_container">
                <div className="roomInfo_form">
                  {currentStatus == "All" && (
                    <Form
                      autoComplete="off"
                      size="large"
                      form={editRoomDetailsForm}
                    >
                      <div className="room_info">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="customer-info">
                            <span>Customer</span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              {" "}
                              |{" "}
                            </span>
                            <span>
                              <NavLink
                                to="#"
                                style={{
                                  fontSize: 13,
                                  color: "#008cba",
                                  position: "relative",
                                  top: "0px",
                                }}
                                className="customer-data-btn"
                                onClick={() => {
                                  setCustomerModalVisible({
                                    visible: true,
                                    type: "reservation",
                                  });
                                }}
                              >
                                {reservationData?.customerId?.mobile}
                              </NavLink>
                            </span>
                            <span
                              style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                              }}
                            >
                              {" "}
                              |{" "}
                            </span>
                            <span>
                              <NavLink
                                to="#"
                                style={{
                                  fontSize: 13,
                                  color: "#008cba",
                                  position: "relative",
                                  top: "0px",
                                }}
                                className="customer-data-btn"
                                onClick={() => {
                                  setCustomerModalVisible({
                                    visible: true,
                                    type: "reservation",
                                  });
                                }}
                              >
                                {reservationData?.customerId?.name}
                              </NavLink>
                            </span>
                          </div>
                        </div>
                        <div
                          className="borderUpper-top"
                          style={{ padding: "0px" }}
                        ></div>
                        <Row
                          className="stayBox"
                          style={{ marginBottom: "0px" }}
                        >
                          <Col
                            md={12}
                            sm={12}
                            lg={12}
                            xxl={12}
                            xs={24}
                            className="gutter-row"
                          >
                            <Form.Item
                              name="stay"
                              label="Stay Start Date"
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              <DatePicker
                                style={{
                                  width: "100%",
                                  minWidth: "auto",
                                }}
                                defaultValue={moment(
                                  reservationStartDate,
                                  "ddd DD MMM YYYY"
                                )}
                                size="large"
                                disabled={true}
                                onChange={(date, string) => {
                                  setNumberOfNights(
                                    moment(reservationEndDate).diff(
                                      moment(string),
                                      "days"
                                    )
                                  );
                                  setReservationStartDate(string);
                                }}
                                format="ddd DD MMM YYYY"
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                            <Form.Item
                              name="stay"
                              label="Stay End Date1"
                              style={{
                                marginRight: "10px",
                              }}
                            >
                              <DatePicker
                                style={{
                                  width: "100%",
                                  minWidth: "auto",
                                }}
                                defaultValue={moment(
                                  reservationEndDate,
                                  "ddd DD MMM YYYY"
                                )}
                                size="large"
                                disabled={true}
                                onChange={(date, string) => {
                                  setNumberOfNights(
                                    moment(string).diff(
                                      moment(reservationStartDate),
                                      "days"
                                    )
                                  );
                                  setReservationEndDate(string);
                                }}
                                format="ddd DD MMM YYYY"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row
                          className="stayBox"
                          style={{ marginBottom: "0px" }}
                        >
                          <Col
                            md={12}
                            sm={12}
                            lg={12}
                            xxl={12}
                            xs={24}
                            className="gutter-row"
                          >
                            <Form.Item
                              name="source"
                              style={{
                                marginRight: "10px",
                              }}
                              label="Source"
                              initialValue={source}
                            >
                              <Select
                                style={{
                                  marginBottom: "10px",
                                }}
                                className="high_addpd sourceField"
                                showSearch
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={
                                  reservationData?.isCancelled ? true : false
                                }
                                placeholder="Select Source"
                                onChange={(val) => {
                                  if (val == "OTA") {
                                    setChannel();
                                  } else {
                                    setChannel("Walk-in");
                                  }
                                  setSource(val);
                                }}
                                dropdownRender={(menu) => <div>{menu}</div>}
                                options={[
                                  {
                                    value: "Direct",
                                    label: "Direct",
                                  },
                                  {
                                    value: "OTA",
                                    label: "OTA",
                                  },
                                  {
                                    value: "Agent",
                                    label: "Agent",
                                  },
                                  {
                                    value: "Corporate",
                                    label: "Corporate",
                                  },
                                ]}
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                            <Form.Item
                              name="channel"
                              style={{
                                marginRight: "10px",
                              }}
                              label="Channel"
                              initialValue={channel}
                            >
                              <p style={{ display: "none" }}>{channel}</p>
                              <Select
                                className="high_addpd"
                                showSearch
                                value={channel}
                                onChange={(val) => setChannel(val)}
                                filterOption={(input, option) =>
                                  option.value
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                                placeholder="Select Channel"
                                disabled={
                                  reservationData?.isCancelled ? true : false
                                }
                                dropdownRender={(menu) => <div>{menu}</div>}
                                options={source == "OTA" ? OTA : Direct}
                              ></Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <div className="discount-section upper-btns orderfntbtn">
                          <Button
                            size="large"
                            id="orderTicketId"
                            htmlType="submit"
                            style={{
                              marginRight: "5px",
                              borderRadius: "inherit",
                              background: "#008cba",
                              border: "#008cba",
                              width: "50%",
                              height: "40px",
                            }}
                            onClick={() => PrintReceiptFunction("confirm")}
                          >
                            Print voucher
                          </Button>
                          <Popover
                            content={content}
                            title=""
                            trigger="click"
                            visible={emailVisible}
                            onVisibleChange={(visible) =>
                              setEmailVisible(visible)
                            }
                          >
                            <Button
                              size="large"
                              id="orderTicketId"
                              htmlType="submit"
                              style={{
                                marginRight: "5px",
                                borderRadius: "inherit",
                                background: "#008cba",
                                border: "#008cba",
                                width: "50%",
                                height: "40px",
                              }}
                              onClick={() => emailDataSet()}
                            >
                              Email voucher
                            </Button>
                          </Popover>

                          <Button
                            size="large"
                            id="orderTicketId"
                            htmlType="submit"
                            style={{
                              borderRadius: "inherit",
                              width: "50%",
                              height: "40px",
                              background: "#BD025D",
                              border: "#BD025D",
                            }}
                            onClick={() => handleAllRoomInvoice("update")}
                          >
                            {generalLoader ? "Loading..." : "Save"}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                  {/* {roomDetails == undefined &&
                        fetchRoomLoader &&
                        currentStatus != "All" &&
                        currentStatus != "notes" &&
                        currentStatus != "logs" &&
                        currentStatus != "invoice" && (
                          <div>
                            <Spin
                              style={{
                                color: "#BD025D",
                                position: "absolute",
                                marginLeft: "48%",
                                marginTop: "21%",
                                transform: "translate(-50%,-50%)",
                              }}
                            />
                          </div>
                        )} */}
                  {currentStatus != "All" &&
                    currentStatus != "notes" &&
                    currentStatus != "logs" &&
                    currentStatus != "invoice" &&
                    roomDetails && (
                      <div>
                        {false ? (
                          <Spin
                            style={{
                              color: "#BD025D",
                              position: "absolute",
                              marginLeft: "48%",
                              marginTop: "21%",
                              transform: "translate(-50%,-50%)",
                            }}
                          />
                        ) : (
                          <Form
                            autoComplete="off"
                            size="large"
                            form={editRoomDetailsForm}
                          >
                            <div className="room_info">
                              <div
                                // style={{
                                //   display: "flex",
                                //   justifyContent: "space-between",
                                // }}
                                className="displayFLex"
                              >
                                <div className="customer-info">
                                  <span>{`${roomDetails?.room_type.name} ${roomDetails.room_name}`}</span>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {" "}
                                    |{" "}
                                  </span>
                                  <span>
                                    <NavLink
                                      to="#"
                                      style={{
                                        fontSize: 13,
                                        color: "#008cba",
                                        position: "relative",
                                        top: "0px",
                                        cursor: !roomDetails.isCancelled
                                          ? "pointer"
                                          : "no-drop",
                                      }}
                                      className="customer-data-btn"
                                      onClick={() => {
                                        if (!roomDetails.isCancelled) {
                                          setCustomerModalVisible({
                                            visible: true,
                                            type: "room",
                                          });
                                        }
                                      }}
                                    >
                                      {roomDetails?.isCustomerAdd == false
                                        ? "Add Guest"
                                        : roomDetails?.customerInfo?.name}
                                    </NavLink>
                                  </span>
                                  <span
                                    style={{
                                      marginLeft: "10px",
                                      marginRight: "10px",
                                    }}
                                  >
                                    {" "}
                                    |{" "}
                                  </span>
                                  {roomDetails?.isCancelled ? null : (
                                    <span>
                                      <NavLink
                                        to="#"
                                        style={{
                                          fontSize: 13,
                                          color: "#008cba",
                                          position: "relative",
                                          top: "0px",
                                        }}
                                        className="customer-data-btn"
                                        onClick={() =>
                                          setDetailsLock(!detailsLock)
                                        }
                                      >
                                        Edit Room
                                      </NavLink>
                                    </span>
                                  )}
                                </div>

                                <div className="marginLeft">
                                  {roomDetails?.isCancelled ? (
                                    <Tag
                                      color="red"
                                      style={{ marginTop: "8px" }}
                                    >
                                      Cancelled
                                    </Tag>
                                  ) : (
                                    <div>
                                      <Tag
                                        color="green"
                                        style={{ marginTop: "8px" }}
                                      >
                                        {roomDetails?.status == "available"
                                          ? roomDetails?.checkUpcoming
                                            ? "Confirmed"
                                            : "Arrival"
                                          : roomDetails?.status == "assign"
                                            ? "Assign"
                                            : roomDetails?.status == "checkin"
                                              ? "In-house"
                                              : roomDetails?.status == "cleaning"
                                                ? "Dirty"
                                                : "Checked Out"}
                                      </Tag>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div
                                className="borderUpper-top"
                                style={{ padding: "0px" }}
                              ></div>
                              <Row
                                className="stayBox"
                                style={{ marginBottom: "0px" }}
                              >
                                <Col
                                  md={12}
                                  sm={12}
                                  lg={12}
                                  xxl={12}
                                  xs={24}
                                  className="gutter-row"
                                >
                                  <Form.Item
                                    name="reservationInDateAndTime"
                                    label="Stay Start Date"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  >
                                    <DatePicker
                                      disabled={detailsLock}
                                      style={{
                                        width: "100%",
                                        minWidth: "auto",
                                      }}
                                      size="middle"
                                      onChange={(date, string) => {
                                        let nights = moment(
                                          roomReservationEndDate
                                        ).diff(moment(string), "days");

                                        let outDate = roomReservationEndDate;

                                        if (nights <= 0) {
                                          outDate = moment(string)
                                            .add(1, "days")
                                            .format("ddd DD MMM YYYY");
                                          editRoomDetailsForm.setFieldsValue({
                                            reservationOutDateAndTime: moment(
                                              outDate,
                                              "ddd DD MMM YYYY"
                                            ),
                                          });
                                          setRoomReservationEndDate(outDate);
                                        }
                                        calculatedPriceOfThisRoomType(
                                          roomDetails.rate_plan,
                                          roomDetails,
                                          string,
                                          outDate
                                        );
                                        setRoomReservationStartDate(string);
                                        handleShowAvailableRoomList(
                                          string,
                                          outDate,
                                          roomDetails
                                        );
                                        checkRoomAvailble(
                                          string,
                                          outDate,
                                          1,
                                          roomDetails?.room_type,
                                          roomDetails._id
                                        );
                                      }}
                                      format="ddd DD MMM YYYY"
                                    />
                                  </Form.Item>
                                </Col>
                                <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
                                  <Form.Item
                                    name="reservationOutDateAndTime"
                                    label="Stay End Date"
                                    style={{
                                      marginRight: "10px",
                                    }}
                                  >
                                    <DatePicker
                                      disabled={detailsLock}
                                      style={{
                                        width: "100%",
                                        minWidth: "auto",
                                      }}
                                      // defaultValue={moment(
                                      //   roomReservationEndDate,
                                      //   "ddd DD MMM YYYY"
                                      // )}
                                      disabledDate={disabledEndDate}
                                      size="middle"
                                      onChange={(date, string) => {
                                        if (getItem("rate_app_enable")) {
                                          handleEditRateplan(
                                            JSON.stringify(
                                              roomDetails.rate_plan
                                            ),
                                            roomDetails,
                                            roomReservarionStartDate,
                                            string
                                          );
                                        } else {
                                          calculatedPriceOfThisRoomType(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            roomReservarionStartDate,
                                            string
                                          );
                                        }
                                        checkRoomAvailble(
                                          roomReservarionStartDate,
                                          string,
                                          1,
                                          roomDetails?.room_type,
                                          roomDetails._id
                                        );
                                        handleShowAvailableRoomList(
                                          roomReservarionStartDate,
                                          string,
                                          roomDetails
                                        );

                                        setRoomReservationEndDate(string);
                                      }}
                                      format="ddd DD MMM YYYY"
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    label="Room Type"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Room type is Required",
                                      },
                                    ]}
                                    name="room_edit_room_type"
                                  >
                                    <Select
                                      disabled={detailsLock}
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select room type"
                                      onChange={(l) => {
                                        let roomType = JSON.parse(l);
                                        onSelectRoomType(
                                          roomType,
                                          seleroomIndex
                                        );
                                      }}
                                    >
                                      {roomTypeList?.map((data) => (
                                        <Select.Option
                                          key={data._id}
                                          value={JSON.stringify(data)}
                                        >
                                          {data?.name}
                                        </Select.Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </Col>
                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    label="Plan"
                                    name="room_edit_rate_plan"
                                    rules={[
                                      {
                                        required: true,
                                        message: "Rate plan is Required",
                                      },
                                    ]}
                                  >
                                    <Select
                                      disabled={detailsLock}
                                      className="high_addpd room_reservation"
                                      showSearch
                                      filterOption={(input, option) =>
                                        option.value
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select plan type"
                                      dropdownRender={(menu) => (
                                        <div>{menu}</div>
                                      )}
                                      onChange={(value) =>
                                        handleEditRateplan(value, roomDetails)
                                      }
                                    >
                                      {roomDetails.room_type.rate_plan_list &&
                                        roomDetails.room_type.rate_plan_list?.map(
                                          (data, index) => (
                                            <Select.Option
                                              key={index}
                                              value={JSON.stringify(data)}
                                            >
                                              {data?.rate_name}
                                            </Select.Option>
                                          )
                                        )}
                                    </Select>
                                  </Form.Item>
                                </Col>

                                <Col
                                  xxl={8}
                                  xl={8}
                                  lg={8}
                                  md={8}
                                  sm={8}
                                  xs={24}
                                  className="gutter-box"
                                >
                                  <Form.Item
                                    className="custom-input-text"
                                    label="Room Number"
                                    name="room_edit_room_name"
                                  >
                                    <Select
                                      className="high_addpd room_reservation"
                                      showSearch
                                      allowClear={true}
                                      disabled={
                                        roomDetails?.isAssignIn == false
                                          ? false
                                          : detailsLock
                                      }
                                      filterOption={(input, option) =>
                                        option?.children
                                          ?.toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      placeholder="Select room number"
                                      onChange={(p) =>
                                        onSelectRoom(
                                          p,
                                          seleroomIndex,
                                          roomDetails.avialbleRoomList
                                        )
                                      }
                                      onFocus={() =>
                                        handleShowAvailableRoomList(
                                          roomReservarionStartDate,
                                          roomReservationEndDate,
                                          roomDetails
                                        )
                                      }
                                    >
                                      {fetchRoomLoader ? (
                                        <Select.Option
                                          key={23}
                                          disabled
                                          value="1"
                                        >
                                          <Spin
                                            style={{
                                              color: "#BD025D",
                                              textAlign: "center",
                                              marginLeft: "1px",
                                            }}
                                          />
                                        </Select.Option>
                                      ) : (
                                        roomDetails.avialbleRoomList?.map(
                                          (data, index) => {
                                            if (data) {
                                              return (
                                                <Select.Option
                                                  key={index}
                                                  value={data._id}
                                                >
                                                  {data.room_name}
                                                </Select.Option>
                                              );
                                            }
                                          }
                                        )
                                      )}
                                    </Select>
                                  </Form.Item>

                                  <div
                                    className="assigned_room_status"
                                    style={{
                                      cursor:
                                        roomDetails?.isAssignIn &&
                                          roomDetails?.isCheckedIn == true
                                          ? "no-drop"
                                          : reservationDisable
                                            ? "no-drop"
                                            : roomDetails.isCancelled ||
                                              !roomDetails.roomId
                                              ? "no-drop"
                                              : "pointer",
                                    }}
                                    onClick={() => {
                                      let check =
                                        roomDetails?.isAssignIn &&
                                        roomDetails?.isCheckedIn == true;
                                      if (
                                        !check &&
                                        reservationDisable == false &&
                                        roomDetails.roomId
                                      ) {
                                        if (!roomDetails.isCancelled) {
                                          handleRoomCheckInCheckout(
                                            roomDetails?.isAssignIn
                                              ? "unassign"
                                              : "assign",
                                            roomDetails
                                          );
                                        }
                                      }
                                    }}
                                  >
                                    {(allRoomTableLoader.type == "unassign" ||
                                      allRoomTableLoader.type == "assign") &&
                                      allRoomTableLoader.status
                                      ? "Loading..."
                                      : roomDetails?.isAssignIn
                                        ? "Unassign Room"
                                        : "Assign Room"}
                                  </div>
                                </Col>
                              </Row>

                              <Form.Item
                                className="custom-input-text"
                                label={
                                  <div className="guestLabel">
                                    <p>Guest</p>
                                    <div
                                      className="assigned_staying_info"
                                      style={{
                                        fontSize: "13px",
                                        color: "#5c5c5c",
                                      }}
                                    >
                                      <p>{roomDetails.guest.adults} Adults</p>
                                      <p>
                                        {roomDetails.guest.children} Children
                                      </p>
                                      <p>{roomDetails.guest.infants} Infants</p>
                                    </div>
                                  </div>
                                }
                                name="guest"
                              >
                                <div className="guest_numbersBox">
                                  <div className="guestCounter">
                                    <p
                                      style={{
                                        marginLeft: "10px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Adults
                                    </p>
                                    <div className="quantityies qucuentlft">
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "adults",
                                            "minus"
                                          )
                                        }
                                      >
                                        −
                                      </span>
                                      {roomDetails.guest.adults}
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "adults",
                                            "plus"
                                          )
                                        }
                                      >
                                        +
                                      </span>
                                    </div>
                                  </div>
                                  <div className="guestCounter">
                                    <p
                                      style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      children
                                    </p>
                                    <div className="quantityies qucuentlft">
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "children",
                                            "minus"
                                          )
                                        }
                                      >
                                        −
                                      </span>
                                      {roomDetails.guest.children}
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "children",
                                            "plus"
                                          )
                                        }
                                      >
                                        +
                                      </span>
                                    </div>
                                  </div>
                                  <div className="guestCounter">
                                    <p
                                      style={{
                                        marginLeft: "5px",
                                        marginBottom: "5px",
                                      }}
                                    >
                                      Infants
                                    </p>
                                    <div className="quantityies qucuentlft">
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "infants",
                                            "minus"
                                          )
                                        }
                                      >
                                        −
                                      </span>
                                      {roomDetails.guest.infants}
                                      <span
                                        className="qunatity-adjust"
                                        style={{
                                          cursor: detailsLock
                                            ? "no-drop"
                                            : "pointer",
                                        }}
                                        onClick={() =>
                                          updateGuest(
                                            roomDetails.rate_plan,
                                            roomDetails,
                                            "infants",
                                            "plus"
                                          )
                                        }
                                      >
                                        +
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </Form.Item>
                              <br />
                              <div className="discount-section">
                                <NavLink
                                  to="#"
                                  style={{
                                    color: "#008cba",
                                    fontSize: "13px",
                                    border: "none",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                  onClick={PrintReceiptFunction}
                                >
                                  Print Registration Card
                                </NavLink>
                                {/* {roomDetails.checkUpcoming
                                      ? "true"
                                      : "false"} */}
                                <NavLink
                                  to="#"
                                  style={{
                                    color: "#008cba",
                                    fontSize: "13px",
                                    border: "none",
                                    paddingLeft: "10px",
                                    paddingRight: "10px",
                                  }}
                                  onClick={() =>
                                    setCancelReservation({
                                      visible: true,
                                      type: "room",
                                    })
                                  }
                                >
                                  Cancel Room
                                </NavLink>
                              </div>
                              <div className="discount-section upper-btns orderfntbtn">
                                <Button
                                  size="large"
                                  id="orderTicketId"
                                  htmlType="submit"
                                  style={{
                                    marginRight: "5px",
                                    borderRadius: "inherit",
                                    background: "#008cba",
                                    border: "#008cba",
                                    width: "50%",
                                    height: "40px",
                                  }}
                                  disabled={
                                    roomDetails?.isCancelled
                                      ? true
                                      : detailsLock
                                        ? true
                                        : reservationDisable
                                  }
                                  onClick={() => {
                                    if (roomDetails) {
                                      setSaveModal(true);
                                    }
                                  }}
                                >
                                  Save
                                </Button>
                                {console.log("dhakdjasdasdasdad", roomDetails)}
                                <Button
                                  size="large"
                                  id="orderTicketId"
                                  htmlType="submit"
                                  disabled={
                                    roomDetails?.isCancelled
                                      ? true
                                      : roomDetails.checkUpcoming
                                        ? true
                                        : false
                                  }
                                  style={{
                                    marginRight: "5px",
                                    borderRadius: "inherit",
                                    background: "#008cba",
                                    border: "#008cba",
                                    width: "50%",
                                    height: "40px",
                                    cursor: !roomDetails.roomId
                                      ? "no-drop"
                                      : roomDetails?.isCheckedOut ==
                                        undefined ||
                                        roomDetails?.isCheckedOut == false
                                        ? roomDetails.checkUpcoming
                                          ? "no-drop"
                                          : roomDetails?.isCancelled
                                            ? "no-drop"
                                            : "pointer"
                                        : "no-drop",
                                  }}
                                  onClick={() => {
                                    if (
                                      roomDetails?.isCheckedOut == undefined ||
                                      roomDetails?.isCheckedOut == false
                                    ) {
                                      if (
                                        !roomDetails?.isCancelled &&
                                        roomDetails.roomId
                                      ) {
                                        handleRoomCheckInCheckout(
                                          roomDetails?.isCheckedIn
                                            ? "undocheckin"
                                            : "checkIn",
                                          roomDetails
                                        );
                                      }
                                    }
                                  }}
                                >
                                  {(allRoomTableLoader.type == "undocheckin" ||
                                    allRoomTableLoader.type == "checkIn") &&
                                    allRoomTableLoader.status
                                    ? "Loading..."
                                    : roomDetails?.isCheckedIn
                                      ? "Undo Check In"
                                      : "Check In"}
                                </Button>
                                {/* {roomDetails.checkUpcoming
                                      ? "true"
                                      : "false"} */}
                                <Button
                                  type="success"
                                  size="large"
                                  disabled={
                                    roomDetails?.isCheckedIn &&
                                      !roomDetails?.isCancelled
                                      ? false
                                      : true
                                  }
                                  style={{
                                    borderRadius: "inherit",
                                    width: "50%",
                                    height: "40px",
                                    background: "#BD025D",
                                    border: "#BD025D",
                                  }}
                                  onClick={() =>
                                    handleRoomCheckInCheckout(
                                      roomDetails?.isCheckedOut
                                        ? "undoCheckOut"
                                        : "checkOut",
                                      roomDetails
                                    )
                                  }
                                >
                                  {(allRoomTableLoader.type == "undoCheckOut" ||
                                    allRoomTableLoader.type == "checkOut") &&
                                    allRoomTableLoader.status
                                    ? "Loading..."
                                    : roomDetails?.isCheckedOut
                                      ? `Undo Check Out ${rsSymbol}${totalRoomPrice(
                                        roomDetails
                                      )}`
                                      : `Check Out ${rsSymbol}${totalRoomPrice(
                                        roomDetails
                                      )}`}
                                </Button>
                              </div>
                            </div>
                          </Form>
                        )}
                      </div>
                    )}
                  {currentStatus == "logs" && (
                    <div className="room_info">
                      <p className="assigned_Heading_text">
                        #{reservationData?.reservationStringId}
                      </p>
                      <div style={{ marginTop: "10px" }}>
                        <label className="space-content">
                          <span>Last reservation update</span>
                          <span
                            style={{
                              marginRight: "10px",
                            }}
                          >
                            {commonFunction.convertToDate(
                              logList[0] &&
                                logList[0].time >
                                reservationData?.reservationUpdateTime
                                ? logList[0].time
                                : reservationData?.reservationUpdateTime,
                              "ddd DD MMM YYYY h:mm A"
                            )}
                            |{" "}
                            {logList[0] &&
                              logList[0].time >
                              reservationData?.reservationUpdateTime
                              ? logList[0].user
                              : reservationData?.reservationUpdateStaffUserId
                                ?.username}
                          </span>
                        </label>
                        <div className="borderUpper-top"></div>
                      </div>

                      {logList.map((val) => {
                        if (val.status) {
                          return (
                            <div style={{ marginTop: "10px" }}>
                              <label className="space-content">
                                <span>
                                  {val.room_name} {val.status}
                                </span>
                                <span
                                  style={{
                                    marginRight: "10px",
                                  }}
                                >
                                  {commonFunction.convertToDate(
                                    val.time,
                                    "ddd DD MMM YYYY h:mm A"
                                  )}{" "}
                                  | {val.user}
                                </span>
                              </label>
                              <div className="borderUpper-top"></div>
                            </div>
                          );
                        }
                      })}
                    </div>
                  )}
                  {currentStatus == "notes" && (
                    <div className="room_info">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div></div>
                        <Button
                          onClick={() => {
                            setNotesModal(true);
                            setEditNote();
                            setImportantNotes(false);
                            notesForm.resetFields();
                          }}
                        >
                          Add notes
                        </Button>
                      </div>

                      {reservationData?.notes?.map((val) => {
                        return (
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "25px",
                            }}
                          >
                            <label>
                              <span>
                                Create
                                {` ${commonFunction.convertToDate(
                                  val.createAt,
                                  "MMM DD, Y h:mm A"
                                )}`}
                              </span>
                              <span> | </span>
                              <span
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                Update
                                {` ${commonFunction.convertToDate(
                                  val.updateAt,
                                  "MMM DD, Y h:mm A"
                                )}`}
                              </span>
                              <span> | </span>
                              <span>{val.notesUserName}</span>
                              {val.cancel == false && (
                                <FeatherIcon
                                  icon="edit"
                                  size={12}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "6px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => editNotes(val, "edit")}
                                />
                              )}

                              {val.cancel == false && (
                                <FeatherIcon
                                  icon="trash"
                                  size={12}
                                  style={{
                                    cursor: "pointer",
                                    marginTop: "6px",
                                    marginLeft: "5px",
                                  }}
                                  onClick={() => editNotes(val, "delete")}
                                />
                              )}
                            </label>
                            <div className="borderUpper-top"></div>
                            {val.cancel ? (
                              <p
                                style={{
                                  wordWrap: "break-word",
                                  textDecoration: "line-through",
                                }}
                              >
                                {val.important && (
                                  <StarOutlined
                                    style={{ marginRight: "8px" }}
                                  />
                                )}
                                {"   "}
                                {val.text}
                              </p>
                            ) : (
                              <p
                                style={{
                                  wordWrap: "break-word",
                                }}
                              >
                                {val.important && (
                                  <StarOutlined
                                    style={{ marginRight: "8px" }}
                                  />
                                )}{" "}
                                {"   "}
                                {val.text}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {currentStatus == "invoice" && (
                    <div className="room_info">
                      <p className="assigned_Heading_text">
                        #{reservationData?.reservationStringId}
                      </p>

                      {invoiceList?.map((val) => {
                        return (
                          <div
                            style={{
                              marginTop: "10px",
                              marginBottom: "25px",
                            }}
                          >
                            <label>
                              <span>
                                <span>Invoice No :</span>
                                <NavLink
                                  to={`/room-receipts/${val._id}`}
                                  style={{ color: "#008cba" }}
                                >
                                  #{val.invoiceSringId}{" "}
                                </NavLink>
                                , Prepared by {val.invoiceUserName} on{" "}
                                {` ${commonFunction.convertToDate(
                                  val.createAt,
                                  "MMM DD, Y h:mm A"
                                )}`}
                              </span>
                              <span> | </span>
                              <span>
                                <span
                                  style={{
                                    color: "#008cba",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleRepetInvoicePrint(val)}
                                >
                                  Print Preview
                                </span>
                              </span>
                              <span> | </span>
                              {val.delete == false && (
                                <NavLink
                                  to="#"
                                  style={{ color: "#008cba" }}
                                  onClick={() =>
                                    setDeleteInvoiceModal({
                                      id: val._id,
                                      visible: true,
                                    })
                                  }
                                >
                                  Cancel Invoice
                                </NavLink>
                                // <FeatherIcon
                                //   icon="trash"
                                //   size={12}
                                //   style={{
                                //     cursor: "pointer",
                                //     marginTop: "6px",
                                //     marginLeft: "5px",
                                //   }}
                                //   onClick={() =>
                                //     setDeleteInvoiceModal({
                                //       id: val._id,
                                //       visible: true,
                                //     })
                                //   }
                                // />
                              )}
                            </label>
                            <div className="borderUpper-top"></div>
                            {val.items.map((m, index) => {
                              return (
                                <div>
                                  <div
                                    style={{
                                      justifyContent: "space-between",
                                      display: "flex",
                                      marginLeft: "20px",
                                      marginRight: "20px",
                                      marginBottom: "6px",
                                      textDecoration: val.delete
                                        ? "line-through"
                                        : "",
                                    }}
                                  >
                                    <div>
                                      {m.roomName
                                        ? m.roomName
                                        : `${m.room_type_name} ${m.room_name}`}{" "}
                                      {m.type != "addtional" ? " | " : ""}{" "}
                                      {m.type == "room"
                                        ? "Room Charges"
                                        : m.type == "posease-restaurant"
                                          ? "In Room Service"
                                          : ""}{" "}
                                      {m.itemName ? ` | ${m.itemName}` : null}
                                    </div>
                                    <div>
                                      {" "}
                                      {rsSymbol}
                                      {Number(m.value).toFixed(2)}
                                    </div>
                                  </div>
                                  {index == val.items.length - 1 &&
                                    val.payment.totalTax > 0 && (
                                      <div className="borderUpper-top"></div>
                                    )}{" "}
                                  {index == val.items.length - 1 &&
                                    val.payment.totalTax > 0 && (
                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                          marginLeft: "20px",
                                          marginRight: "20px",
                                          marginBottom: "6px",
                                          textDecoration: val.delete
                                            ? "line-through"
                                            : "",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "15px",
                                          }}
                                        >
                                          Taxes
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "15px",
                                          }}
                                        >
                                          {rsSymbol}
                                          {Number(val.payment.totalTax).toFixed(
                                            2
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  {index == val.items.length - 1 && (
                                    <div>
                                      <div className="borderUpper-top"></div>

                                      <div
                                        style={{
                                          justifyContent: "space-between",
                                          display: "flex",
                                          marginLeft: "20px",
                                          marginRight: "20px",
                                          marginBottom: "6px",
                                          textDecoration: val.delete
                                            ? "line-through"
                                            : "",
                                        }}
                                      >
                                        <div
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "15px",
                                          }}
                                        >
                                          Total
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: 700,
                                            fontSize: "15px",
                                          }}
                                        >
                                          {rsSymbol}
                                          {Number(val.payment.total).toFixed(2)}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            {val.notes && val.notes != "" && (
                              <p style={{ marginTop: "5px" }}>
                                notes - {val.notes}
                              </p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
  const col2 = (
    <Col
      xxl={12}
      lg={12}
      xl={12}
      sm={24}
      xs={24}
      className="cart-sell-data cart-col reservation"
    >
      <Card headless>
        <div className="order-summery-main">
          <p className="order-summry-header">
            <span className="tabel_necurnt">
              <bold style={{ fontSize: "15px", fontWeight: "700" }}>
                #{reservationData?.reservationStringId}{" "}
              </bold>

              <span style={{ marginLeft: "8px", marginRight: "8px" }}> | </span>
              <bold style={{ fontSize: "15px", fontWeight: "700" }}>
                {`${numberOfNights} Nights`}
              </bold>
            </span>
            <span className="pull-right sp-bill-actions">
              <Row>
                <NavLink
                  to="#"
                  className="customer-data-btn"
                  style={{
                    fontSize: 13,
                  }}
                >
                  {checkDeleteIcon ? (
                    <div
                      style={{
                        marginRight: 8,
                        color: "#008cba",
                      }}
                      onClick={() => {
                        setCancelReservation({
                          visible: true,
                          type: "all",
                        });
                      }}
                    >
                      Cancel Reservation
                    </div>
                  ) : (
                    <div
                      style={{
                        marginRight: 8,
                        color: "#008cba",
                      }}
                      onClick={() => {
                        tabChangeToCurrentFunction("ORDER");
                      }}
                    >
                      Go back
                    </div>
                  )}
                </NavLink>
              </Row>
            </span>
          </p>
          <Form form={addForm}>
            <Space size="medium" />
            {allRoomTable}
            <div className="discount-section">
              <Button
                type="link"
                className="onhover"
                style={{
                  color: "#008cba",
                  fontSize: "13px",
                  background: "#F4F5F7",
                  border: "none",
                }}
                onClick={() => {
                  setAddRoomModalVisible(true);
                }}
              >
                Add Room
              </Button>
              <Button
                type="link"
                className="onhover"
                style={{
                  color: "#008cba",
                  fontSize: "13px",
                  background: "#F4F5F7",
                  border: "none",
                }}
                onClick={() => {
                  createTransacationForm();
                }}
              >
                Add transaction
              </Button>

              <Button
                type="link"
                className="onhover"
                style={{
                  color: "#008cba",
                  fontSize: "13px",
                  background: "#F4F5F7",
                  border: "none",
                }}
                onClick={() => {
                  setAddtionalUpdateId();
                  reservationData.roomInfo = reservationData.roomInfo.filter(
                    (val) => !val.isCancelled
                  );
                  addtionalForm.setFieldsValue({
                    guest: JSON.stringify({
                      _id: reservationData.roomInfo[0]._id,
                      customerId: reservationData.roomInfo[0].customerInfo._id,
                      roomName: `${reservationData.roomInfo[0].room_type.name} ${reservationData.roomInfo[0].room_name}`,
                    }),
                    item: undefined,
                  });
                  setAddtionalQty(1);
                  setAddtionalValue(0);
                  setAddtionalModal(true);
                }}
              >
                Addtional Item
              </Button>
            </div>

            <div className="discount-section upper-btns orderfntbtn checkPendingButton">
              <Button
                size="large"
                id="orderTicketId"
                htmlType="submit"
                disabled={fetchInvoiceLoader}
                style={{
                  marginRight: "5px",
                  borderRadius: "inherit",
                  background: "#008cba",
                  border: "#008cba",
                  width: "50%",
                  height: "40px",
                }}
                onClick={generateInvoce}
              >
                Generate invoice
              </Button>

              <Button
                type="success"
                disabled={pendingButtonDisable}
                size="large"
                style={{
                  borderRadius: "inherit",
                  width: "50%",
                  height: "40px",
                  background: "#BD025D",
                  border: "#BD025D",
                }}
                onClick={() => handleAllRoomInvoice("complate")}
              >
                {loaderCharge
                  ? "Loading..."
                  : pendingPrice > 0
                    ? `Outstanding ${rsSymbol}${Number(pendingPrice).toFixed(2)}`
                    : allCheckoutRoomDone
                      ? "Complete Reservation"
                      : "Checkout And Complete"}
              </Button>
            </div>
          </Form>
        </div>
      </Card>
    </Col>
  );
  return (
    <>
      {windowWidth <= 992 && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              type="primary"
              style={{ marginBottom: "15px" }}
              onClick={() => setViewFolio(!viewFolio)}
            >
              {viewFolio ? "View Details" : "View Folio"}
            </Button>
          </div>
          <Row>{viewFolio == false ? col1 : col2}</Row>
        </div>
      )}
      {windowWidth <= 992 == false && (
        <Row>
          {col1}
          {col2}
        </Row>
      )}

      {invoiceModal && (
        <Modal
          title="Invoice"
          open={invoiceModal}
          width={600}
          bodyStyle={{ paddingTop: 5 }}
          onCancel={() => setInvoiceModal(false)}
          footer={[
            <Button onClick={() => setInvoiceModal(false)}>Cancel</Button>,
            <Button
              disabled={treevalues?.length ? false : true}
              onClick={() => {
                handleInvoicePrint();
              }}
              className="print-invoice-hover"
              style={{
                borderRadius: "inherit",
                backgroundColor: "#008cba",
                border: "#008cba",
                color: "white",
              }}
            >
              Print Preview
            </Button>,
            <Button
              disabled={treevalues?.length ? false : true}
              onClick={() => submitInvoice()}
              type="primary"
            >
              {invoiceLoader ? "Loading..." : "Create Invoice"}
            </Button>,
          ]}
        >
          <div>
            <Form
              style={{ width: "100%" }}
              form={invoiceForm}
              name="addNotes"
              className="comman-input"
            >
              <Form.Item
                style={{ marginTop: "10px", marginRight: "10px" }}
                name="invoice_type"
              >
                <Radio.Group value={invoiceType} onChange={changeInvoiceType}>
                  <Radio value="All">All</Radio>
                  <Radio value="Split">Split</Radio>
                </Radio.Group>
              </Form.Item>

              <Row gutter={16}>
                <Col
                  md={12}
                  sm={12}
                  lg={12}
                  xxl={12}
                  xs={24}
                  className="gutter-row"
                >
                  <Form.Item
                    label="Guest"
                    name="guest"
                    rules={[
                      {
                        required: true,
                        message: "Guest is required",
                      },
                    ]}
                  >
                    <Select
                      className="high_addpd room_reservation"
                      showSearch
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder="Select guest"
                      onChange={changeGuestInvoice}
                    >
                      {reservationData.roomInfo?.map(
                        (data) =>
                          !data.isCancelled && (
                            <Select.Option
                              key={data._id}
                              value={JSON.stringify({
                                _id: data._id,
                                customerId: data.customerInfo._id,
                                roomName: `${data.room_type.name} ${data.room_name}`,
                              })}
                            >
                              {`${data.customerInfo.name} - (${data.room_name == "-" ? "" : data.room_name
                                })`}
                            </Select.Option>
                          )
                      )}
                    </Select>
                  </Form.Item>
                </Col>
                <Col
                  md={12}
                  sm={12}
                  lg={12}
                  xxl={12}
                  xs={24}
                  className="gutter-row"
                >
                  <Form.Item name="notes" label="Notes">
                    <Input placeholder="Notes" />
                  </Form.Item>
                </Col>
              </Row>

              {invoiceType == "Split" && (
                <Form.Item name="items" label="Items">
                  <div style={{ display: "none" }}> {treevalues}</div>

                  <TreeSelect
                    multiple
                    className="multiItems"
                    value={treevalues}
                    treeData={[
                      {
                        title:
                          treevalues.length > 0 ? (
                            <span
                              onClick={() => {
                                setTreeValues([]);
                                setInvoiceCalCulatedValue(
                                  createTotalInvoiceValue([])
                                );
                              }}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Unselect all
                            </span>
                          ) : (
                            <span
                              onClick={() => {
                                setTreeValues(totalIds);
                                setInvoiceCalCulatedValue(
                                  createTotalInvoiceValue([...totalIds])
                                );
                              }}
                              style={{
                                display: "inline-block",
                                color: "#286FBE",
                                cursor: "pointer",
                              }}
                            >
                              Select all
                            </span>
                          ),
                        value: "xxx",
                        disableCheckbox: true,
                        disabled: true,
                      },
                      ...treeData,
                    ]}
                    treeDefaultExpandAll
                    onChange={(val) => {
                      setTreeValues(val);
                      setInvoiceCalCulatedValue(
                        createTotalInvoiceValue([...val])
                      );
                    }}
                    treeCheckable={true}
                    showCheckedStrategy={SHOW_PARENT}
                    placeholder="Please select Register"
                    style={{
                      width: "100%",
                      marginBottom: 10,
                    }}
                  />
                </Form.Item>
              )}
            </Form>
            <div
              style={{
                overflowY: "scroll",
                maxHeight: "300px",
              }}
            >
              <div className="borderUpper-top"></div>
              {treevalues.map((id) => {
                let val = treeDataObj[id];
                return (
                  <div>
                    <div
                      style={{
                        justifyContent: "space-between",
                        display: "flex",
                        marginLeft: "20px",
                        marginRight: "20px",
                      }}
                    >
                      <div>
                        {val.roomName
                          ? val.roomName
                          : `${val.room_type.name} ${val.room_name}`}{" "}
                        {val.type != "addtional" && " | "}
                        {val.type == "room"
                          ? "Room Charges"
                          : val.type == "posease-restaurant"
                            ? "In Room Service"
                            : ""}{" "}
                        {val.itemName ? ` | ${val.itemName}` : null}
                        {/* {val.title} */}
                      </div>
                      <div>
                        {rsSymbol}
                        {Number(
                          val.calculatedPriceWithTax
                            ? val.calculatedPriceWithTax
                            : val.calculatedPriceWithoutTax
                        ).toFixed(2)}
                      </div>
                    </div>
                    <div className="borderUpper-top"></div>
                  </div>
                );
              })}
              {/* {invoiceCalCulatedValue?.tax > 0 && treevalues?.length > 0 && (
                <div>
                  <div
                    style={{
                      justifyContent: "space-between",
                      display: "flex",
                      marginLeft: "20px",
                      marginRight: "20px",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "15px",
                      }}
                    >
                      Taxes
                    </div>
                    <div
                      style={{
                        fontWeight: 700,
                        fontSize: "15px",
                      }}
                    >
                      {rsSymbol}
                      {Number(invoiceCalCulatedValue.tax).toFixed(2)}
                    </div>
                  </div>
                  <div className="borderUpper-top"></div>
                </div>
              )} */}

              {invoiceCalCulatedValue?.total > 0 && treevalues?.length > 0 && (
                <div
                  style={{
                    justifyContent: "space-between",
                    display: "flex",
                    marginLeft: "20px",
                    marginRight: "20px",
                  }}
                >
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    Total
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "15px",
                    }}
                  >
                    {rsSymbol}
                    {Number(invoiceCalCulatedValue.total).toFixed(2)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal>
      )}
      {overBookingModal && (
        <Modal
          title="Not Available"
          visible={overBookingModal}
          onCancel={() => setOverBookingModal(false)}
          onOk={() => setOverBookingModal(false)}
        >
          <p>
            Selected rooms are currently occupied for this category. Please wait
            for availability or consider offering alternative category.
          </p>
        </Modal>
      )}
      {openALlCheckOutModal && (
        <Modal
          title="CheckOut All"
          visible={openALlCheckOutModal}
          onCancel={() => setOpenAllCheckoutModal(false)}
          onOk={() => handleAllCheckOutAndComplate()}
          okText={
            allcheckoutLoader ? "Loading..." : "Checkout All and Complate"
          }
        >
          <p>
            Are you sure you want to checkout all rooms and complete this
            reservation ?
          </p>
        </Modal>
      )}
      <Modal
        title="Add notes"
        open={notesModal}
        onOk={notesForm.submit}
        onCancel={() => setNotesModal(false)}
        okText={
          notesLoader
            ? "Loading"
            : editNote?.check == "delete"
              ? "Cancel"
              : "Save"
        }
      >
        <Form
          style={{ width: "100%" }}
          form={notesForm}
          name="addNotes"
          onFinish={submitNotes}
          className="comman-input"
        >
          {editNote?.check == "delete" ? (
            <div>are you sure cancel this notes</div>
          ) : (
            <div>
              <Form.Item name="notes" label="Notes">
                <TextArea
                  style={{ marginBottom: 10 }}
                  placeholder="Enter your notes"
                />
              </Form.Item>
              <Form.Item name="important">
                <Checkbox
                  checked={importantNotes}
                  onChange={(e) => setImportantNotes(e.target.checked)}
                >
                  Important
                </Checkbox>
              </Form.Item>
            </div>
          )}
        </Form>
      </Modal>

      <Modal
        title="Cancel"
        open={deleteInvoiceModal.visible}
        onOk={() => deleteInvoice()}
        onCancel={() =>
          setDeleteInvoiceModal({
            visible: false,
            id: "",
          })
        }
      >
        <p>Are you sure cancel invoice ?</p>
      </Modal>
      <Modal
        title="Reservation Cancel"
        open={cancelReservation.visible}
        onOk={() => handleCancelReservation()}
        onCancel={() =>
          setCancelReservation({
            visible: false,
            type: "room",
          })
        }
        okText={loader ? "Loading..." : "Yes"}
        cancelText="No"
      >
        <p>Are you sure cancel reservation ?</p>
      </Modal>
      <Modal
        title="Cancel"
        open={deleteTransacationModal.visible}
        onOk={transactionForm.submit}
        onCancel={() =>
          setDeleteTransacation({
            visible: false,
            id: "",
            value: 0,
          })
        }
        okText={loader ? "Loading..." : "Cancel paymnet"}
      >
        <Form
          style={{ width: "100%" }}
          form={transactionForm}
          name="addNotes"
          onFinish={deleteTrancation}
          className="comman-input"
        >
          <div>
            <Form.Item
              name="cancel_notes"
              label="Cancel notes"
              rules={[{ required: true }]}
            >
              <TextArea
                style={{ marginBottom: 10 }}
                placeholder="Enter your notes"
              />
            </Form.Item>
            <Form.Item
              name="cancelType"
              label="Payment Type"
              rules={[{ required: true }]}
              initialValue={PaymentType}
            >
              <Radio.Group
                onChange={(e) => setPaymentType(e.target.value)}
                value={PaymentType}
                className="tick-radio"
              >
                <Radio.Button
                  value="cash"
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {PaymentType === "cash" ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  Cash
                </Radio.Button>
                <Radio.Button
                  value="card"
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {PaymentType === "card" ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}{" "}
                  Credit / Debit Card
                </Radio.Button>
                <Radio.Button
                  value="other"
                  style={{
                    marginRight: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {PaymentType === "other" ? (
                    <svg
                      width="13px"
                      style={{ marginRight: "2px" }}
                      viewBox="0 0 123 102"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                        fill="#BE3D5D"
                      />
                    </svg>
                  ) : (
                    ""
                  )}
                  Other
                </Radio.Button>
                {PaymentTypeList.map((val) => {
                  return (
                    <Radio.Button
                      value={val.name}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {PaymentType === val.name ? (
                        <svg
                          width="13px"
                          style={{
                            marginRight: "2px",
                          }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      {val.name}
                    </Radio.Button>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </div>
        </Form>
      </Modal>

      {customerModalVisible?.visible && (
        <RoomCustomer
          customerModalVisible={customerModalVisible}
          setCustomerModalVisible={setCustomerModalVisible}
          roomDetails={{
            customerInfo:
              roomDetails?.isCustomerAdd == false
                ? {}
                : roomDetails?.customerInfo
                  ? roomDetails.customerInfo
                  : {},
          }}
          reservationData={reservationData}
          reservationCustomerUpdate={handleAllRoomInvoice}
          handleSingleCustomerAddUpdate={handleSingleCustomerAddUpdate}
          loader={loader}
        />
      )}
      {addRoomModalVisible && (
        <AddAddtionalRoom
          addRoomModalVisible={addRoomModalVisible}
          setAddRoomModalVisible={setAddRoomModalVisible}
          roomTypeList={roomTypeList}
          numberOfNights={numberOfNights}
          handleAddAddtionalRoom={handleAddAddtionalRoom}
          loader={loader}
          rsSymbol={rsSymbol}
          reservationData={reservationData}
          currentRegisterData={currentRegisterData}
        />
      )}
      {editModalOpen && (
        <EditRoomPrice
          editModalOpen={editModalOpen}
          setEditModalOpen={setEditModalOpen}
          roomDetails={roomDetails}
          loader={loader}
          handleEditRatePlanSave={handleEditRatePlanSave}
        />
      )}
      <Modal
        title="Save"
        open={saveModal}
        okText={loader ? "Loading.." : "Save"}
        onOk={() => handleRoomUpdate()}
        onCancel={() => setSaveModal(false)}
      >
        Are you sure save this details?
      </Modal>

      <Modal
        title="Add Folio"
        open={addFolio}
        onOk={folioForm.submit}
        onCancel={() => setAddFolio(false)}
        okText={
          allRoomTableLoader.type == "invoice" && allRoomTableLoader.status
            ? "Loading.."
            : "Add payment"
        }
        okButtonProps={{
          disabled: folioForm.getFieldValue("amount") == 0 ? true : false,
        }}
      >
        <Form
          style={{ width: "100%" }}
          form={folioForm}
          name="addFolio"
          onFinish={submitAddFolio}
          className="comman-input"
        >
          <Form.Item
            style={{ marginTop: "10px", marginRight: "10px" }}
            name="folio_type"
          >
            <Radio.Group value={folioType} onChange={changeFolioType}>
              <Radio value="All">All</Radio>
              <Radio value="Split" disabled={checkOneTimeInAllPayment}>
                Split
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Guest"
            name="guest"
            rules={[
              {
                required: true,
                message: "Guest is required",
              },
            ]}
          >
            <Select
              className="high_addpd room_reservation"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select guest"
              onChange={handleGuestSelection}
            >
              {reservationData.roomInfo?.map(
                (data) =>
                  !data.isCancelled && (
                    <Select.Option
                      key={data._id}
                      value={JSON.stringify({
                        _id: data._id,
                        customerId: data.customerInfo._id,
                        roomName: `${data.room_type.name} ${data.room_name}`,
                      })}
                    >
                      {`${data.customerInfo.name} - (${data.room_name == "-" ? "" : data.room_name
                        })`}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="amount"
            label="Amount"
            rules={[
              {
                pattern: new RegExp(/^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/),
                message: "Transaction amount cannot be negative",
              },
              {
                required: true,
                message: "Please enter amount",
              },
              // {
              //   validator: (_, value) => {
              //     if (Number(value) > pendingPrice) {
              //       return Promise.reject(
              //         "Amount cannot be more than the total."
              //       );
              //     } else {
              //       return Promise.resolve();
              //     }
              //   },
              // },
            ]}
            initialValue={pendingPrice}
          >
            <Input
              min={0}
              type="number"
              step="any"
              placeholder="Enter transaction amount"
              style={{ marginBottom: 10 }}
              onKeyPress={(event) => {
                if (event.key.match("[0-9,.]+")) {
                  return true;
                } else {
                  return event.preventDefault();
                }
              }}
            />
          </Form.Item>
          <Form.Item name="notes" label="Notes">
            <TextArea
              style={{ marginBottom: 10 }}
              placeholder="Enter your notes"
            />
          </Form.Item>
          <Form.Item
            name="type"
            label="Payment Type"
            rules={[
              {
                required: true,
                message: "Payment Type is required",
              },
            ]}
          >
            <Radio.Group
              onChange={(e) => setPaymentType(e.target.value)}
              value={PaymentType}
              className="tick-radio"
            >
              <Radio.Button
                value="cash"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {PaymentType === "cash" ? (
                  <svg
                    width="13px"
                    style={{ marginRight: "2px" }}
                    viewBox="0 0 123 102"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                      fill="#BE3D5D"
                    />
                  </svg>
                ) : (
                  ""
                )}
                Cash
              </Radio.Button>
              <Radio.Button
                value="card"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {PaymentType === "card" ? (
                  <svg
                    width="13px"
                    style={{ marginRight: "2px" }}
                    viewBox="0 0 123 102"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                      fill="#BE3D5D"
                    />
                  </svg>
                ) : (
                  ""
                )}{" "}
                Credit / Debit Card
              </Radio.Button>
              <Radio.Button
                value="other"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {PaymentType === "other" ? (
                  <svg
                    width="13px"
                    style={{ marginRight: "2px" }}
                    viewBox="0 0 123 102"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                      fill="#BE3D5D"
                    />
                  </svg>
                ) : (
                  ""
                )}
                Other
              </Radio.Button>
              {PaymentTypeList.map((val) => {
                return (
                  <Radio.Button
                    value={val.name}
                    style={{
                      marginRight: "10px",
                      marginBottom: "10px",
                    }}
                  >
                    {PaymentType === val.name ? (
                      <svg
                        width="13px"
                        style={{
                          marginRight: "2px",
                        }}
                        viewBox="0 0 123 102"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                          fill="#BE3D5D"
                        />
                      </svg>
                    ) : (
                      ""
                    )}
                    {val.name}
                  </Radio.Button>
                );
              })}
              {/* <Radio.Button
                value="pending"
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                }}
              >
                {PaymentType === "pending" ? (
                  <svg
                    width="13px"
                    style={{ marginRight: "2px" }}
                    viewBox="0 0 123 102"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                      fill="#BE3D5D"
                    />
                  </svg>
                ) : (
                  ""
                )}
                Credit Sales (Pending)
              </Radio.Button> */}
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={
          addtionalUpdateId ? "Update Addtional Item" : "Add Addtional Item"
        }
        open={addAdtioanlModal}
        onCancel={() => setAddtionalModal(false)}
        footer={[
          <Button key="back" onClick={() => setAddtionalModal(false)}>
            Cancel
          </Button>,
          <Button key="cancel" onClick={() => submitAddtional("remove")}>
            Remove item
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={() => submitAddtional("add")}
          >
            {allRoomTableLoader.type == "addtional" && allRoomTableLoader.status
              ? "Loading.."
              : addtionalUpdateId
                ? "Update item"
                : "Add item"}
          </Button>,
        ]}
      >
        <Form
          style={{ width: "100%" }}
          form={addtionalForm}
          name="addtionalForm"
          className="comman-input"
        >
          <Form.Item
            label="Guest"
            name="guest"
            rules={[
              {
                required: true,
                message: "Guest is required",
              },
            ]}
          >
            <Select
              style={{ marginBottom: 10 }}
              className="high_addpd room_reservation"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select guest"
            >
              {reservationData.roomInfo?.map(
                (data) =>
                  !data.isCancelled && (
                    <Select.Option
                      key={data._id}
                      value={JSON.stringify({
                        _id: data._id,
                        customerId: data.customerInfo._id,
                        roomName: `${data.room_type.name} ${data.room_name}`,
                      })}
                      style={{ marginBottom: 10 }}
                    >
                      {`${data.customerInfo.name} - (${data.room_name == "-" ? "" : data.room_name
                        })`}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Form.Item
            name="item"
            label="Item"
            rules={[
              {
                required: true,
                message: "Item is required",
              },
            ]}
          >
            <Select
              style={{ marginBottom: 10 }}
              className="high_addpd room_reservation"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              placeholder="Select item"
              onChange={(l) => {
                let val = JSON.parse(l);
                setSelectAddtionalCharge(val);
                setAddtionalValue(Number(val.charge_value));
              }}
            >
              {addtionalCharge?.map(
                (data) =>
                  data.type &&
                  data.type == "Hotel" && (
                    <Select.Option
                      key={data._id}
                      value={JSON.stringify(data)}
                      style={{ marginBottom: 10 }}
                    >
                      {data?.charge_name}
                    </Select.Option>
                  )
              )}
            </Select>
          </Form.Item>
          <Row>
            <Col md={12} lg={12} sm={24}>
              <Form.Item
                name="qty"
                label="Nights / quantity"
                rules={[
                  {
                    pattern: new RegExp(
                      /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    ),
                    message: "Quantity cannot be negative",
                  },
                  {
                    required: true,
                    message: "Please enter quantity",
                  },
                ]}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                <Input
                  min={0}
                  style={{ marginBottom: 10 }}
                  type="number"
                  step="any"
                  value={addtionalQty}
                  onChange={(e) => setAddtionalQty(Number(e.target.value))}
                  placeholder="Enter transaction amount"
                  onKeyPress={(event) => {
                    if (event.key.match("[0-9,.]+")) {
                      return true;
                    } else {
                      return event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12} sm={24}>
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    pattern: new RegExp(
                      /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                    ),
                    message: "amount cannot be negative",
                  },
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input
                  min={0}
                  type="number"
                  step="any"
                  disabled={addtionalForm.getFieldValue("item") ? false : true}
                  value={addtionalValue}
                  onChange={(e) => setAddtionalValue(Number(e.target.value))}
                  placeholder="Enter transaction amount"
                  style={{ marginBottom: 10 }}
                  onKeyPress={(event) => {
                    if (event.key.match("[0-9,.]+")) {
                      return true;
                    } else {
                      return event.preventDefault();
                    }
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item name="notes" label="Notes">
            <TextArea
              style={{ marginBottom: 10 }}
              placeholder="Enter your notes"
            />
          </Form.Item>
          <p>Total : {totalAddtionalValue?.total}</p>
        </Form>
      </Modal>
    </>
  );
}

export default AssignRoom;
