import "./App.css";
import * as axios from "axios";
import { useEffect, useState, useMemo, useContext, useRef } from "react";
import { Provider, useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import { ConfigProvider, message } from "antd";
import store from "./redux/store";
import Admin from "./routes/admin";
import Restaurant from "./routes/restaurant";
import Cashier from "./routes/cashier";
import AppRoute from "./routes/app";
import Auth from "./routes/auth";
import "./static/css/style.css";
import io from "socket.io-client";
import config from "./config/config";
import ProtectedRoute from "./components/utilities/protectedRoute";
import { getItem, setItem, removeItem } from "./utility/localStorageControl";
import { useDispatch } from "react-redux";
import { offLineMode } from "./redux/authentication/actionCreator";
import {
  onlineToOpenSihft,
  onlineToClosedShift,
} from "./redux/authentication/actionCreator";
import { CreateOrder, AddAndUpdateBooking } from "./redux/sell/actionCreator";
import { getAllSetUpList } from "./redux/setUp/actionCreator";
import { addOrUpdatePatty } from "./redux/pattyCash/actionCreator";

import {
  getAllOrderList,
  getAllQROrderList,
} from "./redux/onlineOrder/actionCreator";
import { getAllRegisterList } from "./redux/register/actionCreator";
import moment from "moment";
import { SocketProvider, SocketContext } from "./socket/socketContext";
import "antd/dist/antd.css";
import { getApplicationById } from "./redux/applicatons/actionCreator";
import { UserLogin } from "./container/profile/authentication/overview/UserLogin";

const { theme } = config;

const ProviderConfig = () => {
  const dispatch = useDispatch();
  const audioPlayer = useRef(null);
  const [online, setOnline] = useState(window.navigator.onLine);

  // const idb =
  //   window?.indexedDB ||
  //   window?.mozIndexedDB ||
  //   window?.webkitIndexedDB ||
  //   window?.msIndexedDB ||
  //   window?.shimIndexedDB;

  // const insertDataInIndexedDb = () => {
  //   //check for support
  //   if (!idb) {
  //     console.log("This browser doesn't support IndexedDB");
  //     return;
  //   }

  //   const request = idb.open("productList", 1);

  //   request.onerror = function(event) {
  //     console.error("An error occurred with IndexedDB");
  //     console.error(event);
  //   };

  //   request.onupgradeneeded = function(event) {
  //     console.log(event);
  //     const db = request.result;

  //     if (!db.objectStoreNames.contains("userData")) {
  //       const objectStore = db.createObjectStore("userData", {
  //         keyPath: "id",
  //       });
  //     }
  //   };

  //   request.onsuccess = function() {
  //     console.log("Database opened successfully");
  //     const db = request.result;

  //     var tx = db.transaction("userData", "readwrite");
  //     var userData = tx.objectStore("userData");
  //     return tx.complete;
  //   };
  // };
  // useEffect(() => {
  //   insertDataInIndexedDb();
  // }, []);
  const {
    rtl,
    isLoggedIn,
    topMenu,
    darkMode,
    userRole,
    isShopSetUp,
    registerList,
  } = useSelector((state) => {
    const userDetails = getItem("userDetails");
    return {
      registerList: state.register.RegisterList,
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
      isLoggedIn: state.auth.login,
      userRole: userDetails ? userDetails.role : "",
      isShopSetUp: userDetails ? userDetails.is_shop : "",
    };
  });
  const qrSocket = useContext(SocketContext);
  let [activeRegister, setActiveRegister] = useState(
    registerList?.find((val) => val.active)
  );
  let [pmsActiveRegister, setPmsActiveRegister] = useState(
    registerList?.find((val) => val.activeHotel)
  );
  const [path, setPath] = useState(window.location.pathname);

  const getAllLatestApp = async () => {
    await dispatch(getAllRegisterList("checkReceipt"));
    let register = getItem("setupCache")?.register?.find((val) => val.active);
    let curretAppData = await dispatch(getApplicationById(register));

    if (curretAppData) {
      if (curretAppData.bingage_enable) {
        setItem("bingage_enable", curretAppData.bingage_enable.enable);
      }
      if (curretAppData.create_receipt_while_fullfilling_booking) {
        setItem(
          "create_receipt_while_fullfilling_booking",
          curretAppData.create_receipt_while_fullfilling_booking.enable
        );
      }
      if (curretAppData.dyno_api_enable) {
        setItem("dyno_api_enable", curretAppData.dyno_api_enable.enable);
      }
      if (curretAppData.hotel_enable) {
        setItem("hotel_enable", curretAppData.hotel_enable.enable);
      }
      if (curretAppData.pettycash_enable) {
        setItem("pettycash_enable", curretAppData.pettycash_enable.enable);
      }
      if (curretAppData.pricebook_enable) {
        setItem("pricebook_enable", curretAppData.pricebook_enable.enable);
      }
      if (curretAppData.qrCode_enable) {
        setItem("qrCode_enable", curretAppData.qrCode_enable.enable);
      }
      if (curretAppData.waiter_app_enable) {
        setItem("waiter_app_enable", curretAppData.waiter_app_enable.enable);
      }
      if (curretAppData.inventrory_app_enable) {
        setItem(
          "inventrory_app_enable",
          curretAppData.inventrory_app_enable.enable
        );
      }
      if (curretAppData.report_analytics) {
        setItem("report_analytics", curretAppData.report_analytics.enable);
      }
    } else {
      setItem("bingage_enable", false);
      setItem("inventrory_app_enable", false);
      setItem("create_receipt_while_fullfilling_booking", false);
      setItem("dyno_api_enable", false);
      setItem("hotel_enable", false);
      setItem("pettycash_enable", false);
      setItem("pricebook_enable", false);
      setItem("qrCode_enable", false);
      setItem("waiter_app_enable", false);
      setItem("report_analytics", false);
    }
  };
  const CACHE_NAME = "dfsdfhjklkklk;k;;;kl";
  useEffect(() => {
    if (isLoggedIn && online) {
      window.onload = function () {
        caches.keys()?.then((cacheNames) => {
          if (cacheNames && cacheNames.length && caches) {
            return Promise.all(
              cacheNames.map((cacheName) => {
                if (cacheName !== CACHE_NAME) {
                  return caches.delete(cacheName);
                }
              })
            );
          }
        });
        dispatch(getAllSetUpList());
        getAllLatestApp();
      };
      let unmounted = false;
      if (!unmounted) {
        setPath(window.location.pathname);
      }
      return () => (unmounted = true);
    }
  }, [setPath]);

  function useOnlineStatus() {
    useEffect(() => {
      function handleOnline() {
        setOnline(true);
      }

      function handleOffline() {
        setOnline(false);
      }

      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);

      return () => {
        window.removeEventListener("online", handleOnline);
        window.removeEventListener("offline", handleOffline);
      };
    }, []);

    return online;
  }
  let status = useOnlineStatus();
  useEffect(() => {
    if (status) {
      let pendingReceiptsList = getItem("pendingReceipts")?.reverse();
      if (
        pendingReceiptsList != null &&
        pendingReceiptsList &&
        pendingReceiptsList.length > 0
      ) {
        let pendinglist = [];
        let totalCount = pendingReceiptsList.length;
        let pending = [];
        pendingReceiptsList.map(async (val, index) => {
          if (val.draftList) {
            let data = await dispatch(AddAndUpdateBooking(val));
            if (data) {
              pending.push(index);
              pendingReceiptsList.shift();
              pendinglist = pendingReceiptsList;
            } else {
              pendinglist = pendingReceiptsList;
            }
          } else {
            let data = await dispatch(CreateOrder(val));
            if (data) {
              pending.push(index);
              pendingReceiptsList.shift();
              pendinglist = pendingReceiptsList;
            } else {
              pendinglist = pendingReceiptsList;
            }
          }
        });
        setItem("pendingReceipts", pendinglist);
      }
      let pendingshiftsList = getItem("pendingShiftList")?.reverse();

      if (pendingshiftsList != null && pendingshiftsList.length > 0) {
        pendingshiftsList.map(async (val) => {
          if (val["openbalance"] !== undefined) {
            let data = await onlineToOpenSihft(val.openbalance, val.userName);
          } else {
            let data = await onlineToClosedShift(
              val.closebalance,
              val.userName
            );
          }
        });
        setItem("pendingShiftList", []);
      }

      let pendingPaattycashList = getItem("pendingPattyCashEntries")?.reverse();
      if (pendingPaattycashList != null && pendingPaattycashList.length > 0) {
        let pending = [];
        let pendinglist = [];
        pendingPaattycashList.map(async (val, index) => {
          let data = await dispatch(addOrUpdatePatty(val));
          if (data) {
            pending.push(index);
            pendingPaattycashList.shift();
            pendinglist = pendingPaattycashList;
          } else {
            pendinglist = pendingPaattycashList;
          }
        });
        setItem("pendingPattyCashEntries", []);
      }
      dispatch(offLineMode(false));
    } else {
      dispatch(offLineMode(true));
    }
  }, [status]);

  const MINUTE_MS = 20000;

  useEffect(() => {
    let registerData = activeRegister;

    // if (
    //   getItem("dyno_api_enable") &&
    //   registerData &&
    //   registerData.onlineOrder?.length
    // ) {
    //   const interval = setInterval(async () => {
    //     let oldOrder = getItem("totalOnlineOrder")?.length
    //       ? getItem("totalOnlineOrder").length
    //       : 0;

    //     console.log("registerData42423", registerData);
    //     if (registerData && registerData.onlineOrder?.length) {
    //       let data = await dispatch(getAllOrderList(registerData.onlineOrder));
    //       console.log(
    //         "checkmmmkm",
    //         oldOrder,
    //         data,
    //         moment().format("MMMM Do YYYY, h:mm:ss a")
    //       );
    //       if (data && oldOrder != data.kitchenUserList.length) {
    //         console.log("data343434=>", data);
    //         setItem("totalOnlineOrder", data.kitchenUserList);
    //         message.success({
    //           content: "New Order Coming",
    //           style: {
    //             float: "center",
    //             marginTop: "2vh",
    //           },
    //         });
    //       }
    //     }
    //   }, MINUTE_MS);

    //   return () => clearInterval(interval);
    // }
    // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  }, [getItem("dyno_api_enable")]);

  useEffect(() => {
    if (getItem("qrCode_enable") && activeRegister && activeRegister._id) {
      qrSocket?.on(
        `send_notification_qr_order_create/${activeRegister._id}`,
        async (register) => {
          console.log("activeRegisteactiveRegisterr", activeRegister, register);
          if (
            activeRegister &&
            register &&
            activeRegister._id == register.register_id
          ) {
            message.success({
              content: "New Incoming Order",
              style: {
                float: "center",
                marginTop: "2vh",
              },
            });
            let oldOrder =
              getItem("QROnlineOrder") != null ? getItem("QROnlineOrder") : 0;
            let data = await dispatch(getAllQROrderList(activeRegister._id));
            if (data && oldOrder != data.kitchenUserIdData.length) {
              setItem("QROnlineOrder", data.kitchenUserIdData.length);
            }
          }
        }
      );
    }
  }, [getItem("qrCode_enable"), activeRegister, qrSocket]);

  useEffect(() => {
    setActiveRegister(registerList?.find((val) => val.active));
  }, [registerList?.find((val) => val.active)?._id]);
  useEffect(() => {
    setPmsActiveRegister(registerList?.find((val) => val.activeHotel));
  }, [registerList?.find((val) => val.activeHotel)?._id]);
  useEffect(async () => {
    if (activeRegister && activeRegister._id) {
      qrSocket?.on(
        `send_subscribe_req/${activeRegister._id}`,
        async (register) => {
          if (
            activeRegister &&
            register &&
            activeRegister._id == register.register_id
          ) {
            await getAllLatestApp();
            if (register.status == "unsubscribed") {
              message.success({
                content: "Your subscription has been expired",
                style: {
                  float: "center",
                  marginTop: "2vh",
                },
              });
            } else {
              message.success({
                content: "Your subscription is active",
                style: {
                  float: "center",
                  marginTop: "2vh",
                },
              });
            }
          }
        }
      );
    }
  }, [activeRegister]);

  useEffect(async () => {
    if (pmsActiveRegister && pmsActiveRegister._id && qrSocket) {
      qrSocket?.on(
        `send_subscribe_req/${pmsActiveRegister._id}`,
        async (register) => {
          console.log(
            "checkPmssadasdasdadadadadadadadasdada",
            pmsActiveRegister
          );
          if (
            pmsActiveRegister &&
            register &&
            pmsActiveRegister._id == register.register_id
          ) {
            await getAllLatestApp();
            if (register.status == "unsubscribed") {
              message.success({
                content: "Your subscription has been expired",
                style: {
                  float: "center",
                  marginTop: "2vh",
                },
              });
            } else {
              message.success({
                content: "Your subscription is active",
                style: {
                  float: "center",
                  marginTop: "2vh",
                },
              });
            }
          }
        }
      );
    }
  }, [pmsActiveRegister]);

  return (
    <ConfigProvider direction={rtl ? "rtl" : "ltr"}>
      <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
        <Router basename={process.env.PUBLIC_URL}>
          {!isLoggedIn ? (
            <Route path="/" component={Auth} />
          ) : userRole === "admin" ? (
            <ProtectedRoute path="/admin" component={Admin} />
          ) : userRole === "cashier" ? (
            <ProtectedRoute path="" component={Cashier} />
          ) : userRole === "app_user" ? (
            <ProtectedRoute path="/app" component={AppRoute} />
          ) : (
            <ProtectedRoute path="" component={Restaurant} />
          )}
          {isLoggedIn &&
            (path === process.env.PUBLIC_URL ||
              path === `${process.env.PUBLIC_URL}/` ||
              path === `${process.env.PUBLIC_URL}/` ||
              path === `${process.env.PUBLIC_URL}/login` ||
              path === `${process.env.PUBLIC_URL}/pin-auth`) &&
            (userRole === "admin" ? (
              <Redirect to="/admin" />
            ) : userRole === "app_user" ? (
              <Redirect to="/app/app/all" />
            ) : userRole === "cashier" ? (
              <Redirect to="/sell" />
            ) : userRole === "restaurant" && !isShopSetUp ? (
              <Redirect to="/settings/shop" />
            ) : (
              <Redirect to="/sell" />
            ))}
        </Router>
      </ThemeProvider>
    </ConfigProvider>
  );
};

function App() {
  return (
    <Provider store={store}>
      <SocketProvider>
        <ProviderConfig />
      </SocketProvider>
    </Provider>
  );
}

export default App;
