import React, { useState, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Checkbox,
  Row,
  Col,
  Input,
  Form,
  Select,
  Tooltip,
  TimePicker,
  Radio,
} from "antd";
import { Cards } from "../../../../../components/cards/frame/cards-frame";
import Heading from "../../../../../components/heading/heading";
import { Button } from "../../../../../components/buttons/buttons";
import "../../setting.css";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleFilled, DeleteOutlined } from "@ant-design/icons";
import {
  addOrUpdateRegister,
  getRegisterById,
  getAllRegisterList,
} from "../../../../../redux/register/actionCreator";
import {
  changeRegisterFooterImage,
  getAllProductList,
} from "../../../../../redux/products/actionCreator";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import _ from "lodash";
import moment from "moment";
import { getItem } from "../../../../../utility/localStorageControl";
import commonFunction from "../../../../../utility/commonFunctions";
import FeatherIcon from "feather-icons-react";
const EditRegister = () => {
  const dispatch = useDispatch();
  const [registerType, setRegisterType] = useState();
  const history = useHistory();
  const [form] = Form.useForm();
  const { Option } = Select;
  const location = useLocation();
  const [RegisterData, setRegisterData] = useState();
  let isMounted = useRef(true);
  const [hotelAllow, setHotelAllow] = useState(false);
  const userDetails = getItem("userDetails");
  const [apiData, setApiData] = useState();
  const [disabledSave, setDisabledSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hotelStartTime, setHotelStartTime] = useState("");
  const [hootelCloseTime, setHotelCloseTime] = useState("");
  const [imgPreview, seImgPreview] = useState("");
  const [image, setImage] = useState("");
  const { hotelRegisterList, registerList } = useSelector((state) => ({
    hotelRegisterList: state.register.RegisterList.filter(
      (val) => val.type == "hotel"
    ),
    registerList: state.register.RegisterList,
  }));
  console.log("registerListregisterListregisterList", registerList);
  useEffect(() => {
    async function fetchRegisterData() {
      if (location.state !== null) {
        const getRegisterData = await dispatch(
          getRegisterById(location.state.register_id)
        );
        if (isMounted.current) setRegisterData(getRegisterData.registerIdData);
      }
    }
    if (isMounted.current) {
      fetchRegisterData();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const RemoveImg = async () => {
    let formdata = new FormData();
    formdata.append("product_img", false);

    const savedProductImg = await dispatch(
      changeRegisterFooterImage(formdata, location?.state?.register_id)
    );
    console.log("savedProductImg", savedProductImg);
    if (savedProductImg && savedProductImg.topProductList) {
      setImage(savedProductImg?.topProductList?.data?.footer_img);
      seImgPreview(savedProductImg?.topProductList?.data?.footer_img);
    }
  };
  useEffect(() => {
    if (RegisterData) {
      // console.log("🚀 ~ file: EditRegister.js:63 ~ useEffect ~ RegisterData:", RegisterData)
      // console.log("🚀 ~ file: EditRegister.js:63 ~ useEffect ~ date::::", moment(RegisterData.hotel_start_time, 'h:mm a'))
      setDisabledSave(true);
      setApiData({
        register_name: RegisterData.register_name,
        receipt_number_prefix: RegisterData.receipt_number_prefix,
        bill_header: RegisterData.bill_header,
        bill_footer: RegisterData.bill_footer,
        printer_type: RegisterData.printer_type,
        print_receipts: RegisterData.print_receipts,
        include_shop_logo: RegisterData.include_shop_logo,
        table_numbers: RegisterData.table_numbers,
        type: RegisterData?.type,
        registration_card_footer: RegisterData.registration_card_footer,
        voucher_footer: RegisterData.voucher_footer,
        booking_notification_email: RegisterData?.booking_notification_email,
      });
      if (RegisterData.hotel_start_time) {
        setHotelStartTime(RegisterData.hotel_start_time);
      }
      if (RegisterData.hotel_end_time) {
        setHotelCloseTime(RegisterData.hotel_end_time);
      }

      RegisterData.footer_img !== "" && RegisterData.footer_img !== undefined
        ? seImgPreview(
            <div className="previewImg">
              <img alt="" src={RegisterData.footer_img} className="hoverImg" />
            </div>
          )
        : "";
      form.setFieldsValue({
        register_name: RegisterData.register_name,
        receipt_number_prefix: RegisterData.receipt_number_prefix,
        bill_header: RegisterData.bill_header,
        bill_footer: RegisterData.bill_footer,
        printer_type: RegisterData.printer_type,
        print_receipts: RegisterData.print_receipts ? true : false,
        include_shop_logo: RegisterData.include_shop_logo ? true : false,
        table_numbers: RegisterData.table_numbers,
        server_ip_address: RegisterData.server_ip_address,
        kds_stale_time: RegisterData.kds_stale_time,
        enable_accept_status: RegisterData.enable_accept_status,
        enable_served_status: RegisterData.enable_served_status,
        allow_to_change_status: RegisterData.allow_to_change_status,
        hotel_end_time: RegisterData.hotel_end_time
          ? moment(RegisterData.hotel_end_time, "h:mm a")
          : "",
        hotel_start_time: RegisterData.hotel_start_time
          ? moment(RegisterData.hotel_start_time, "h:mm a")
          : "",
        allow_hotel: RegisterData.allow_hotel,
        hotel_register: RegisterData?.hotel_register,
        type: RegisterData?.type,
        registration_card_footer: RegisterData.registration_card_footer,
        voucher_footer: RegisterData.voucher_footer,
        booking_notification_email: RegisterData?.booking_notification_email,
      });
      setRegisterType(RegisterData?.type);
      if (RegisterData.allow_hotel) {
        setHotelAllow(true);
      }
    }
  }, [RegisterData]);

  const handleSubmit = async (formData) => {
    setLoading(true);
    localStorage.removeItem("active_cart");

    if (formData.table_numbers) {
      let spiltArrayNumber = formData.table_numbers.split("-");
      let spiltComaNumber = formData.table_numbers.split(",");

      if (spiltArrayNumber[0][1] && spiltArrayNumber[0][1] != ",") {
        spiltArrayNumber = spiltArrayNumber[0][1].split(",");
      }

      if (spiltComaNumber.length > 1) {
        spiltComaNumber = spiltComaNumber;
      }

      let charArray = [];
      let NumericString = [];
      let NumberAndChar = [];
      spiltComaNumber.map((value) => {
        value = value.replace(")", "");
        value = value.replace("(", "");
        if (value.search(/[^a-zA-Z]+/) === -1) {
          charArray.push(value);
        }
      });
      spiltComaNumber.map((value) => {
        value = value.replace(")", "");
        value = value.replace("(", "");
        if (value.match(/^[0-9]+$/)) {
          NumericString.push(value);
        }
      });
      spiltComaNumber.map((value) => {
        value = value.replace(")", "");
        let arry = value.split("(");
        let arry2 = [];
        if (arry.length > 1) {
          arry2 = arry[1].split("-");
        } else {
          arry2 = arry[0].split("-");
        }

        value = value.replace("(", "");

        if (value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])([a-zA-Z0-9-]+)$/)) {
          let countFirst = parseInt(arry2[0].trim());

          let tables = Array.from(
            { length: arry2[1] - (arry2[0] - 1) },
            (_, i) => arry[0] + (i + countFirst)
          );
          NumberAndChar = NumberAndChar.concat(tables);
        }
      });

      let onlyNumber = Array.from(
        { length: spiltArrayNumber[0] },
        (_, i) => i + 1
      );
      formData.table_data = {
        numbers: onlyNumber,
        string: charArray,
        alphanumeric: NumberAndChar,
      };
      if (onlyNumber.length > 1) {
        formData.table_data = {
          numbers: onlyNumber,
          string: charArray,
          alphanumeric: NumberAndChar,
        };
      }
      if (NumericString.length > 0) {
        formData.table_data = {
          numbers: NumericString,
          string: charArray,
          alphanumeric: NumberAndChar,
        };
      }
    } else {
      formData.table_data = {};
      formData.table_numbers = "";
    }
    if (hotelStartTime && hotelStartTime != "") {
      formData["hotel_start_time"] = hotelStartTime;
    }
    if (hootelCloseTime && hootelCloseTime != "") {
      formData["hotel_end_time"] = hootelCloseTime;
    }

    const getAddRegisters = await dispatch(
      addOrUpdateRegister(formData, location.state.register_id)
    );
    if (
      getAddRegisters &&
      getAddRegisters.registerData &&
      !getAddRegisters.error
    ) {
      if (image !== "" && image !== undefined && image !== null) {
        const Form_Data = new FormData();
        Form_Data.append("product_img", image);
        let updateProductsImg = await dispatch(
          changeRegisterFooterImage(
            Form_Data,
            getAddRegisters.registerData?._id
          )
        );
      }
      const registerList = await dispatch(getAllRegisterList());
      if (registerList && registerList.RegisterList) {
        let getProductList = await dispatch(getAllProductList());
        if (getProductList && getProductList.productList) {
          history.push("/settings/registers");
        }
      }
    }
  };

  const handleFormChange = (item, allFileds) => {
    setLoading(false);
    if (apiData) {
      let currentFormData = {};
      _.each(apiData, (val, key) => {
        let findData = allFileds.find((k) => k.name[0] == key);
        if (findData) {
          currentFormData[findData.name[0]] = findData.value;
        }
      });
      if (_.isEqual(apiData, currentFormData)) {
        setDisabledSave(true);
      } else {
        setDisabledSave(false);
      }
      return true;
    }
  };

  function checkBrecketIsClose(expr) {
    const holder = [];
    const openBrackets = ["("];
    const closedBrackets = [")"];
    for (let letter of expr) {
      if (openBrackets.includes(letter)) {
        holder.push(letter);
      } else if (closedBrackets.includes(letter)) {
        const openPair = openBrackets[closedBrackets.indexOf(letter)];
        if (holder[holder.length - 1] === openPair) {
          holder.splice(-1, 1);
        } else {
          holder.push(letter);
          break;
        }
      }
    }
    return holder.length === 0; // return true if length is 0, otherwise false
  }
  function containsEmpty(a) {
    return (
      []
        .concat(a)
        .sort()
        .reverse()
        .pop() === ""
    );
  }
  function checkBracketisBlank(value) {
    if (value.match(/\(.*?\)/g) != null) {
      let arry = value.match(/\(.*?\)/g).map((x) => x.replace(/[()]/g, ""));
      return containsEmpty(arry);
    }
  }
  function checkMinusSign(value) {
    let checkArray = value.split("),");
    if (checkArray.length > 0) {
      let FilterData = checkArray.filter(
        (val) =>
          val.charAt(val.indexOf("(") + 1) == "-" ||
          val.charAt(val.indexOf(")") - 1) == "-"
      );
      return FilterData.length > 0 ? true : false;
    } else {
      return false;
    }
  }
  const checkIsNumber = (value) => {
    if (value != "") {
      let tableNosArray = value.split("),");
      let finalTableArray = [];
      let tableNosName;
      let tableNosRange;
      let splitedTbs;
      let roomArray = [];
      let i;
      tableNosArray.forEach((items) => {
        let inputNumberItem = items[0];
        if (items[0] == 1) {
          if (items.indexOf("-") > -1) {
            tableNosRange = items.split("-");
            tableNosRange[0] = parseInt(tableNosRange[0]);
            tableNosRange[1] = parseInt(tableNosRange[1]);

            if (tableNosRange[0] > tableNosRange[1]) {
              for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
                roomArray.push("Table" + " " + i);
              }
            } else {
              for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
                roomArray.push("Table" + " " + i);
              }
            }
          } else {
            tableNosRange = items.split(",");
            tableNosRange.forEach((items) => {
              roomArray.push("Table" + " " + items);
            });
          }

          i = 1;
          finalTableArray.forEach((item) => {
            if (item.name == "Table") {
              i = 2;
              item.rows = roomArray;
            }
          });
          if (i == 1) {
            finalTableArray.push({
              name: "Table",
              status: "Empty",
              rows: roomArray,
            });
          }
        } else if (isNaN(inputNumberItem) && items && items.indexOf("-") > -1) {
          splitedTbs = items.split("(");

          tableNosName = splitedTbs[0];
          tableNosRange = splitedTbs[1];
          let roomCharArray = [];

          tableNosRange = tableNosRange.replace(")", "");

          tableNosRange = tableNosRange.split("-");
          tableNosRange[0] = parseInt(tableNosRange[0]);
          tableNosRange[1] = parseInt(tableNosRange[1]);

          if (tableNosRange[0] > tableNosRange[1]) {
            for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
              roomCharArray.push("Table" + " " + i);
            }
          } else {
            for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
              roomCharArray.push(tableNosName + " " + i);
            }
          }

          finalTableArray.push({
            name: tableNosName,
            status: "Empty",
            rows: roomCharArray,
          });
        } else if (items && items.indexOf(",") > -1) {
          let tempTables = items.split("(");
          tableNosName = tempTables[0];
          tableNosRange = tempTables[1];
          tableNosRange = tableNosRange.replace(")", "");
          tableNosRange = tableNosRange.split(",");
          let roomCharArray = [];
          tableNosRange.forEach((items) => {
            roomCharArray.push(tableNosName + " " + items);
          });
          finalTableArray.push({
            name: tableNosName,
            status: "Empty",
            rows: roomCharArray,
          });
        } else {
          if (items.indexOf("-") > -1) {
            tableNosRange = items.split("-");
            tableNosRange[0] = parseInt(tableNosRange[0]);
            tableNosRange[1] = parseInt(tableNosRange[1]);

            if (tableNosRange[0] > tableNosRange[1]) {
              for (i = tableNosRange[1]; i <= tableNosRange[0]; i++) {
                roomArray.push("Table" + " " + i);
              }
            } else {
              for (i = tableNosRange[0]; i <= tableNosRange[1]; i++) {
                roomArray.push("Table" + " " + i);
              }
            }
          } else {
            let tempTables = items.split("(");
            tableNosName = tempTables[0];
            tableNosRange = items.split(",");

            tableNosRange.forEach((items) => {
              tempTables[1].indexOf(")") > -1
                ? finalTableArray.push({
                    name: tableNosName,
                    status: "Empty",
                    rows: [tableNosName + tempTables[1].slice(0, -1)],
                  })
                : finalTableArray.push({
                    name: tableNosName,
                    status: "Empty",
                    rows: [tableNosName + tempTables[1]],
                  });
            });
          }
          i = 1;
          finalTableArray.forEach((item) => {
            if (item.name == "Table") {
              i = 2;
              item.rows = roomArray;
            }
          });
        }
      });

      let filterA = finalTableArray.filter((val) => val.rows.length == 0);

      if (filterA.length > 0) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkFirstNumber = (value) => {
    let array = value.split("),");

    if (array.length > 0) {
      let A;
      let B;
      array.map((k) => {
        if (k.indexOf("-") > -1) {
          let splitArray = k.split("-");
          splitArray.map((j) => {
            if (j.indexOf(")") > -1) {
              if (j.split(")")[0]) {
                B = parseInt(j.split(")")[0]);
              }
            } else {
              if (j.split("(")[1]) {
                A = parseInt(j.split("(")[1]);
              }
            }
          });
        }
      });

      if (A && B && A >= B) {
        return true;
      } else if (A && B && A < B) {
        return false;
      }
    }
  };
  const changeHandler = (e) => {
    setImage(e.target.files[0]);
    seImgPreview(
      <div className="previewImg">
        <img
          crossOrigin="anonymous"
          alt=""
          src={URL.createObjectURL(e.target.files[0])}
          className="hoverImg"
        />
      </div>
    );
  };
  return (
    <>
      <Cards
        marginTop={true}
        title={
          <div className="setting-card-title">
            <Heading as="h4">Your Register Details</Heading>
            <span>
              Enable receipt printing to print receipts while billing with this
              register.
            </span>
            <span>
              By default, The shop name will be printed on the receipt.
            </span>
          </div>
        }
      >
        <Row gutter={25} justify="center">
          <Col xxl={12} md={14} sm={18} xs={24}>
            <Form
              autoComplete="off"
              style={{ width: "100%" }}
              form={form}
              name="add Register"
              onFieldsChange={(val, allFileds) =>
                handleFormChange(val, allFileds)
              }
              onFinish={handleSubmit}
            >
              <Form.Item
                name="register_name"
                label="Register Name"
                rules={[
                  {
                    required: true,
                    message: "Register name is required",
                  },
                ]}
              >
                <Input
                  style={{ marginBottom: 10 }}
                  placeholder="Register Name"
                />
              </Form.Item>
              {/* <Form.Item name="type" label="Register type">
                <Radio.Group onChange={(e) => setRegisterType(e.target.value)}>
                  <Radio value={"hotel"}>Hotel</Radio>
                  <Radio value={"restaurant"}>Restaurant</Radio>
                </Radio.Group>
              </Form.Item> */}
              <Form.Item
                name="receipt_number_prefix"
                label={
                  <span>
                    Receipt Number Prefix &nbsp;&nbsp;
                    <Tooltip
                      title="Two letter prefix code for recepit number E.G Prefix AB Will generate receipt numbers like AB1,AB2 etc"
                      color="#FFFF"
                    >
                      <InfoCircleFilled
                        style={{
                          color: "#AD005A",
                          paddingLeft: "12px !important",
                        }}
                      />
                    </Tooltip>
                  </span>
                }
                rules={[
                  {
                    message: "Register prefix is required",
                    required: true,
                  },
                  {
                    max: 2,
                    message:
                      "Register prefix cannot be more than 2 characters long.",
                  },
                ]}
              >
                <Input
                  style={{ marginBottom: 10 }}
                  placeholder="Receipt Number Prefix"
                />
              </Form.Item>
              <Form.Item
                name="bill_header"
                label={
                  <span>
                    Bill Header &nbsp;&nbsp;
                    <Tooltip
                      title="The bill header will be printed at the top of the receipt and can be used to add your shop detail like address phone & tax numbers"
                      color="#FFFF"
                    >
                      <InfoCircleFilled
                        style={{
                          color: "#AD005A",
                          paddingLeft: "12px !important",
                        }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <TextArea
                  style={{ marginBottom: 10 }}
                  placeholder="Bill header content (optional)"
                />
              </Form.Item>
              <Form.Item
                name="bill_footer"
                label={
                  <span>
                    Bill Footer &nbsp;&nbsp;
                    <Tooltip
                      title="The bill footer will be printed at the bottom of the receipt and can be used to add details like terms and condition"
                      color="#FFFF"
                    >
                      <InfoCircleFilled
                        style={{
                          color: "#AD005A",
                          paddingLeft: "12px !important",
                        }}
                      />
                    </Tooltip>
                  </span>
                }
              >
                <TextArea
                  style={{ marginBottom: 10 }}
                  placeholder="Bill footer content (optional)"
                />
              </Form.Item>
              <p>
                Bill Footer image{" "}
                <Tooltip
                  title="The bill footer image will be printed at the bottom of the receipt and can be used to add a payment QR code or other relevant information."
                  color="#FFFF"
                >
                  <InfoCircleFilled
                    style={{
                      color: "#AD005A",
                      paddingLeft: "12px !important",
                    }}
                  />
                </Tooltip>
              </p>
              <div className="image-Container">
                <Form.Item
                  name="footer_img"
                  className="imgFormItem"
                  label={
                    <div className="productImgContainer">
                      {Object.keys(imgPreview).length !== 0 ? (
                        imgPreview
                      ) : (
                        <div className="previewImgContainer">
                          <img
                            alt=""
                            src={require("../../../../../static/img/products/6.png")}
                          />
                        </div>
                      )}

                      <div className="editImg">
                        <FeatherIcon icon="edit" size={35} color="white" />
                      </div>
                    </div>
                  }
                >
                  <Input
                    size="small"
                    type="file"
                    onChange={changeHandler}
                    style={{ display: "none" }}
                  ></Input>
                </Form.Item>
                {Object.keys(imgPreview).length !== 0 ? (
                  <DeleteOutlined
                    className="delete_productImg"
                    size="25px"
                    onClick={() => RemoveImg()}
                  />
                ) : (
                  ""
                )}
              </div>
              {registerType == "hotel" && (
                <div>
                  <Form.Item
                    name="registration_card_footer"
                    label={
                      <span>
                        Registration Card Footer &nbsp;&nbsp;
                        <Tooltip
                          title="The  registration Card footer will be printed at the bottom of the receipt and can be used to add details like terms and condition"
                          color="#FFFF"
                        >
                          <InfoCircleFilled
                            style={{
                              color: "#AD005A",
                              paddingLeft: "12px !important",
                            }}
                          />
                        </Tooltip>
                      </span>
                    }
                  >
                    <TextArea
                      style={{ marginBottom: 10 }}
                      placeholder="Registration Card footer content (optional)"
                    />
                  </Form.Item>
                  <Form.Item
                    name="voucher_footer"
                    label={
                      <span>
                        Voucher Footer &nbsp;&nbsp;
                        <Tooltip
                          title="The Voucher footer will be printed at the bottom of the receipt and can be used to add details like terms and condition"
                          color="#FFFF"
                        >
                          <InfoCircleFilled
                            style={{
                              color: "#AD005A",
                              paddingLeft: "12px !important",
                            }}
                          />
                        </Tooltip>
                      </span>
                    }
                  >
                    <TextArea
                      style={{ marginBottom: 10 }}
                      placeholder="Voucher footer content (optional)"
                    />
                  </Form.Item>
                  <Form.Item
                    name="booking_notification_email"
                    label="Booking Notification Email"
                    rules={[
                      {
                        type: "email",
                        message: "Invalid Email Address",
                      },
                    ]}
                  >
                    <Input
                      style={{ marginBottom: 10 }}
                      placeholder="Set Booking Notification Email"
                    />
                  </Form.Item>
                </div>
              )}

              {registerType != "hotel" && (
                <Form.Item
                  name="printer_type"
                  label="Printer Type (for PosEase Web)"
                >
                  <Select style={{ width: "100%", marginBottom: 10 }}>
                    <Option value="80mm">3 inch recepit (80mm)</Option>
                    <Option value="58mm">2 inch receipt (58mm)</Option>
                    <Option value="A4">A4 size</Option>
                    <Option value="A5">A5 size</Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item name="print_receipts" valuePropName="checked">
                {registerType == "hotel" ? (
                  <Checkbox
                    className="add-form-check"
                    style={{ marginTop: 10 }}
                  >
                    Print receipts (for PosEase Web){" "}
                  </Checkbox>
                ) : (
                  <Checkbox
                    className="add-form-check"
                    style={{ marginTop: 10 }}
                  >
                    Print receipts and order tickets (for PosEase Web){" "}
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item name="include_shop_logo" valuePropName="checked">
                <Checkbox className="add-form-check">
                  Include shop logo in printed receipts (for PosEase Web){" "}
                </Checkbox>
              </Form.Item>
              {registerType != "hotel" &&
                userDetails?.business_type &&
                userDetails?.business_type === "hotel_management" && (
                  <Form.Item name="allow_hotel" valuePropName="checked">
                    <Checkbox
                      className="add-form-check"
                      style={{ marginBottom: 10 }}
                      onChange={(e) => setHotelAllow(e.target.checked)}
                    >
                      Allow PMS register
                    </Checkbox>
                  </Form.Item>
                )}

              {hotelAllow && (
                <Form.Item
                  name="hotel_register"
                  label="PMS Register"
                  rules={[
                    {
                      message: "PMS Register",
                      required: hotelAllow ? true : false,
                    },
                  ]}
                >
                  <Select
                    style={{ width: "100%", marginBottom: 10 }}
                    placeholder="Select PMS Register"
                  >
                    {hotelRegisterList.map((val) => {
                      console.log("registerList", registerList);
                      let findAllReadyAssignOrNot = registerList.find(
                        (item) =>
                          item.hotel_register === val._id && val.allow_hotel
                      );
                      if (!findAllReadyAssignOrNot) {
                        return (
                          <Option value={val._id}>{val.register_name}</Option>
                        );
                      }
                    })}
                  </Select>
                </Form.Item>
              )}

              {getItem("qrCode_enable") && (
                <div>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 12px)",
                    }}
                    label="Outlet Open Time"
                    name="hotel_start_time"
                  >
                    {/* <p>{hotelStartTime}</p> */}
                    <TimePicker
                      className="book_picker"
                      use12Hours
                      format="h:mm A"
                      size="large"
                      hotelStartTime
                      onChange={(time, timeString) => {
                        setHotelStartTime(timeString);
                      }}
                    />
                  </Form.Item>
                  <span
                    style={{
                      display: "inline-block",
                      width: "24px",
                      lineHeight: "32px",
                      textAlign: "center",
                    }}
                  ></span>
                  <Form.Item
                    style={{
                      display: "inline-block",
                      width: "calc(50% - 12px)",
                    }}
                    label="Outlet Close Time"
                    name="hotel_end_time"
                  >
                    {/* <p>{hootelCloseTime}</p> */}
                    <TimePicker
                      className="book_picker"
                      use12Hours
                      format="h:mm A"
                      hootelCloseTime
                      size="large"
                      onChange={(time, timeString) =>
                        setHotelCloseTime(timeString)
                      }
                    />
                  </Form.Item>
                </div>
              )}
              {registerType != "hotel" && (
                <Form.Item
                  name="table_numbers"
                  rules={[
                    {
                      validator: (_, value) => {
                        let tableName = value
                          .split(",")
                          .map((val) => val.split("(")[0]);

                        if (value.split(") ").length > 1) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (
                          tableName.filter(
                            (item, index) => tableName.indexOf(item) !== index
                          ).length > 0
                        ) {
                          return Promise.reject(
                            "Same categories name not allowed"
                          );
                        } else if (
                          (tableName.filter((val) => val == val.trim())
                            .length ==
                            tableName.length) ==
                          false
                        ) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (checkBrecketIsClose(value) == false) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (
                          checkBracketisBlank(value) != undefined &&
                          checkBracketisBlank(value)
                        ) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (value.charAt(value.length - 1) == ",") {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (
                          value.split("),").length > 1 &&
                          value
                            .split("),")
                            .filter(
                              (val) =>
                                !val.includes("-") &&
                                !val.includes(")") &&
                                !val.includes("(")
                            ).length > 0
                        ) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (checkMinusSign(value)) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (checkIsNumber(value)) {
                          return Promise.reject(
                            "Table numbers are invalid, specify a range or a set of comma separated values."
                          );
                        } else if (checkFirstNumber(value)) {
                          return Promise.reject("Number should be greater");
                        } else {
                          return Promise.resolve();
                        }
                      },
                    },
                  ]}
                  label={
                    <span>
                      Table Numbers &nbsp;&nbsp;
                      <Tooltip
                        title="Provide table numbers either as a range eg:1-6,or as comman seprated values e.g G1,G2,G3,U1,U2,U3 if this field is set you will be able to manage table orders ,take aways and deliveries from the Sell page"
                        color="#FFFF"
                      >
                        <InfoCircleFilled
                          style={{
                            color: "#AD005A",
                            paddingLeft: "12px !important",
                          }}
                        />
                      </Tooltip>
                    </span>
                  }
                >
                  <TextArea
                    style={{ marginBottom: 10 }}
                    onChange={(e) => {
                      let tableNosArray = e.target.value.split("),");

                      let finalTableArray = [];
                      let tableNosName;
                      let tableNosRange;
                      let splitedTbs;
                      let roomArray = [];
                      let i;
                      tableNosArray.forEach((items) => {
                        let inputNumberItem = items[0];
                        if (items[0] == 1) {
                          if (items.indexOf("-") > -1) {
                            tableNosRange = items.split("-");
                            tableNosRange[0] = parseInt(tableNosRange[0]);
                            tableNosRange[1] = parseInt(tableNosRange[1]);

                            if (tableNosRange[0] > tableNosRange[1]) {
                              for (
                                i = tableNosRange[1];
                                i <= tableNosRange[0];
                                i++
                              ) {
                                roomArray.push("Table" + " " + i);
                              }
                            } else {
                              for (
                                i = tableNosRange[0];
                                i <= tableNosRange[1];
                                i++
                              ) {
                                roomArray.push("Table" + " " + i);
                              }
                            }
                          } else {
                            tableNosRange = items.split(",");
                            tableNosRange.forEach((items) => {
                              roomArray.push("Table" + " " + items);
                            });
                          }

                          i = 1;
                          finalTableArray.forEach((item) => {
                            if (item.name == "Table") {
                              i = 2;
                              item.rows = roomArray;
                            }
                          });
                          if (i == 1) {
                            finalTableArray.push({
                              name: "Table",
                              status: "Empty",
                              rows: roomArray,
                            });
                          }
                        } else if (
                          isNaN(inputNumberItem) &&
                          items &&
                          items.indexOf("-") > -1
                        ) {
                          splitedTbs = items.split("(");
                          tableNosName = splitedTbs[0];
                          tableNosRange = splitedTbs[1];
                          let roomCharArray = [];
                          tableNosRange = tableNosRange.replace(")", "");
                          tableNosRange = tableNosRange.split("-");
                          tableNosRange[0] = parseInt(tableNosRange[0]);
                          tableNosRange[1] = parseInt(tableNosRange[1]);

                          if (tableNosRange[0] > tableNosRange[1]) {
                            for (
                              i = tableNosRange[1];
                              i <= tableNosRange[0];
                              i++
                            ) {
                              roomCharArray.push("Table" + " " + i);
                            }
                          } else {
                            for (
                              i = tableNosRange[0];
                              i <= tableNosRange[1];
                              i++
                            ) {
                              roomCharArray.push("Table" + " " + i);
                            }
                          }

                          finalTableArray.push({
                            name: tableNosName,
                            status: "Empty",
                            rows: roomCharArray,
                          });
                        } else if (items && items.indexOf(",") > -1) {
                          let tempTables = items.split("(");
                          tableNosName = tempTables[0];
                          tableNosRange = tempTables[1];
                          tableNosRange = tableNosRange.replace(")", "");
                          tableNosRange = tableNosRange.split(",");
                          let roomCharArray = [];
                          tableNosRange.forEach((items) => {
                            roomCharArray.push("Table" + " " + items);
                          });

                          finalTableArray.push({
                            name: tableNosName,
                            status: "Empty",
                            rows: roomCharArray,
                          });
                        } else {
                          if (items.indexOf("-") > -1) {
                            tableNosRange = items.split("-");
                            tableNosRange[0] = parseInt(tableNosRange[0]);
                            tableNosRange[1] = parseInt(tableNosRange[1]);

                            if (tableNosRange[0] > tableNosRange[1]) {
                              for (
                                i = tableNosRange[1];
                                i <= tableNosRange[0];
                                i++
                              ) {
                                roomArray.push("Table" + " " + i);
                              }
                            } else {
                              for (
                                i = tableNosRange[0];
                                i <= tableNosRange[1];
                                i++
                              ) {
                                roomArray.push("Table" + " " + i);
                              }
                            }
                          } else {
                            tableNosRange = items.split(",");

                            tableNosRange.forEach((items) => {
                              roomArray.push("Table" + " " + items);
                            });
                          }

                          i = 1;
                          finalTableArray.forEach((item) => {
                            if (item.name == "Table") {
                              i = 2;
                              item.rows = roomArray;
                            }
                          });
                          if (i == 1) {
                            finalTableArray.push({
                              name: "Table",
                              status: "Empty",
                              rows: roomArray,
                            });
                          }
                        }
                      });
                    }}
                  />
                </Form.Item>
              )}

              <Form.Item style={{ float: "right" }}>
                <Button
                  className="go-back-button"
                  size="small"
                  type="white"
                  style={{ marginRight: "10px" }}
                  onClick={() => history.push("/settings/registers")}
                >
                  Go Back
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  // disabled={disabledSave}
                >
                  {loading ? (
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{
                            fontSize: 16,
                            color: "white",
                            margin: "0px 8px",
                          }}
                          spin
                        />
                      }
                    />
                  ) : (
                    "Save"
                  )}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Cards>
    </>
  );
};

export { EditRegister };
