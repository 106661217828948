import React, { useState, lazy, Suspense } from "react";
import { Row, Col, Skeleton } from "antd";
import FeatherIcon from "feather-icons-react";
import CalenDar from "react-calendar";
import { Link, Switch, Route, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Aside, CalendarWrapper } from "./Style";
import { Main } from "../../container/styled";
import { Button } from "../../components/buttons/buttons";
import { Cards } from "../../components/cards/frame/cards-frame";
import { PageHeader } from "../../components/page-headers/page-headers";
import "react-calendar/dist/Calendar.css";
import { eventVisible } from "../../redux/calendar/actionCreator";
import "./style.css";
import { CalendarButtonPageHeader } from "../buttons/calendar-button/calendar-button";
const YearCalendar = lazy(() => import("./overview/Year"));
const MonthCalendar = lazy(() => import("./overview/Month"));
const WeekCalendar = lazy(() => import("./overview/Week"));
const DayCalendar = lazy(() => import("./overview/Day"));
const TodayCalendar = lazy(() => import("./overview/Today"));
const ScheduleCalendar = lazy(() => import("./overview/Schedule"));

const Calendars = (props) => {
  let {
    tabChangeToCurrentFunction,
    type,
    roomTypeList,
    currentRegisterData,
    setReservationDetails,
    setActiveReservationTab,
    setNewReservation,
  } = props;

  const dispatch = useDispatch();
  const [category, setCategory] = useState(
    type == "rate" || type == "calendar" ? "week" : "month"
  );
  const { events, isVisible } = useSelector((state) => {
    return {
      events: state.Calender.events,
      isVisible: state.Calender.eventVisible,
    };
  });

  const { path } = useRouteMatch();
  const [state, setState] = useState({
    date: new Date(),
    visible: false,
  });

  const onChange = (date) => setState({ date });

  const onHandleVisible = () => {
    dispatch(eventVisible(!isVisible));
  };

  const redirectToCurrentFunc = (tab, data) => {
    tabChangeToCurrentFunction(tab, data);
  };

  return (
    <>
      {type != "rate" && type != "calendar" && (
        <PageHeader
          ghost
          title="Reservation"
          buttons={[
            <div key="1" className="page-header-actions">
              {/* <CalendarButtonPageHeader /> */}
              {/* <ExportButtonPageHeader />
              <ShareButtonPageHeader /> */}
              <ul className="calendarType">
                <li
                  key="day"
                  className={category === "day" ? "Active" : "not-Active"}
                  onClick={setCategory.bind(this, "day")}
                >
                  Day
                </li>
                <li
                  key="week"
                  className={category === "week" ? "Active" : "not-Active"}
                  onClick={setCategory.bind(this, "week")}
                >
                  Week
                </li>
                <li
                  key="month"
                  className={category === "month" ? "Active" : "not-Active"}
                  onClick={setCategory.bind(this, "month")}
                >
                  Month
                </li>
                {/* <li
                  key="year"
                  className={category === "year" ? "Active" : "not-Active"}
                  onClick={setCategory.bind(this, "year")}
                >
                  Year
                </li> */}
              </ul>
              <Button size="small" type="primary">
                <FeatherIcon icon="plus" size={14} />
                Add New Reservation
              </Button>
            </div>,
          ]}
        />
      )}

      <CalendarWrapper>
        <Row gutter={25}>
          <Col span={24}>
            {category === "year" ? (
              <YearCalendar />
            ) : category === "month" ? (
              <MonthCalendar
                redirectToCurrentFunction={redirectToCurrentFunc}
              />
            ) : category === "week" ? (
              <WeekCalendar
                type={type}
                roomTypeList={roomTypeList}
                currentRegisterData={currentRegisterData}
                setNewReservation={setNewReservation}
                setReservationDetails={setReservationDetails}
                tabChangeToCurrentFunction={tabChangeToCurrentFunction}
                setActiveReservationTab={setActiveReservationTab}
              />
            ) : category === "day" ? (
              <TodayCalendar />
            ) : (
              <MonthCalendar />
            )}
          </Col>
        </Row>
      </CalendarWrapper>
    </>
  );
};

export default Calendars;
