import { legacy_createStore } from "redux";
import { getItem, setItem } from "../../utility/localStorageControl";
import moment from "moment";
import { message } from "antd";
import { getAllRegisterList } from "../register/actionCreator";
import axios from "axios";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");

const addOrUpdateReseration = (formData, _id) => {
  return async (dispatch) => {
    try {
      let getReservation = {};
      if (_id) {
        getReservation = await DataService.put(
          `${API.reservation.addReservation}/${_id}`,
          formData
        );
        if (!getReservation.data.error) {
          return getReservation.data;
        } else {
          return getReservation.data;
        }
      } else {
        getReservation = await DataService.post(
          API.reservation.addReservation,
          formData
        );
      }
      if (!getReservation.data.error) {
        console.log("Sagarasasasasasasasasasasasa", getReservation.data.data);

        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getReservationList = (registerId, status, dateOfStart, endOfStart) => {
  return async (dispatch) => {
    try {
      let url = API.reservation.getAllReservation;
      let startDate = moment().format("YYYY-MM-DD");
      if (registerId) {
        url = `${API.reservation.getAllReservation
          }?registerId=${registerId}&startDate=${dateOfStart ? dateOfStart : startDate
          }&endDate=${endOfStart ? endOfStart : startDate}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone
          }&status=${status}`;
      }
      let getReservationlist = await DataService.get(url);

      if (!getReservationlist.data.error) {
        return getReservationlist.data;
      } else {
        return getReservationlist.data;
      }
    } catch (err) {
      console.log("errerrerrerrerrerrerrerrerrerrerrerrerr", err);
      if (err.response) {
        // Error from the server
        console.log("Server responded with status:", err.response.status);
        console.log("Server error message:", err.response.data.message);
        return false;
        return {
          error: true,
          message:
            err.response.data.message || "An error occurred. Please try again.",
        };
      } else if (err.request) {
        // Error related to the request
        console.log("Request error:", err.request);
        return false;
        return {
          error: true,
          message: "Request error. Please check your network connection.",
        };
      } else {
        // Other errors
        console.log("General error:", err.message);
        return false;
        return {
          error: true,
          message: "An error occurred. Please try again.",
        };
      }
    }
  };
};

const getRoomReservation = (
  registerId,
  dateOfStart,
  endOfStart,
  roomInfoId
) => {
  return async (dispatch) => {
    try {
      let url = API.reservation.roomReservation;
      let startDate = moment().format("YYYY-MM-DD");
      if (registerId) {
        url = `${API.reservation.roomReservation
          }?registerId=${registerId}&startDate=${dateOfStart ? dateOfStart : startDate
          }&endDate=${endOfStart ? endOfStart : startDate
          }&roomInfoId=${roomInfoId}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone
          }`;
      }
      let getReservationlist = await DataService.get(url);

      if (!getReservationlist.data.error) {
        return getReservationlist.data;
      } else {
        return getReservationlist.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getSingleReservation = (id) => {
  return async (dispatch) => {
    try {
      let getReservationlist = await DataService.get(
        `${API.reservation.getById}/${id}`
      );

      if (!getReservationlist.data.error) {
        return getReservationlist.data;
      } else {
        return getReservationlist.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const reservationCheckInCheckOut = (formData, id) => {
  return async (dispatch) => {
    let userInfo = getItem("userDetails");
    if (userInfo && userInfo.restaurant_user_id) {
      formData["user_id"] = userInfo.restaurant_user_id;
    }

    try {
      let getReservationData;
      if (
        formData.status == "undoCheckOut" ||
        formData.status == "checkOut" ||
        formData.status == "payment" ||
        formData.status == "item"
      ) {
        getReservationData = await DataService.post(
          `${API.reservation.checkOut}/${id}`,
          formData
        );
      } else {
        getReservationData = await DataService.post(
          `${API.reservation.checkIn}/${id}`,
          formData
        );
      }

      if (!getReservationData.data.error) {
        return getReservationData.data;
      } else {
        return getReservationData.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const addBookingInChannel = (payload) => {
  return async (dispatch) => {
    try {
      let token = payload?.ota_token;
      let localProviderCode = "JaTkrvpsR0f0T8MUnK70";
      let providerCode = "ggdyFUXCfUzJj0DNjPDE";
      delete payload?.ota_token;
      let response = await axios.post(
        "https://www.eglobe-solutions.com/webapichannelmanager/extpms/bookings/notif",
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            ProviderCode: providerCode,
          },
        }
      );

      return response;
    } catch (err) {
      if (err.response) {
        message.error(err?.response?.data)
        if (err.response.data) {
          console.log("API Error Message:", err.response.data);
        }
      } else {
        message.error(err.message)

      }
      return false;
    }
  };
};

const addOrDeleteInvoice = (formData, id) => {
  return async (dispatch) => {
    try {
      let userInfo = getItem("userDetails");
      if (userInfo && userInfo.restaurant_user_id) {
        formData["invoiceDetails"]["invoiceUserName"] = userInfo.username;
      }
      let addInvoice = await DataService.post(
        API.reservation.addInvoice,
        formData
      );
      if (!addInvoice.data.error) {
        return addInvoice.data;
      } else {
        return addInvoice.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const addUpdateNotes = (formData, id) => {
  return async (dispatch) => {
    try {
      let userInfo = getItem("userDetails");
      if (userInfo && userInfo.restaurant_user_id) {
        formData["notesDetails"]["notesUserName"] = userInfo.username;
      }
      if (id) {
        formData["notesDetails"]["_id"] = id;
      }
      let addNotes = await DataService.post(
        id ? API.reservation.updateNotes : API.reservation.addNotes,
        formData
      );
      if (!addNotes.data.error) {
        return addNotes.data;
      } else {
        return addNotes.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const createAllRoomInvoice = (formData) => {
  return async (dispatch) => {
    try {
      let userInfo = getItem("userDetails");
      if (userInfo && userInfo.restaurant_user_id) {
        formData["user_id"] = userInfo.restaurant_user_id;
      }
      let formData1 = new FormData();
      const images = formData?.customer?.doc_images ?? [];

      if (images.length > 0) {
        for (const [index, oneImage] of images.entries()) {
          if (oneImage.originFileObj) {
            formData1.append("files", oneImage.originFileObj);
            images.splice(index, 1);
          }
        }
      }
      if (formData?.customer?.doc_images) {
        formData.customer.doc_images = images;
      }
      Object.keys(formData).forEach((key) => {
        formData1.append(key, JSON.stringify(formData[key]));
      });
      let invoiceData = await DataService.post(
        API.reservation.reservationComplated,
        formData1
      );

      if (!invoiceData.data.error) {
        return invoiceData.data;
      } else {
        return invoiceData.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const sendConfimationLater = (formData, reservationId) => {
  return async (dispatch) => {
    try {
      let invoiceData = await DataService.post(
        `${API.reservation.sendConfimationLater}/${reservationId}`,
        formData
      );
      if (!invoiceData.data.error) {
        return invoiceData.data;
      } else {
        return invoiceData.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getAllRoomReceipts = (reservationObjectId, registerId, searchObj) => {
  return async (dispatch) => {
    let startDate = searchObj?.startDate
      ? searchObj?.startDate
      : moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    let endDate = searchObj?.endDate
      ? searchObj?.endDate
      : moment().format("YYYY-MM-DD");
    try {
      let url = reservationObjectId
        ? `${API.reservation.receipts}?&reservationObjectId=${reservationObjectId}`
        : registerId
          ? `${API.reservation.receipts}?registerId=${registerId}&page=${searchObj.currentPage
          }&limit=${10}&timeZon=${Intl.DateTimeFormat().resolvedOptions().timeZone
          }&startDate=${startDate}&endDate=${endDate}&receiptNum=${searchObj.receiptNum
          }`
          : API.reservation.receipts;

      let getReceipt = await DataService.get(url);

      if (!getReceipt.data.error) {
        return getReceipt.data;
      } else {
        return getReceipt.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getRoomReceiptsById = (id) => {
  return async (dispatch) => {
    try {
      const ReceiptsByIdData = await DataService.get(
        `${API.reservation.getRoomReceipt}/${id}`
      );

      if (!ReceiptsByIdData.data.error) {
        return ReceiptsByIdData.data.data;
      } else {
        return ReceiptsByIdData.data;
      }
    } catch (err) {
      dispatch(ReceiptsIdErr(err));
    }
  };
};
const roomIdByUpdate = (formData) => {
  return async (dispatch) => {
    try {
      let getReservation = await DataService.put(
        API.reservation.roomIdByUpdate,
        formData
      );

      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const customerAddUpdate = (formData) => {
  return async (dispatch) => {
    try {
      let formData1 = new FormData();
      const images = formData.roomDetails?.customer?.doc_images ?? [];

      if (images.length > 0) {
        for (const [index, oneImage] of images.entries()) {
          if (oneImage.originFileObj) {
            formData1.append("files", oneImage.originFileObj);
            images.splice(index, 1);
          }
        }
      }
      if (formData.roomDetails?.customer) {
        formData.roomDetails.customer.doc_images = images;
      }
      Object.keys(formData).forEach((key) => {
        formData1.append(key, JSON.stringify(formData[key]));
      });
      let getReservation = await DataService.put(
        API.reservation.customer,
        formData1
      );
      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const addAddtionalExtraRoom = (formData) => {
  return async (dispatch) => {
    try {
      let getReservation = await DataService.put(
        API.reservation.addtionalRoom,
        formData
      );

      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const customerFilter = (formData) => {
  return async (dispatch) => {
    try {
      let getReservation = await DataService.post(
        API.reservation.customerFilter,
        formData
      );

      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getRoomDashBoardData = (userDetails) => {
  return async (dispatch) => {
    try {
      let id =
        userDetails.role == "cashier"
          ? userDetails.hotel_register_assigned_to
          : "allRegister";
      let startDate = moment().format("YYYY-MM-DD");
      let getReservation = await DataService.get(
        `${API.reservation.dashboard}?startDate=${startDate}&register_id=${id}`
      );
      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const getRevenueDashboard = (type, id, pmsCasierRegisterId) => {
  return async (dispatch) => {
    try {
      let startDate;
      let endDate;
      if (pmsCasierRegisterId) {
        if (pmsCasierRegisterId != "allRegister") {
          id = pmsCasierRegisterId;
        }
      }
      if (type == "today") {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      } else if (type == "yesterday") {
        console.log("yesterday");
        startDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD");
      } else if (type == "this_month") {
        startDate = moment()
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (type == "last_month") {
        startDate = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        endDate = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else {
        startDate = moment().format("YYYY-MM-DD");
        endDate = moment().format("YYYY-MM-DD");
      }

      let getReservation = await DataService.get(
        `${API.reservation.revenueDashBoard}?startDate=${startDate}&endDate=${endDate}&register_id=${id}`
      );
      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getAvailability = (date, id) => {
  return async (dispatch) => {
    try {
      let startDate = moment(date, "MMM DD, Y").format("YYYY-MM-DD");
      let url = `${API.reservation.getAvailability}?startDate=${startDate}&endDate=${startDate}&register_id=${id}`;
      let getReservation = await DataService.get(url);
      if (!getReservation.data.error) {
        return getReservation.data;
      } else {
        return getReservation.data;
      }
    } catch (err) {
      return false;
    }
  };
};

export {
  customerAddUpdate,
  addOrUpdateReseration,
  getReservationList,
  reservationCheckInCheckOut,
  createAllRoomInvoice,
  getAllRoomReceipts,
  roomIdByUpdate,
  addUpdateNotes,
  addOrDeleteInvoice,
  customerFilter,
  addAddtionalExtraRoom,
  getSingleReservation,
  getRoomReceiptsById,
  getRoomDashBoardData,
  getRevenueDashboard,
  getAvailability,
  getRoomReservation,
  addBookingInChannel,
  sendConfimationLater,
};
