import axios from "axios";
import { getItem, setItem } from "../../utility/localStorageControl";
import { getAllRoomList } from "../room/actionCreator";
import actions from "./actions";
import { message } from "antd";
const { DataService } = require("../../config/dataService/dataService");
const { API } = require("../../config/api/index");
const {
  roomTypeAdd,
  roomTypeAddErr,
  roomTypeList,
  roomTypeListErr,
  roomTypeId,
  roomTypeIdErr,
  roomTypeDelete,
  roomTypeDeleteErr,
} = actions;

const getAllroomTypeList = (registerId, checksell) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");

      if (checksell == "sell" && allSetupcache?.roomType) {
        let filter = allSetupcache?.roomType.filter(
          (val) => val.register_id == registerId
        );
        return {
          data: filter,
          error: false,
          message: "Success",
          messageCode: "ROOM_TYPE_GET",
          statusCode: 200,
        };
      } else {
        let url = API.roomType.getAllroomType;
        const getroomTypeList = await DataService.get(url);
        if (!getroomTypeList.data.error) {
          allSetupcache.roomType = getroomTypeList.data.data;
          setItem("setupCache", allSetupcache);
          let filter = getroomTypeList.data.data.filter(
            (val) => val.register_id == registerId
          );
          await dispatch(getAllRoomList(registerId));
          return {
            data: filter,
            error: false,
            message: "Success",
            messageCode: "ROOM_TYPE_GET",
            statusCode: 200,
          };
        } else {
          return getroomTypeList.data;
        }
      }
    } catch (err) {
      return false;
    }
  };
};

const addOrUpdateroomType = (formData, roomType_id) => {
  return async (dispatch) => {
    try {
      let getAddedroomType = {};
      if (roomType_id) {
        getAddedroomType = await DataService.put(
          `${API.roomType.updateroomType}/${roomType_id}`,
          formData
        );
      } else {
        getAddedroomType = await DataService.post(
          API.roomType.addroomType,
          formData
        );
      }

      if (!getAddedroomType.data.error) {
        await dispatch(
          getAllroomTypeList(getAddedroomType.data.data.register_id)
        );
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const addOrUpdateBookingSetup = (formData, roomType_id) => {
  return async (dispatch) => {
    try {
      let getAddedroomType = {};
      if (roomType_id) {
        getAddedroomType = await DataService.put(
          `${API.bookingSetup.updateBookingSetup}/${roomType_id}`,
          formData
        );
      } else {
        getAddedroomType = await DataService.post(
          API.bookingSetup.addBookingSetup,
          formData
        );
      }

      if (!getAddedroomType.data.error) {
        await dispatch(
          getAllroomTypeList(getAddedroomType.data.data.register_id)
        );
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const uploadImage = (formData) => {
  return async (dispatch) => {
    try {
      let getAddedroomType = {};

      getAddedroomType = await DataService.post(
        API.reservation.uploadImage,
        formData
      );

      if (!getAddedroomType.data.error) {
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getOtaChannel = (channel_key) => {
  return async (dispatch) => {
    try {
      let resoponse = await axios.get(
        `https://www.eglobe-solutions.com/webapichannelmanager/rooms/${channel_key}`
      );
      console.log("Responsefasdfasf", resoponse);
      return resoponse;
    } catch (err) {
      if (err.response) {
        message.error(err?.response?.data)
        if (err.response.data) {
          console.log("API Error Message:", err.response.data);
        }
      } else {
        message.error(err.message)

      }
      return false;
    }
  };
};
const getroomTypeById = (id) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");
      let roomTypeDetails;
      if (allSetupcache != null && allSetupcache.roomType) {
        roomTypeDetails = allSetupcache.roomType.find((val) => val._id == id);
      }
      if (roomTypeDetails) {
        return dispatch(roomTypeId(roomTypeDetails));
      } else {
        const roomTypeByIdData = await DataService.get(
          `${API.roomType.getroomTypeById}/${id}`
        );
        if (!roomTypeByIdData.data.error) {
          return dispatch(roomTypeId(roomTypeByIdData.data.data));
        } else {
          return dispatch(roomTypeIdErr(roomTypeByIdData.data));
        }
      }
    } catch (err) {
      dispatch(roomTypeIdErr(err));
    }
  };
};

const deleteRoomType = (roomTypeIds, register_id) => {
  return async (dispatch) => {
    try {
      const getDeletedroomType = await DataService.post(
        API.roomType.deleteAllroomType,
        roomTypeIds
      );
      if (!getDeletedroomType.data.error) {
        await dispatch(getAllroomTypeList(register_id));
        return getDeletedroomType.data;
      } else {
        return getDeletedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const checkRoomAvailblity = (payload) => {
  return async (dispatch) => {
    try {
      payload["timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const getroomTypeList = await DataService.post(
        API.reservation.checkRoom,
        payload
      );
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const checkBookedRoom = (payload) => {
  return async (dispatch) => {
    try {
      payload["timeZone"] = Intl.DateTimeFormat().resolvedOptions().timeZone;

      const getroomTypeList = await DataService.post(
        API.reservation.bookedRoom,
        payload
      );
      if (!getroomTypeList.data.error) {
        return getroomTypeList.data;
      } else {
        return getroomTypeList.data;
      }
    } catch (err) {
      return false;
    }
  };
};
const getAllhousekeeperList = (registerId, checksell) => {
  return async (dispatch) => {
    try {
      let allSetupcache = getItem("setupCache");

      if (checksell == "sell" && allSetupcache?.roomType) {
        let filter = allSetupcache?.housekeeperList.filter(
          (val) => val.register_id == registerId
        );
        return {
          data: filter,
          error: false,
          message: "Success",
          messageCode: "ROOM_TYPE_GET",
          statusCode: 200,
        };
      } else {
        let url = API.housekeeper.getAllHousekeeper;
        const getroomTypeList = await DataService.get(url);
        if (!getroomTypeList.data.error) {
          allSetupcache.housekeeperList = getroomTypeList.data.data;
          setItem("setupCache", allSetupcache);
          let filter = getroomTypeList.data.data.filter(
            (val) => val.register_id == registerId
          );

          return {
            data: filter,
            error: false,
            message: "Success",
            messageCode: "HOUSEKEEPER_GET",
            statusCode: 200,
          };
        } else {
          return getroomTypeList.data;
        }
      }
    } catch (err) {
      return false;
    }
  };
};

const addOrUpdateHouseKeeper = (formData, roomType_id) => {
  return async (dispatch) => {
    try {
      let getAddedroomType = {};
      if (roomType_id) {
        getAddedroomType = await DataService.put(
          `${API.housekeeper.editHousekeeper}/${roomType_id}`,
          formData
        );
      } else {
        getAddedroomType = await DataService.post(
          API.housekeeper.addHousekeeper,
          formData
        );
      }

      if (!getAddedroomType.data.error) {
        await dispatch(
          getAllhousekeeperList(getAddedroomType.data.data.register_id)
        );
        return getAddedroomType.data;
      } else {
        return getAddedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};

const deleteHousekeeper = (roomTypeIds, register_id) => {
  return async (dispatch) => {
    try {
      const getDeletedroomType = await DataService.post(
        API.housekeeper.deleteHousekeeper,
        roomTypeIds
      );
      if (!getDeletedroomType.data.error) {
        await dispatch(getAllhousekeeperList(register_id));
        return getDeletedroomType.data;
      } else {
        return getDeletedroomType.data;
      }
    } catch (err) {
      return false;
    }
  };
};
export {
  addOrUpdateroomType,
  getAllroomTypeList,
  getroomTypeById,
  deleteRoomType,
  checkRoomAvailblity,
  addOrUpdateHouseKeeper,
  getAllhousekeeperList,
  deleteHousekeeper,
  getOtaChannel,
  checkBookedRoom,
  uploadImage,
  addOrUpdateBookingSetup,
};
